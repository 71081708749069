const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-sco`

const CALISTA_SCO_CONTRACT = `${MODULE_BASE}/contract`

const pathMap = {
    SCO_RATE_CONTRACT: CALISTA_SCO_CONTRACT,
    SCO_RATE_CONTRACT_LIST_VIEW: CALISTA_SCO_CONTRACT,
    SCO_RATE_CONTRACT_ADD_PAGE: `${CALISTA_SCO_CONTRACT}/add`,
    SCO_RATE_CONTRACT_EDIT_PAGE: `${CALISTA_SCO_CONTRACT}/edit/:id`,
    SCO_RATE_CONTRACT_DETAILS_PAGE: `${CALISTA_SCO_CONTRACT}/view/:id`,
}

export default pathMap
