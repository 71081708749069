import { constants, useTranslation } from 'cng-web-lib'

import HelpdeskKeys from 'src/constants/locale/key/Helpdesk'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_Helpdesk'

const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.HELPDESK
]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

    //* View Booking  --------------------------------------------------------
    {
        // List View
        let listViewTitle = translate(
            Namespace.HELPDESK, 
            HelpdeskKeys.VIEW_BOOKING
        );
        breadcrumbNameMap.set(pathMap.VIEW_BOOKING, listViewTitle)

        // Booking Details
        let bookingDetailsTtitle = translate(
            Namespace.HELPDESK, 
            HelpdeskKeys.BOOKING_DETAILS
        );
        breadcrumbNameMap.set(pathMap.VIEW_BOOKING_DETAILS, bookingDetailsTtitle)

        // List View
        let listFrbTitle = translate(
            Namespace.HELPDESK,
            HelpdeskKeys.VIEW_BOOKING
        );
        breadcrumbNameMap.set(pathMap.FRB, listFrbTitle)

        // Booking Details
        let frbDetailsTitle = translate(
            Namespace.HELPDESK,
            HelpdeskKeys.BOOKING_DETAILS
        );
        breadcrumbNameMap.set(pathMap.FRB_DETAILS, frbDetailsTitle)

        // List View
        let listSiTitle = translate(
            Namespace.HELPDESK,
            HelpdeskKeys.VIEW_SI
        );
        breadcrumbNameMap.set(pathMap.SI, listSiTitle)

        // Booking Details
        let siDetailsTitle = translate(
            Namespace.HELPDESK,
            HelpdeskKeys.SI_DETAILS
        );
        breadcrumbNameMap.set(pathMap.SI_DETAILS, siDetailsTitle)

        // Table View
        let searchSiTitle = translate(
            Namespace.HELPDESK,
            HelpdeskKeys.SEARCH_SI
        );
        breadcrumbNameMap.set(pathMap.SEARCH_SI, searchSiTitle)
    }


    return breadcrumbNameMap
}


export default useBreadcrumbNameMap
