import { useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_SCOJMCustomAttr'
import SCOJMCustomAttributeKeys from '../../constants/locale/key/SCOJMCustomAttributeKeys'

const translationNamespace = [Namespace.UI_COMPONENT, Namespace.SCO_JM_CUSTOM_ATTR]

function useBreadcrumbNameMap() {

  const { translate } = useTranslation(translationNamespace)

  const breadcrumbNameMap = new Map()

  {

    // --------------- SCO JM Custom Attribute ---------------
    // List View
    let customAttrListViewTitle = translate(Namespace.SCO_JM_CUSTOM_ATTR, SCOJMCustomAttributeKeys.MODULE_TITLE);
    breadcrumbNameMap.set(pathMap.SCO_JM_CUSTOM_ATTR_LIST_VIEW, customAttrListViewTitle);

    // Add View
    let customAttrAddViewTitle = translate(Namespace.SCO_JM_CUSTOM_ATTR, SCOJMCustomAttributeKeys.CUSTOM_ATTRIBUTE_ADD);
    breadcrumbNameMap.set(pathMap.SCO_JM_CUSTOM_ATTR_ADD_VIEW, customAttrAddViewTitle);

    // Edit View
    let customAttrEditViewTitle = translate(Namespace.SCO_JM_CUSTOM_ATTR, SCOJMCustomAttributeKeys.CUSTOM_ATTRIBUTE_EDIT);
    breadcrumbNameMap.set(pathMap.SCO_JM_CUSTOM_ATTR_EDIT_VIEW, customAttrEditViewTitle);

    // Detail View
    let customAttrDetailsViewTitle = translate(Namespace.SCO_JM_CUSTOM_ATTR, SCOJMCustomAttributeKeys.CUSTOM_ATTRIBUTE_VIEW);
    breadcrumbNameMap.set(pathMap.SCO_JM_CUSTOM_ATTR_DETAILS_VIEW, customAttrDetailsViewTitle);

  }

  return breadcrumbNameMap

}

export default useBreadcrumbNameMap
