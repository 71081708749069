import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap, { MODULE_BASE } from 'src/paths/PathMap_TradeManagement'
import TradeManagementKeys from 'src/constants/locale/key/TradeManagement'
import TradeManagementTranslationText from 'src/views/trademanagement/shared/TradeManagementTranslationText'
import TmpDeniedPartySearchKeys from 'src/constants/locale/key/TmpDeniedPartySearch'

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const translationNamespace = [
  Namespace.UI_COMPONENT,
  Namespace.TRADE_MANAGEMENT,
  Namespace.TMP_DPS
]

function useBreadcrumbNameMap() {
  const { translate } = useTranslation(translationNamespace)
  const breadcrumbNameMap = new Map()

  // Locale
  const FtaTranslatedTextsObject = TradeManagementTranslationText()
  // breadcrumbNameMap.set(MODULE_BASE, 'Export')

  let title = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.FTACALCULATORTITLE
  )
  breadcrumbNameMap.set(pathMap.TRADE_MGMT_FTA, title)

  breadcrumbNameMap.set(pathMap.TRADE_MGMT_PRODUCT_DETAILS, title)

  // FTA breadcrumbs
  // breadcrumbNameMap.set(pathMap.TRADE_MGMT_PRODUCT_DETAILS, 'FTA calculator')
  breadcrumbNameMap.set(
    pathMap.TRADE_MGMT_PRODUCT_DETAILS,
    FtaTranslatedTextsObject.bcFtaCalculator
  )

  breadcrumbNameMap.set(
    pathMap.TRADE_MGMT_VIEW_FTA_PRODUCT_DETAILS,
    FtaTranslatedTextsObject.bcViewFtaCalculations
  )

  breadcrumbNameMap.set(
    pathMap.TRADE_MGMT_FTA_ACTIVITY_HISTORY,
    FtaTranslatedTextsObject.bcFtaActivityHistory
  )

  breadcrumbNameMap.set(
    pathMap.MY_PRODUCT_FTA,
    'My Products'
  )
  breadcrumbNameMap.set(
    `${pathMap.MY_PRODUCT_FTA}/:productId`,
    'View product'
  )
  breadcrumbNameMap.set(
    `${pathMap.MY_PRODUCT_FTA}/new`,
    'Create product'
  )

  //DPS breadcrumps
  let TmpDpsSearchTitle = translate(Namespace.TMP_DPS, TmpDeniedPartySearchKeys.TITLE_PARTYSEARCH);
  breadcrumbNameMap.set(pathMap.DPS_SEARCH_PAGE, TmpDpsSearchTitle)

  return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {
  const { translate } = useTranslation(translationNamespace)

  let title = translate(Namespace.UI_COMPONENT, component, {
    title: componentTitle
  })

  return title
}

export default useBreadcrumbNameMap
