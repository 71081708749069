const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-common/report`

const REPORT_MANAGEMENT_PATH = `${MODULE_BASE}/report-management`
const REPORT_DOWNLOAD_PATH = `${MODULE_BASE}/report-download`
const REPORT_SCHEDULER_PATH = `${MODULE_BASE}/report-scheduler`
const GENERATED_REPORT_PATH = `${MODULE_BASE}/generated-report`
const REPORT_SCHEDULER_CUSTOMER_PATH = `${MODULE_BASE}/report-customer`
const GENERATED_REPORT_CUSTOMER_PATH = `${MODULE_BASE}/generated-report-customer`

const pathMap = {
    REPORT_MANAGEMENT: REPORT_MANAGEMENT_PATH,
    REPORT_MANAGEMENT_LIST_VIEW: REPORT_MANAGEMENT_PATH,
    REPORT_MANAGEMENT_ADD_VIEW: `${REPORT_MANAGEMENT_PATH}/add`,
    REPORT_MANAGEMENT_EDIT_VIEW: `${REPORT_MANAGEMENT_PATH}/edit/:id`,
    REPORT_MANAGEMENT_DETAILS_VIEW: `${REPORT_MANAGEMENT_PATH}/view/:id`,

    REPORT_DOWNLOAD: REPORT_DOWNLOAD_PATH,
    REPORT_DOWNLOAD_LIST_VIEW: REPORT_DOWNLOAD_PATH,
    REPORT_DOWNLOAD_ADD_VIEW: `${REPORT_DOWNLOAD_PATH}/add`,
    REPORT_DOWNLOAD_EDIT_VIEW: `${REPORT_DOWNLOAD_PATH}/edit/:id`,
    REPORT_DOWNLOAD_DETAILS_VIEW: `${REPORT_DOWNLOAD_PATH}/view/:id`,

    REPORT_SCHEDULER: REPORT_SCHEDULER_PATH,
    REPORT_SCHEDULER_LIST_VIEW: REPORT_SCHEDULER_PATH,
    REPORT_SCHEDULER_ADD_VIEW: `${REPORT_SCHEDULER_PATH}/add`,
    REPORT_SCHEDULER_EDIT_VIEW: `${REPORT_SCHEDULER_PATH}/edit/:id`,
    REPORT_SCHEDULER_DETAILS_VIEW: `${REPORT_SCHEDULER_PATH}/view/:id`,

    REPORT_SCHEDULER_CUSTOMER: REPORT_SCHEDULER_CUSTOMER_PATH,
    REPORT_SCHEDULER_CUSTOMER_LIST_VIEW: REPORT_SCHEDULER_CUSTOMER_PATH,
    REPORT_SCHEDULER_CUSTOMER_ADD_VIEW: `${REPORT_SCHEDULER_CUSTOMER_PATH}/add`,
    REPORT_SCHEDULER_CUSTOMER_EDIT_VIEW: `${REPORT_SCHEDULER_CUSTOMER_PATH}/edit/:id`,
    REPORT_SCHEDULER_CUSTOMER_DETAILS_VIEW: `${REPORT_SCHEDULER_CUSTOMER_PATH}/view/:id`,

    GENERATED_REPORT: GENERATED_REPORT_PATH,
    GENERATED_REPORT_LIST_VIEW: GENERATED_REPORT_PATH,
    GENERATED_REPORT_CUSTOMER_LIST_VIEW: GENERATED_REPORT_CUSTOMER_PATH
}

export default pathMap