import Footer, { ExtendedFooter } from './Footer'

import PropTypes from 'prop-types'
import React from 'react'
import TopBar from './TopBar'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ebebeb',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}))

function HomePageLayout({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
            <Footer>
              <ExtendedFooter />
            </Footer>
          </div>
        </div>
      </div>
    </div>
  )
}

HomePageLayout.propTypes = {
  children: PropTypes.any
}

export default HomePageLayout
