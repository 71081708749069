import { constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_SCO_JobAttribute'
import SCOJob from 'src/constants/locale/key/SCOJob'

const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.SCO_JOB
]

function useBreadcrumbNameMap() {
    const { translate } = useTranslation(translationNamespace)
    const breadcrumbNameMap = new Map()



    /** 
     *  Job Attribute Configuration Table List Title
     * 
     **/

    let tableTitle = translate(Namespace.SCO_JOB, SCOJob.JOB_ATTRIBUTE_CONFIGURATION)
    breadcrumbNameMap.set(pathMap.SCO_JOB_ATTRIBUTE_LIST, tableTitle)


    let createJobAttribute = translate(Namespace.SCO_JOB, SCOJob.CREATE_NEW_JOB_ATTRIBUTE)
    breadcrumbNameMap.set(pathMap.SCO_JOB_ATTRIBUTE_CREATE_VIEW, createJobAttribute)


    let editJobAttribute = translate(Namespace.SCO_JOB, SCOJob.EDIT_JOB_ATTRIBUTE)
    breadcrumbNameMap.set(pathMap.SCO_JOB_ATTRIBUTE_EDIT_VIEW, editJobAttribute)



    let viewJobAttribute = translate(Namespace.SCO_JOB, SCOJob.VIEW_JOB_ATTRIBUTE)
    breadcrumbNameMap.set(pathMap.SCO_JOB_ATTRIBUTE_VIEW_DETAILS, viewJobAttribute)



    return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {
    const { translate } = useTranslation(translationNamespace)

    let title = translate(Namespace.UI_COMPONENT, component, {
        title: componentTitle
    })

    return title
}

export default useBreadcrumbNameMap
