const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-ricestockpile`

const UPLOAD_IMPORTER_PROFILE_PATH = `${MODULE_BASE}/upload-importer`
const UPLOAD_PO_PATH = `${MODULE_BASE}/upload-po`
const UPLOAD_STOCK_DETAIL_PATH = `${MODULE_BASE}/upload-stock`
const UPLOAD_RICE_STOCKPILE_CONFIG_PATH = `${MODULE_BASE}/config`

const POWERBI_DASHBOARD_IMPORTER_PROFILE_PATH = `${MODULE_BASE}/powerbi-dashboard1`
const POWERBI_DASHBOARD_STOCKPILE_PATH = `${MODULE_BASE}/powerbi-dashboard2`
const POWERBI_DASHBOARD_IMPORT_STATUS_BY_IMPORTER_PATH = `${MODULE_BASE}/powerbi-dashboard3`
const POWERBI_DASHBOARD_IMPORT_STATUS_PATH = `${MODULE_BASE}/powerbi-dashboard4`

const IMPORTERS_PATH = `${MODULE_BASE}/importer`

const pathMap = {
  UPLOAD_IMPORTER_PROFILE_VIEW: `${UPLOAD_IMPORTER_PROFILE_PATH}`,
  UPLOAD_PO_DETAIL_VIEW: `${UPLOAD_PO_PATH}`,
  UPLOAD_STOCK_DETAIL_VIEW: `${UPLOAD_STOCK_DETAIL_PATH}`,
  UPLOAD_RICE_STOCKPILE_CONFIG_VIEW: `${UPLOAD_RICE_STOCKPILE_CONFIG_PATH}`,
  POWERBI_DASHBOARD_IMPORTER_PROFILE_VIEW: `${POWERBI_DASHBOARD_IMPORTER_PROFILE_PATH}`,
  POWERBI_DASHBOARD_STOCKPILE_VIEW: `${POWERBI_DASHBOARD_STOCKPILE_PATH}`,
  POWERBI_DASHBOARD_IMPORT_STATUS_BY_IMPORTER_VIEW: `${POWERBI_DASHBOARD_IMPORT_STATUS_BY_IMPORTER_PATH}`,
  POWERBI_DASHBOARD_IMPORT_STATUS_VIEW: `${POWERBI_DASHBOARD_IMPORT_STATUS_PATH}`,
  IMPORTERS_ADD_VIEW: `${IMPORTERS_PATH}/add`,
  IMPORTERS_DETAILS_VIEW: `${IMPORTERS_PATH}/view/:id`,
  IMPORTERS_EDIT_VIEW: `${IMPORTERS_PATH}/edit/:id`,
  IMPORTERS_LIST_VIEW: IMPORTERS_PATH
}

export default pathMap
