const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-sco`
const JM_CUSTOM_ATTR = `${MODULE_BASE}/jm-custom-attribute`
const JM_CUSTOM_ATTR_VALUE = `${MODULE_BASE}/jm-custom-attribute/config-value`

const pathMap = {

  //Custom Attribute Config Page
  SCO_JM_CUSTOM_ATTR_LIST_VIEW: JM_CUSTOM_ATTR,
  SCO_JM_CUSTOM_ATTR_ADD_VIEW: `${JM_CUSTOM_ATTR}/add`,
  SCO_JM_CUSTOM_ATTR_EDIT_VIEW: `${JM_CUSTOM_ATTR}/edit/:id`,
  SCO_JM_CUSTOM_ATTR_DETAILS_VIEW: `${JM_CUSTOM_ATTR}/view/:id`

}

export default pathMap