const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-sco`

const CALISTA_SCO_JOB_CONFIG = `${MODULE_BASE}/jobconfig`
const CALISTA_SCO_MY_JOB = `${MODULE_BASE}/job`
const CALISTA_SCO_DOC_CONVERSION = `${MODULE_BASE}/docconversion`
const CALISTA_SCO_JOB_REQUEST = `${MODULE_BASE}/jobrequest`


const pathMap = {
    SCO_JOB_CONFIG: CALISTA_SCO_JOB_CONFIG,
    SCO_JOB_CONFIG_LIST_VIEW: CALISTA_SCO_JOB_CONFIG,
    SCO_JOB_CONFIG_ADD_VIEW: `${CALISTA_SCO_JOB_CONFIG}/create`,
    SCO_JOB_CONFIG_DETAILS_VIEW: `${CALISTA_SCO_JOB_CONFIG}/view/:id`,
    SCO_JOB_CONFIG_EDIT_VIEW: `${CALISTA_SCO_JOB_CONFIG}/edit/:id`,

    SCO_JOB: CALISTA_SCO_MY_JOB,
    SCO_JOB_LIST_VIEW: CALISTA_SCO_MY_JOB,
    SCO_JOB_DRAFT_VIEW: `${CALISTA_SCO_MY_JOB}/draft/:id`,
    SCO_JOB_ADD_VIEW: `${CALISTA_SCO_MY_JOB}/create-new-job`,
    SCO_JOB_DETAILS_VIEW: `${CALISTA_SCO_MY_JOB}/view/:id`,
    SCO_JOB_DETAILS_EDIT_VIEW: `${CALISTA_SCO_MY_JOB}/edit/:id`,

    SCO_JOB_DOC_CONVERSION: CALISTA_SCO_DOC_CONVERSION,
    SCO_JOB_DOC_CONVERSION_ADD: `${CALISTA_SCO_DOC_CONVERSION}/create`,
    SCO_JOB_DOC_CONVERSION_VIEW: `${CALISTA_SCO_DOC_CONVERSION}/view/:id`,
    SCO_JOB_DOC_CONVERSION_EDIT: `${CALISTA_SCO_DOC_CONVERSION}/edit/:id`,

    SCO_JOB_REQUEST: CALISTA_SCO_JOB_REQUEST,
    SCO_JOB_ADD_REQ: `${CALISTA_SCO_MY_JOB}/create-new-job/:id`,
}

export default pathMap
