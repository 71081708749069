import { constants, useTranslation } from 'cng-web-lib'

import ForwardHubKeys from 'src/constants/locale/key/ForwardHub'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_ForwardHub'

const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.FORWARD_HUB
]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

    //* Product Management  --------------------------------------------------------
    {
        let title = translate(Namespace.FORWARD_HUB, ForwardHubKeys.TITLE_PROD_MGT)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.PROD_MGT_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PROD_MGT_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PROD_MGT_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PROD_MGT_DETAILS_VIEW, detailsViewTitle)
    }

    //* Inbound  --------------------------------------------------------
    {
        let title = translate(Namespace.FORWARD_HUB, ForwardHubKeys.TITLE_INBOUND)

        // Home View
        let homeViewTitle = title
        breadcrumbNameMap.set(pathMap.INBOUND_HOME, homeViewTitle)

    }

    //* Inbound Late  --------------------------------------------------------
    {
        let title = translate(Namespace.FORWARD_HUB, ForwardHubKeys.TITLE_INBOUND_LATE)

        // Home View
        let homeViewTitle = title
        breadcrumbNameMap.set(pathMap.INBOUND_LATE_HOME, homeViewTitle)

    }

    //* Inbound Reinstate  --------------------------------------------------------
    {
        let title = translate(Namespace.FORWARD_HUB, ForwardHubKeys.TITLE_INBOUND_REINSTATE)

        // Home View
        let homeViewTitle = title
        breadcrumbNameMap.set(pathMap.INBOUND_REINSTATE_HOME, homeViewTitle)

    }


    //* Stock Management - Upload Stock  --------------------------------------------------------
    {
        let title = translate(Namespace.FORWARD_HUB, ForwardHubKeys.TITLE_STOCK_UPLOAD)

        // Home View
        let homeViewTitle = title
        breadcrumbNameMap.set(pathMap.STOCK_UPLOAD_HOME, homeViewTitle)

    }

    //* Stock Management - Search Stock  --------------------------------------------------------
    {
        let title = translate(Namespace.FORWARD_HUB, ForwardHubKeys.TITLE_STOCK_SEARCH)

        // Home View
        let homeViewTitle = title
        breadcrumbNameMap.set(pathMap.STOCK_SEARCH_HOME, homeViewTitle)

    }


    //* Dashboard - Basic  --------------------------------------------------------
    {
        let title = translate(Namespace.FORWARD_HUB, ForwardHubKeys.TITLE_DASHBOARD_BASIC)

        // Home View
        let homeViewTitle = title
        breadcrumbNameMap.set(pathMap.DASHBOARD_BASIC, homeViewTitle)

    }

    //* Dashboard - Enhanced  --------------------------------------------------------
    {
        let title = translate(Namespace.FORWARD_HUB, ForwardHubKeys.TITLE_DASHBOARD_ENHANCED)

        // Home View
        let homeViewTitle = title
        breadcrumbNameMap.set(pathMap.DASHBOARD_ENHANCED, homeViewTitle)

    }

     // ---------------- Common Config ---------------
     {
        let title = translate(Namespace.FORWARD_HUB, ForwardHubKeys.TITLE_COMMON_CONFIG)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.COMMON_CONFIG_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.COMMON_CONFIG_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.COMMON_CONFIG_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.COMMON_CONFIG_DETAILS_VIEW, detailsViewTitle)

    }

    return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {

    const { translate } = useTranslation(translationNamespace)

    let title = translate(
        Namespace.UI_COMPONENT,
        component, { title: componentTitle }
    )

    return (title);
}

export default useBreadcrumbNameMap
