import { constants, useTranslation } from 'cng-web-lib'

import BookingSyncLogKeys from 'src/constants/locale/key/BookingSyncLog'
import FreightBookingKeys from 'src/constants/locale/key/FreightBooking'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_FreightBooking'

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const translationNamespace = [
  Namespace.UI_COMPONENT,
  Namespace.FREIGHT_BOOKING,
  Namespace.BOOKING_SYNC_LOG,
  Namespace.COMPANY_PREFERENCE
]

function useBreadcrumbNameMap() {
  const { translate } = useTranslation(translationNamespace)

  const breadcrumbNameMap = new Map()

  //* Create New Booking  --------------------------------------------------------
  {
    let title = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.CREATE_NEW_BOOKING_TITLE
    )

    // List View
    let listViewTitle = title
    breadcrumbNameMap.set(pathMap.CREATE_NEW_BOOKING, listViewTitle)
  }

  //* My Booking --------------------------------------------
  {
    let title = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.MY_BOOKING_TITLE
    )

    //breadcrumbNameMap.set(pathMap.MY_BOOKING, title)
    // List View
    let listViewTitle = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.MY_BOOKING_TITLE
    )
    breadcrumbNameMap.set(pathMap.MY_BOOKING, listViewTitle)

    // View details
    let detailsTitle = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.BOOKING_DETAILS_TITLE
    )
    breadcrumbNameMap.set(pathMap.MY_BOOKING_REVIEW_DRAFT, detailsTitle)

    // Edit View
    let editViewTitle = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.AMEND_BOOKING_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.MY_BOOKING_AMEND, editViewTitle)
  }

  //* View Only Booking --------------------------------------------
  {
    let title = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.VIEW_ONLY_BOOKING_TITLE
    )

    // List View
    let listViewTitle = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.VIEW_ONLY_BOOKING_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.VIEW_ONLY_BOOKING, listViewTitle)

    // View details
    let viewOnlyDetailsTitle = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.BOOKING_DETAILS_TITLE
    )
    breadcrumbNameMap.set(pathMap.VIEW_ONLY_BOOKING_REVIEW, viewOnlyDetailsTitle)
  }

  //* Manage Booking --------------------------------------------
  {
    let title = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.MANAGE_BOOKING_TITLE
    )

    breadcrumbNameMap.set(pathMap.MANAGE_BOOKING, title)
  }

  {
    // View details
    let title = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.BOOKING_DETAILS_TITLE
    )
    breadcrumbNameMap.set(pathMap.MANAGE_BOOKING_REVIEW, title)
  }

  {
    // Edit View
    let editViewTitle = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.AMEND_BOOKING_TITLE
    )
    breadcrumbNameMap.set(pathMap.MANAGE_BOOKING_AMEND, editViewTitle)
  }
  //* Booking From Schedule--------------------------------------------
  {
    let title = translate(
      Namespace.FREIGHT_BOOKING,
      FreightBookingKeys.BOOK_FROM_SCHEDULE
    )
    breadcrumbNameMap.set(pathMap.BOOK_FROM_SCHEDULE, title)
  }

  // ---------------- Booking Sync Log ---------------
  {
    let title = translate(Namespace.BOOKING_SYNC_LOG, BookingSyncLogKeys.TITLE)

    // List View
    let listViewTitle = title
    breadcrumbNameMap.set(pathMap.BOOKING_SYNC_LOG, listViewTitle)

    // Add View
    let addViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.BOOKING_SYNC_LOG_ADD_VIEW, addViewTitle)

    // Edit View
    let editViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.BOOKING_SYNC_LOG_EDIT_VIEW, editViewTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(
      pathMap.BOOKING_SYNC_LOG_DETAILS_VIEW,
      detailsViewTitle
    )
  }

  return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {
  const { translate } = useTranslation(translationNamespace)

  let title = translate(Namespace.UI_COMPONENT, component, {
    title: componentTitle
  })

  return title
}

export default useBreadcrumbNameMap
