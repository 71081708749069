    import { useTranslation } from 'cng-web-lib'
    import Namespace from 'src/constants/locale/Namespace'
    import pathMap from 'src/paths/PathMap_EcommerceLabel'
    import EcommerceLabelKeys from 'src/constants/locale/key/EcommerceLabel'

    const translationNamespace = [
        Namespace.ECOMMERCE_LABEL
    ]

    function useBreadcrumbNameMap() {
        const { translate } = useTranslation(translationNamespace)
        const breadcrumbNameMap = new Map()

        let labelTitle = translate(Namespace.ECOMMERCE_LABEL, EcommerceLabelKeys.LABEL_PAGE_TITLE)
        breadcrumbNameMap.set(pathMap.LABEL_PAGE, labelTitle)
        
        return breadcrumbNameMap
    }


    export default useBreadcrumbNameMap
