import { constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import SCOInvMgmt from 'src/constants/locale/key/SCOInvMgmt'
import SCOInvReportKeys from "../../constants/locale/key/SCOInvReport";
import scoAdminPathMap from 'src/paths/pathMap'

const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.SCO_INV_MGMT,
    Namespace.SCO_INV_REPORT
]

const supplierBreadcrumbNameMap = (breadcrumbNameMap, translate) => {
    /* SUPPLIER MANAGEMENT BREADCRUMBS */
    let supplierTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.SUPPLIER)
    // List View
    let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, supplierTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_SUPPLIER_LIST, listViewTitle)

    // ADMIN List View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_SUPPLIER_LIST, listViewTitle)

    // Edit View
    let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, supplierTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_SUPPLIER_EDIT_VIEW, editViewTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, supplierTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_SUPPLIER_DETAILS_VIEW, detailsViewTitle)
    // ADMIN Details View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_SUPPLIER_DETAILS_VIEW, detailsViewTitle)

    // Create View
    let createViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, supplierTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_SUPPLIER_CREATE_VIEW, createViewTitle)
}

const buyerBreadcrumbNameMap = (breadcrumbNameMap, translate) => {
    /* BUYER MANAGEMENT BREADCRUMBS */
    let buyerTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.BUYER)
    // List View
    let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, buyerTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_BUYER_LIST, listViewTitle)

    // ADMIN List View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_BUYER_LIST, listViewTitle)

    // Edit View
    let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, buyerTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_BUYER_EDIT_VIEW, editViewTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, buyerTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_BUYER_DETAILS_VIEW, detailsViewTitle)
    // ADMIN Details View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_BUYER_DETAILS_VIEW, detailsViewTitle)

    // Create View
    let createViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, buyerTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_BUYER_CREATE_VIEW, createViewTitle)
}

const categoryBreadcrumbNameMap = (breadcrumbNameMap, translate) => {
    /* CATEGORY MANAGEMENT BREADCRUMBS */
    let skuCategoryTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.SKU_CATEGORY)

    // List View
    let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, skuCategoryTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_CATEGORY_LIST, listViewTitle)

    // ADMIN List View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_CATEGORY_LIST, listViewTitle)

    // Edit View
    let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, skuCategoryTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_CATEGORY_EDIT_VIEW, editViewTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, skuCategoryTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_CATEGORY_DETAILS_VIEW, detailsViewTitle)
    // ADMIN Details View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_CATEGORY_DETAILS_VIEW, detailsViewTitle)

    // Create View
    let createViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, skuCategoryTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_CATEGORY_CREATE_VIEW, createViewTitle)
}

const warehouseBreadcrumbNameMap = (breadcrumbNameMap, translate) => {
    // Title
    let warehouseTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.WAREHOUSE_TITLE)
    breadcrumbNameMap.set(pathMap.SCO_INV_WAREHOUSE_LIST, warehouseTitle)

    // List View
    let warehouseListViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, warehouseTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_WAREHOUSE_LIST, warehouseListViewTitle)

    // Admin List View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_WAREHOUSE_LIST, warehouseListViewTitle)

    // Create View
    let createWarehouseViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, warehouseTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_WAREHOUSE_CREATE_VIEW, createWarehouseViewTitle)

    //Edit view
    let warehouseEditTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, warehouseTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_WAREHOUSE_EDIT_VIEW, warehouseEditTitle)

    //Detail View
    let warehouseViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, warehouseTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_WAREHOUSE_DETAILS_VIEW, warehouseViewTitle)

    //Admin Detail View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_DETAILS_VIEW, warehouseViewTitle)
}


const nonStorageBreadcrumbNameMap = (breadcrumbNameMap, translate) => {
    //Title
    let locationTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.NON_STORAGE_LOCATION)
    breadcrumbNameMap.set(pathMap.SCO_INV_NONSTORAGE_LIST, locationTitle)

    //List View
    let locationListViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, locationTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_NONSTORAGE_LIST, locationListViewTitle)

    // Admin List View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_NONSTORAGE_LIST, locationListViewTitle)

    // Create View
    let createLocationViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, locationTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_NONSTORAGE_CREATE_VIEW, createLocationViewTitle)

    //Edit View
    let locationEditTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, locationTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_NONSTORAGE_EDIT_VIEW, locationEditTitle)

    //Detail View
    let locationViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, locationTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_NONSTORAGE_DETAILS_VIEW, locationViewTitle)

    //Admin Detail View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_NONSTORAGE_DETAILS_VIEW, locationViewTitle)

}

const skuBreadcrumbNameMap = (breadcrumbNameMap, translate) => {
    //Title
    let skuTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.SKU)

    // List View
    let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, skuTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_SKU_LIST, listViewTitle)

    // ADMIN List View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_SKU_LIST, listViewTitle)

    // Edit View
    let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, skuTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_SKU_EDIT_VIEW, editViewTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, skuTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_SKU_DETAILS_VIEW, detailsViewTitle)
    // ADMIN Details View
    breadcrumbNameMap.set(pathMap.SCO_INV_ADMIN_SKU_DETAILS_VIEW, detailsViewTitle)

    // Create View
    let createViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, skuTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_SKU_CREATE_VIEW, createViewTitle)

    // Upload View
    let uploadSku = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.UPLOAD_SKU)
    breadcrumbNameMap.set(pathMap.SCO_INV_SKU_UPLOAD, uploadSku)

    // Confirm Uploaded Details View
    let confirmUploadedDetails = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.CONFIRM_UPLOADED_SKU_DETAILS)
    breadcrumbNameMap.set(pathMap.SCO_INV_SKU_CONFIRM_UPLOAD_DETAILS, confirmUploadedDetails)
}

const defLocConfigBreadcrumbNameMap = (breadcrumbNameMap, translate) => {
    // Edit View
    let defLocConfigTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.DEF_LOC_CONFIG)
    breadcrumbNameMap.set(pathMap.SCO_INV_DEF_LOC_CONFIG_EDIT, defLocConfigTitle)
}

const stockBreadcrumbNameMap = (breadcrumbNameMap, translate) => {
    // List View
    let stockTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.INVENTORY_LIST)
    breadcrumbNameMap.set(pathMap.SCO_INV_STOCK_INVENTORY_LIST, stockTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, stockTitle)
    breadcrumbNameMap.set(pathMap.SCO_INV_STOCK_INVENTORY_DETAILS_VIEW, detailsViewTitle)

    // Details Edit
    let detailsEditTitle =  translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.INVENTORY_LIST_DETAILS);
    breadcrumbNameMap.set(pathMap.SCO_INV_STOCK_INVENTORY_DETAILS_EDIT, detailsEditTitle)

}

const principalMappingNameMap = (breadcrumbNameMap, translate) => {

    // list view
    let principalMappingTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.PRINCIPAL_MAPPING)
    breadcrumbNameMap.set(scoAdminPathMap.SCO_ADMIN_PRINCIPAL_MAPPING, principalMappingTitle)

    //details view
    let editPrincipalMappingTitle = translate(Namespace.SCO_INV_MGMT, SCOInvMgmt.PRINCIPAL_MAPPING_DETAILS)
    breadcrumbNameMap.set(scoAdminPathMap.SCO_ADMIN_PRINCIPAL_MAPPING_PARTIES_EDIT_VIEW, editPrincipalMappingTitle)


    let myInventoryPrincipalTitle = translate(Namespace.SCO_INV_MGMT, 'My Inventory (Principal)')
    breadcrumbNameMap.set(pathMap.SCO_INV_MY_PRINCIPAL_LIST, myInventoryPrincipalTitle)

    let myInventoryViewPageTitle = translate(Namespace.SCO_INV_MGMT, 'My Inventory (Principal) Details')
    breadcrumbNameMap.set(pathMap.SCO_INV_MY_PRINCIPAL_DETAILS_VIEW, myInventoryViewPageTitle)


}

const invReportNameMap = (breadcrumbNameMap, translate) => {

    // report export
    let invReportTitle = translate(Namespace.SCO_INV_REPORT, SCOInvReportKeys.TITLE)
    breadcrumbNameMap.set(pathMap.SCO_INV_REPORT, invReportTitle)

}

function useBreadcrumbNameMap() {
    const { translate } = useTranslation(translationNamespace)
    const breadcrumbNameMap = new Map()

    supplierBreadcrumbNameMap(breadcrumbNameMap, translate);
    buyerBreadcrumbNameMap(breadcrumbNameMap, translate);
    categoryBreadcrumbNameMap(breadcrumbNameMap, translate);
    warehouseBreadcrumbNameMap(breadcrumbNameMap, translate);
    nonStorageBreadcrumbNameMap(breadcrumbNameMap, translate);
    skuBreadcrumbNameMap(breadcrumbNameMap, translate);
    defLocConfigBreadcrumbNameMap(breadcrumbNameMap, translate);
    stockBreadcrumbNameMap(breadcrumbNameMap, translate);
    principalMappingNameMap(breadcrumbNameMap, translate);
    invReportNameMap(breadcrumbNameMap, translate);
    return breadcrumbNameMap
}


function TranslateCngUiComponent(component, componentTitle) {
    const { translate } = useTranslation(translationNamespace)

    return translate(Namespace.UI_COMPONENT, component, {
        title: componentTitle
    })
}

export default useBreadcrumbNameMap
