import { useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_eCommerce'
import DeliveryOrdersKeys from '../../constants/locale/key/cec/DeliveryOrdersKeys'

const translationNamespace = [Namespace.UI_COMPONENT, Namespace.DELIVERY_ORDERS]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

    {

        // --------------- Connector Configurations---------------

        // List View
        let listViewTitle = translate(Namespace.DELIVERY_ORDERS, DeliveryOrdersKeys.DELIVERY_ORDER_LIST);
        breadcrumbNameMap.set(pathMap.CEC_DELIVERY_ORDER_LIST_VIEW, listViewTitle);

        // Add View
        let addViewTitle = translate(Namespace.DELIVERY_ORDERS, DeliveryOrdersKeys.CREATE_DELIVERY_ORDER);
        breadcrumbNameMap.set(pathMap.CEC_DELIVERY_ORDER_ADD_VIEW, addViewTitle);

        // Edit View
        let editViewTitle = translate(Namespace.DELIVERY_ORDERS, DeliveryOrdersKeys.EDIT_DELIVERY_ORDER);
        breadcrumbNameMap.set(pathMap.CEC_DELIVERY_ORDER_EDIT_VIEW, editViewTitle);

        // Details View
        let detailsViewTitle = translate(Namespace.CONNECTOR_APPS, DeliveryOrdersKeys.VIEW_DELIVERY_ORDER);
        breadcrumbNameMap.set(pathMap.CEC_DELIVERY_ORDER_DETAILS_VIEW, detailsViewTitle);

    }

    return breadcrumbNameMap
}

export default useBreadcrumbNameMap
