import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap, { MODULE_BASE } from 'src/paths/PathMap_CalistaAdvisory'
import CalistaAdvisoryKeys from 'src/constants/locale/key/CalistaAdvisory'


const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.CALISTA_ADVISORY
]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

    {
        let title = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKeys.SUB_MODULE_TITLE.WIZARD)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_WIZARD, title)
    }

    //* Calista Advisory Party Config
    {
        let title = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKeys.SUB_MODULE_TITLE.CALISTA_ADVISORY_PARTY_CONFIG)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_PARTY_CONFIG_LIST_VIEW, listViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_PARTY_CONFIG_EDIT_VIEW, editViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_PARTY_CONFIG_ADD_VIEW, addViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_PARTY_CONFIG_DETAILS_VIEW, detailsViewTitle)
    }

    //* Calista Advisory System Config
    {
        let sysConfigTitle = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKeys.SUB_MODULE_TITLE.CALISTA_ADVISORY_SYSTEM_CONFIG)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, sysConfigTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG_LIST_VIEW, listViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, sysConfigTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, sysConfigTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG_DETAILS_VIEW, detailsViewTitle)
    }

    //* Calista Advisory Country
    {
        let countryTitle = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKeys.SUB_MODULE_TITLE.CALISTA_ADVISORY_COUNTRY)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, countryTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_COUNTRY_LIST_VIEW, listViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, countryTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_COUNTRY_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, countryTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_COUNTRY_DETAILS_VIEW, detailsViewTitle)
    }

    //* CIA FTA Mapping
    {
        let ftaMappingTitle = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKeys.SUB_MODULE_TITLE.CALISTA_ADVISORY_FTA_MAPPING)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, ftaMappingTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_FTA_MAPPING_LIST_VIEW, listViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, ftaMappingTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_FTA_MAPPING_EDIT_VIEW, editViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, ftaMappingTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_FTA_MAPPING_ADD_VIEW, addViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, ftaMappingTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_FTA_MAPPING_DETAILS_VIEW, detailsViewTitle)
    }

    //* CIA Mass Recommend Admin
    {
        let ciaMassRecAdminTitle = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKeys.SUB_MODULE_TITLE.CIA_ADMIN_MASS_RECOMMEND)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, ciaMassRecAdminTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_MASS_RECOMMEND_LIST_VIEW, listViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, ciaMassRecAdminTitle)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_ADMIN_MASS_RECOMMEND_LIST_VIEW, detailsViewTitle)
    }

    breadcrumbNameMap.set(MODULE_BASE, 'Intelligent Advisory')
    breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_WIZARD, 'Trade Advisory Wizard')
    breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_LCC, 'Landed Cost Calculator')
    breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_LCC_RESULT, 'Calculation Result')
    breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_MASSRECOMMEND, 'Mass Recommendation')
    breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_MASSRECOMMEND_UPLOAD, 'New Request')
    breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_MASSRECOMMEND_RESULT, 'Results')

    return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {

    const { translate } = useTranslation(translationNamespace)

    let title = translate(
        Namespace.UI_COMPONENT,
        component, { title: componentTitle }
    )

    return (title);
}

export default useBreadcrumbNameMap