const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-compliance`

const MANAGE_PERMIT_PATH = `${MODULE_BASE}/manage-permit`
const CREATE_NEW_PERMIT_PATH = `${MODULE_BASE}/new-permit`
const DECLARANT_MAINTENANCE_PATH = `${MODULE_BASE}/declarant-maintenance`

const pathMap = {

    MANAGE_PERMIT: MANAGE_PERMIT_PATH,
    CREATE_NEW_PERMIT: `${MANAGE_PERMIT_PATH}/create`,
    CREATE_NEW_PERMIT_REVIEW: `${MANAGE_PERMIT_PATH}/review`,    
    CREATE_PERMIT_NEW_PERMIT_SUBMITTED: `${MANAGE_PERMIT_PATH}/submitted`,
    MANAGE_VIEW_PERMIT: `${MANAGE_PERMIT_PATH}/view`,
    CREATE_PERMIT_UPDATE_DRAFT: `${MANAGE_PERMIT_PATH}/update`,

    /* Start of Declarant Maintenance PATHS */
    DECLARANT_MAINTENANCE_PARTIES_LIST: `${DECLARANT_MAINTENANCE_PATH}`,
    DECLARANT_MAINTENANCE_EDIT_VIEW: `${DECLARANT_MAINTENANCE_PATH}/edit/:id`,
    DECLARANT_MAINTENANCE_LIST: `${DECLARANT_MAINTENANCE_PATH}`,
    DECLARANT_MAINTENANCE_DETAILS_VIEW: `${DECLARANT_MAINTENANCE_PATH}/view/:id`
}
export default pathMap