import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_CalistaPartyCompanyAdmin'
import CalistaPartyCompanyInfoKeys from 'src/constants/locale/key/CalistaPartyCompanyInfo';

const {
	locale: {
		key: { UiComponentKeys }
	}
} = constants

const translationNamespace = [
	Namespace.UI_COMPONENT,
	Namespace.CALISTA_PARTY_COMPANY_INFO
]

function useBreadcrumbNameMap() {

	const { translate } = useTranslation(translationNamespace)

	const breadcrumbNameMap = new Map()

	//* Calista Party Config ---------------------------------------------------
	{
		let title = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.TITLE)

		// List View
		let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
		breadcrumbNameMap.set(pathMap.CALISTA_PARTY_ADMIN_LIST, listViewTitle)

		// Edit View
		let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
		breadcrumbNameMap.set(pathMap.CALISTA_PARTY_ADMIN_EDIT, editViewTitle)

		// Details View
		let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
		breadcrumbNameMap.set(pathMap.CALISTA_PARTY_ADMIN_VIEW, detailsViewTitle)
	}

	//* End --------------------------------------------------------------------
	return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {

	const { translate } = useTranslation(translationNamespace)

	let title = translate(
		Namespace.UI_COMPONENT,
		component, { title: componentTitle }
	)

	return (title);
}

export default useBreadcrumbNameMap
