const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-common/account-setup`

const SUBSCRIPTION_PATH = `${MODULE_BASE}/subscription`
const TCAL_PARTY_CARRIER_CODE_PATH = `${MODULE_BASE}/tcal-party-carrier-code`
const TCAL_AS_USER_GROUP_MASTER_PATH = `${MODULE_BASE}/tcal-as-user-group-master`
const TTFB_TPR_PARTY_PATH = `${MODULE_BASE}/ttfb-tpr-party`
const USER_FOR_USER_GROUP_PATH = `${MODULE_BASE}/user-for-user-group`
const ACCESS_LEVEL_MASTER_PATH = `${MODULE_BASE}/access-level-master`
const ACCESS_LEVEL_RULE_PATH = `${MODULE_BASE}/access-level-rule`
const ACCESS_LEVEL_COMPANY_PATH = `${MODULE_BASE}/access-level-company`
const ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH = `${MODULE_BASE}/access-level-company-custom-rule`
const ACCESS_LEVEL_USER_PATH = `${MODULE_BASE}/access-level-user`
const ACCESS_LEVEL_USER_ADMIN_PATH = `${MODULE_BASE}/access-level-user-admin`
const SHIPPER_REQUEST_TYPE_PATH = `${MODULE_BASE}/shipper-request-type`
const COMPANY_FOR_SHIPPER_REQUEST_PATH = `${MODULE_BASE}/company-for-shipper-request`
const COMPANY_FOR_USER_GROUP_PATH = `${MODULE_BASE}/company-for-user-group`
const USER_FOR_SHIPPER_REQUEST_PATH = `${MODULE_BASE}/user-for-shipper-request`
const NBS_CHARGE_CODE_MAPPING_PATH = `${MODULE_BASE}/nbs-charge-code-mapping`
const NBS_BILLING_CONFIG_PATH = `${MODULE_BASE}/nbs-billing-config`
const NBS_FILE_PATH = `${MODULE_BASE}/nbs-file`

const pathMap = {
	SUBSCRIPTION: SUBSCRIPTION_PATH,
	SUBSCRIPTION_LIST_VIEW: SUBSCRIPTION_PATH,
	SUBSCRIPTION_ADD_VIEW: `${SUBSCRIPTION_PATH}/add`,
	SUBSCRIPTION_EDIT_VIEW: `${SUBSCRIPTION_PATH}/edit/:id`,
	SUBSCRIPTION_DETAILS_VIEW: `${SUBSCRIPTION_PATH}/view/:id`,

	TCAL_PARTY_CARRIER_CODE: TCAL_PARTY_CARRIER_CODE_PATH,
	TCAL_PARTY_CARRIER_CODE_LIST_VIEW: TCAL_PARTY_CARRIER_CODE_PATH,
	TCAL_PARTY_CARRIER_CODE_ADD_VIEW: `${TCAL_PARTY_CARRIER_CODE_PATH}/add`,
	TCAL_PARTY_CARRIER_CODE_EDIT_VIEW: `${TCAL_PARTY_CARRIER_CODE_PATH}/edit/:id`,
	TCAL_PARTY_CARRIER_CODE_DETAILS_VIEW: `${TCAL_PARTY_CARRIER_CODE_PATH}/view/:id`,

	TCAL_AS_USER_GROUP_MASTER: TCAL_AS_USER_GROUP_MASTER_PATH,
	TCAL_AS_USER_GROUP_MASTER_LIST_VIEW: TCAL_AS_USER_GROUP_MASTER_PATH,
	TCAL_AS_USER_GROUP_MASTER_ADD_VIEW: `${TCAL_AS_USER_GROUP_MASTER_PATH}/add`,
	TCAL_AS_USER_GROUP_MASTER_EDIT_VIEW: `${TCAL_AS_USER_GROUP_MASTER_PATH}/edit/:id`,
	TCAL_AS_USER_GROUP_MASTER_DETAILS_VIEW: `${TCAL_AS_USER_GROUP_MASTER_PATH}/view/:id`,

	TTFB_TPR_PARTY: TTFB_TPR_PARTY_PATH,
	TTFB_TPR_PARTY_LIST_VIEW: TTFB_TPR_PARTY_PATH,
	TTFB_TPR_PARTY_ADD_VIEW: `${TTFB_TPR_PARTY_PATH}/add`,
	TTFB_TPR_PARTY_EDIT_VIEW: `${TTFB_TPR_PARTY_PATH}/edit/:id`,
	TTFB_TPR_PARTY_DETAILS_VIEW: `${TTFB_TPR_PARTY_PATH}/view/:id`,

	USER_FOR_USER_GROUP: USER_FOR_USER_GROUP_PATH,
	USER_FOR_USER_GROUP_LIST_VIEW: USER_FOR_USER_GROUP_PATH,
	USER_FOR_USER_GROUP_ADD_VIEW: `${USER_FOR_USER_GROUP_PATH}/add`,
	USER_FOR_USER_GROUP_EDIT_VIEW: `${USER_FOR_USER_GROUP_PATH}/edit/:id`,
	USER_FOR_USER_GROUP_DETAILS_VIEW: `${USER_FOR_USER_GROUP_PATH}/view/:id`,

	ACCESS_LEVEL_MASTER: ACCESS_LEVEL_MASTER_PATH,
	ACCESS_LEVEL_MASTER_LIST_VIEW: ACCESS_LEVEL_MASTER_PATH,
	ACCESS_LEVEL_MASTER_ADD_VIEW: `${ACCESS_LEVEL_MASTER_PATH}/add`,
	ACCESS_LEVEL_MASTER_EDIT_VIEW: `${ACCESS_LEVEL_MASTER_PATH}/edit/:id`,
	ACCESS_LEVEL_MASTER_DETAILS_VIEW: `${ACCESS_LEVEL_MASTER_PATH}/view/:id`,

	ACCESS_LEVEL_RULE: ACCESS_LEVEL_RULE_PATH,
	ACCESS_LEVEL_RULE_LIST_VIEW: ACCESS_LEVEL_RULE_PATH,
	ACCESS_LEVEL_RULE_ADD_VIEW: `${ACCESS_LEVEL_RULE_PATH}/add`,
	ACCESS_LEVEL_RULE_EDIT_VIEW: `${ACCESS_LEVEL_RULE_PATH}/edit/:id`,
	ACCESS_LEVEL_RULE_DETAILS_VIEW: `${ACCESS_LEVEL_RULE_PATH}/view/:id`,

	ACCESS_LEVEL_COMPANY: ACCESS_LEVEL_COMPANY_PATH,
	ACCESS_LEVEL_COMPANY_LIST_VIEW: ACCESS_LEVEL_COMPANY_PATH,
	ACCESS_LEVEL_COMPANY_ADD_VIEW: `${ACCESS_LEVEL_COMPANY_PATH}/add`,
	ACCESS_LEVEL_COMPANY_EDIT_VIEW: `${ACCESS_LEVEL_COMPANY_PATH}/edit/:id`,
	ACCESS_LEVEL_COMPANY_DETAILS_VIEW: `${ACCESS_LEVEL_COMPANY_PATH}/view/:id`,

	ACCESS_LEVEL_COMPANY_CUSTOM_RULE: ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH,
	ACCESS_LEVEL_COMPANY_CUSTOM_RULE_LIST_VIEW: ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH,
	ACCESS_LEVEL_COMPANY_CUSTOM_RULE_ADD_VIEW: `${ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH}/add`,
	ACCESS_LEVEL_COMPANY_CUSTOM_RULE_EDIT_VIEW: `${ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH}/edit/:id`,
	ACCESS_LEVEL_COMPANY_CUSTOM_RULE_DETAILS_VIEW: `${ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH}/view/:id`,

	ACCESS_LEVEL_USER: ACCESS_LEVEL_USER_PATH,
	ACCESS_LEVEL_USER_LIST_VIEW: ACCESS_LEVEL_USER_PATH,
	ACCESS_LEVEL_USER_ADD_VIEW: `${ACCESS_LEVEL_USER_PATH}/add`,
	ACCESS_LEVEL_USER_EDIT_VIEW: `${ACCESS_LEVEL_USER_PATH}/edit/:id`,
	ACCESS_LEVEL_USER_DETAILS_VIEW: `${ACCESS_LEVEL_USER_PATH}/view/:id`,
	ACCESS_LEVEL_USER_ADMIN: ACCESS_LEVEL_USER_ADMIN_PATH,
	ACCESS_LEVEL_USER_LIST_VIEW_ADMIN: ACCESS_LEVEL_USER_ADMIN_PATH,

	SHIPPER_REQUEST_TYPE: SHIPPER_REQUEST_TYPE_PATH,
	SHIPPER_REQUEST_TYPE_LIST_VIEW: SHIPPER_REQUEST_TYPE_PATH,
	SHIPPER_REQUEST_TYPE_ADD_VIEW: `${SHIPPER_REQUEST_TYPE_PATH}/add`,
	SHIPPER_REQUEST_TYPE_EDIT_VIEW: `${SHIPPER_REQUEST_TYPE_PATH}/edit/:id`,
	SHIPPER_REQUEST_TYPE_DETAILS_VIEW: `${SHIPPER_REQUEST_TYPE_PATH}/view/:id`,

	COMPANY_FOR_SHIPPER_REQUEST: COMPANY_FOR_SHIPPER_REQUEST_PATH,
	COMPANY_FOR_SHIPPER_REQUEST_LIST_VIEW: COMPANY_FOR_SHIPPER_REQUEST_PATH,
	COMPANY_FOR_SHIPPER_REQUEST_ADD_VIEW: `${COMPANY_FOR_SHIPPER_REQUEST_PATH}/add`,
	COMPANY_FOR_SHIPPER_REQUEST_EDIT_VIEW: `${COMPANY_FOR_SHIPPER_REQUEST_PATH}/edit/:id`,
	COMPANY_FOR_SHIPPER_REQUEST_DETAILS_VIEW: `${COMPANY_FOR_SHIPPER_REQUEST_PATH}/view/:id`,

	COMPANY_FOR_USER_GROUP: COMPANY_FOR_USER_GROUP_PATH,
	COMPANY_FOR_USER_GROUP_LIST_VIEW: COMPANY_FOR_USER_GROUP_PATH,
	COMPANY_FOR_USER_GROUP_ADD_VIEW: `${COMPANY_FOR_USER_GROUP_PATH}/add`,
	COMPANY_FOR_USER_GROUP_EDIT_VIEW: `${COMPANY_FOR_USER_GROUP_PATH}/edit/:id`,
	COMPANY_FOR_USER_GROUP_DETAILS_VIEW: `${COMPANY_FOR_USER_GROUP_PATH}/view/:id`,

	USER_FOR_SHIPPER_REQUEST: USER_FOR_SHIPPER_REQUEST_PATH,
	USER_FOR_SHIPPER_REQUEST_LIST_VIEW: USER_FOR_SHIPPER_REQUEST_PATH,
	USER_FOR_SHIPPER_REQUEST_ADD_VIEW: `${USER_FOR_SHIPPER_REQUEST_PATH}/add`,
	USER_FOR_SHIPPER_REQUEST_EDIT_VIEW: `${USER_FOR_SHIPPER_REQUEST_PATH}/edit/:id`,
	USER_FOR_SHIPPER_REQUEST_DETAILS_VIEW: `${USER_FOR_SHIPPER_REQUEST_PATH}/view/:id`,

	NBS_CHARGE_CODE_MAPPING: NBS_CHARGE_CODE_MAPPING_PATH,
	NBS_CHARGE_CODE_MAPPING_LIST_VIEW: NBS_CHARGE_CODE_MAPPING_PATH,
	NBS_CHARGE_CODE_MAPPING_ADD_VIEW: `${NBS_CHARGE_CODE_MAPPING_PATH}/add`,
	NBS_CHARGE_CODE_MAPPING_EDIT_VIEW: `${NBS_CHARGE_CODE_MAPPING_PATH}/edit/:id`,
	NBS_CHARGE_CODE_MAPPING_DETAILS_VIEW: `${NBS_CHARGE_CODE_MAPPING_PATH}/view/:id`,

	NBS_BILLING_CONFIG: NBS_BILLING_CONFIG_PATH,
	NBS_BILLING_CONFIG_LIST_VIEW: NBS_BILLING_CONFIG_PATH,
	NBS_BILLING_CONFIG_ADD_VIEW: `${NBS_BILLING_CONFIG_PATH}/add`,
	NBS_BILLING_CONFIG_EDIT_VIEW: `${NBS_BILLING_CONFIG_PATH}/edit/:id`,
	NBS_BILLING_CONFIG_DETAILS_VIEW: `${NBS_BILLING_CONFIG_PATH}/view/:id`,

	NBS_FILE: NBS_FILE_PATH,
	NBS_FILE_LIST_VIEW: NBS_FILE_PATH,
	NBS_FILE_ADD_VIEW: `${NBS_FILE_PATH}/add`,
	NBS_FILE_EDIT_VIEW: `${NBS_FILE_PATH}/edit/:id`,
	NBS_FILE_DETAILS_VIEW: `${NBS_FILE_PATH}/view/:id`
}

export default pathMap
