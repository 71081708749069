    import { constants, useTranslation } from 'cng-web-lib'
    import Namespace from 'src/constants/locale/Namespace'
    import pathMap from 'src/paths/PathMap_SCO_RateMgmt'
    import SCORateMgmt from 'src/constants/locale/key/SCORateMgmt'

    const {
        locale: {
            key: { UiComponentKeys }
        }
    } = constants

    const translationNamespace = [
        Namespace.UI_COMPONENT,
        Namespace.SCO_RATE_MGMT
    ]

    const contractBreadcrumbNameMap = (breadcrumbNameMap, translate) => {
        let contractTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmt.CONTRACT_TITLE)
        
        breadcrumbNameMap.set(pathMap.SCO_RATE_CONTRACT_LIST_VIEW, contractTitle)


    }

    function useBreadcrumbNameMap() {
        const { translate } = useTranslation(translationNamespace)
        const breadcrumbNameMap = new Map()

        contractBreadcrumbNameMap(breadcrumbNameMap, translate);

        let createTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmt.CREATE_CONTRACT_TITLE)
        breadcrumbNameMap.set(pathMap.SCO_RATE_CONTRACT_ADD_PAGE, createTitle)

        let viewTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmt.VIEW_CONTRACT_TITLE)
        breadcrumbNameMap.set(pathMap.SCO_RATE_CONTRACT_DETAILS_PAGE, viewTitle)

        let editTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmt.EDIT_CONTRACT_TITLE)
        breadcrumbNameMap.set(pathMap.SCO_RATE_CONTRACT_EDIT_PAGE, editTitle)
        
        
        return breadcrumbNameMap
    }

    function TranslateCngUiComponent(component, componentTitle) {
        const { translate } = useTranslation(translationNamespace)

        return translate(Namespace.UI_COMPONENT, component, {
            title: componentTitle
        })
    }

    export default useBreadcrumbNameMap
