import { useTranslation } from 'cng-web-lib'

import CompanyPreferenceKeys from 'src/constants/locale/key/CompanyPreference'
import ShippingInstructionKeys from 'src/constants/locale/key/ShippingInstruction'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_CompanyPreference'


const translationNamespace = [
  Namespace.UI_COMPONENT,
  Namespace.COMPANY_PREFERENCE
]

function useBreadcrumbNameMap() {
  const { translate } = useTranslation(translationNamespace)

  const breadcrumbNameMap = new Map()

  //* Address Book Shipper --------------------------------------------------------
  {
    let title = translate(
      Namespace.COMPANY_PREFERENCE,
      CompanyPreferenceKeys.ADDRESS_BOOK
    )

    // Address book
    let addressBookTitle = title
    breadcrumbNameMap.set(pathMap.ADDRESS_BOOK_SHIPPER, addressBookTitle)
  }
  //* Address Book Carrier --------------------------------------------------------
  {
    let title = translate(
      Namespace.COMPANY_PREFERENCE,
      CompanyPreferenceKeys.ADDRESS_BOOK
    )

    // Address book
    let addressBookTitle = title
    breadcrumbNameMap.set(pathMap.ADDRESS_BOOK_CARRIER, addressBookTitle)
  }
  //* SI setup Company Preference  --------------------------------------------
  {
    let title = translate(
      Namespace.COMPANY_PREFERENCE,
      CompanyPreferenceKeys.SI_SETTING
    )

    // Edit View
    let siSettingTitle = title
    breadcrumbNameMap.set(pathMap.SI_PREF_SETTING, siSettingTitle)
  }
  //* SI Auto Archive Preference  --------------------------------------------
  {
    let siTitle = translate(
      Namespace.COMPANY_PREFERENCE,
      CompanyPreferenceKeys.SI_COM_PREF_TITLE
    )

    // Edit View
    let editViewTitle = siTitle
    breadcrumbNameMap.set(
      pathMap.SI_COMPANY_PREFERENCE_EDIT_VIEW, editViewTitle)
  }
  //* FRB Auto Archive Preference  --------------------------------------------
  {
    let frbTitle = translate(
      Namespace.COMPANY_PREFERENCE,
      CompanyPreferenceKeys.FRB_COM_PREF_TITLE
    )

    // Edit View
    let editViewTitle = frbTitle
    breadcrumbNameMap.set(pathMap.FRB_COMPANY_PREFERENCE_EDIT_VIEW, editViewTitle)
  }

  return breadcrumbNameMap
}

export default useBreadcrumbNameMap
