import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_Report'
import ReportManagementKeys from "../../constants/locale/key/ReportManagementKeys";
import ReportSchedulerKeys from "../../constants/locale/key/ReportSchedulerKeys";
import GeneratedReportKeys from "../../constants/locale/key/GeneratedReportKeys";


const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.REPORT_MANAGEMENT,
    Namespace.REPORT_SCHEDULER,
    Namespace.GENERATED_REPORT,
    Namespace.REPORT_SCHEDULER_CUSTOMER
    // Namespace.REPORT_VIEW
]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

    // --------------- Manage Report ---------------
    {
        let title = translate(Namespace.REPORT_MANAGEMENT, ReportManagementKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_MANAGEMENT_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_MANAGEMENT_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_MANAGEMENT_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_MANAGEMENT_DETAILS_VIEW, detailsViewTitle)
    }
    // --------------- End Manage Report --------------


    // --------------- Report View --------------
    {
        let reportViewTitle = translate(Namespace.REPORT_MANAGEMENT, ReportManagementKeys.ReportParameterList.TITLE)
        let reportDownloadTitle = translate(Namespace.REPORT_MANAGEMENT, ReportManagementKeys.ReportParameterList.DOWNLOAD_TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, reportViewTitle)
        breadcrumbNameMap.set(pathMap.REPORT_DOWNLOAD_LIST_VIEW, listViewTitle)

        // Edit View
        breadcrumbNameMap.set(pathMap.REPORT_DOWNLOAD_EDIT_VIEW, reportDownloadTitle)
    }
    // --------------- End Report View --------------

    // --------------- Report Scheduler --------------
    {
        let title = translate(Namespace.REPORT_SCHEDULER, ReportSchedulerKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_SCHEDULER_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_SCHEDULER_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_SCHEDULER_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_SCHEDULER_DETAILS_VIEW, detailsViewTitle)
    }
    // --------------- End Report Scheduler--------------

    // --------------- Generated Report --------------
    {
        let title = translate(Namespace.GENERATED_REPORT, GeneratedReportKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.GENERATED_REPORT_LIST_VIEW, listViewTitle)
    }
    // --------------- End Generated Report--------------

    // --------------- Report Scheduler Customer--------------
    {
        let title = translate(Namespace.REPORT_SCHEDULER_CUSTOMER, ReportSchedulerKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_SCHEDULER_CUSTOMER_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_SCHEDULER_CUSTOMER_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_SCHEDULER_CUSTOMER_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.REPORT_SCHEDULER_CUSTOMER_DETAILS_VIEW, detailsViewTitle)
    }
    // --------------- End Report Scheduler Customer--------------
    // --------------- Generated Report  Customer--------------
    {
        let title = translate(Namespace.GENERATED_REPORT, GeneratedReportKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.GENERATED_REPORT_CUSTOMER_LIST_VIEW, listViewTitle)
    }
    // --------------- End Generated Report  Customer--------------
    return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {

    const { translate } = useTranslation(translationNamespace)

    let title = translate(
        Namespace.UI_COMPONENT,
        component, { title: componentTitle }
    )

    return (title);
}

export default useBreadcrumbNameMap
