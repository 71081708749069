const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-vesselschedule`

const CARRIER_ENROLL_PATH = `${MODULE_BASE}/carrier-enroll`
const POLLING_PATH = `${MODULE_BASE}/polling`
const TCAL_VS_VESSEL_PATH = `${MODULE_BASE}/tcal-vs-vessel`
const TCAL_VS_VOYAGE_PATH = `${MODULE_BASE}/tcal-vs-voyage`
const SEARCH_SCHEDULE_PATH = `${MODULE_BASE}/search-schedule`
const CARRIER_SEARCH_SCHEDULE_PATH = `${MODULE_BASE}/carrier-search-schedule`
const TCAL_VS_MANAGE_PREF_CAR_PATH = `${MODULE_BASE}/preferred-carrier`
const TCAL_VS_MANAGE_BLA_CAR_PATH = `${MODULE_BASE}/blacklisted-carriers`
const TCAL_VS_MANAGE_PREF_PP_PATH = `${MODULE_BASE}/preferred-port-pairs`
const TCAL_VS_MANAGE_VESSELS_PATH = `${MODULE_BASE}/manage-vessels`
const TCAL_VS_MANAGE_SCHEDULE_PATH = `${MODULE_BASE}/manage-schedule`
const VS_MIGRATION_SYNC_LOG_PATH = `${MODULE_BASE}/vs-migration-sync-log`

const pathMap = {
  CARRIER_ENROLL: CARRIER_ENROLL_PATH,
  CARRIER_ENROLL_LIST_VIEW: CARRIER_ENROLL_PATH,
  CARRIER_ENROLL_ADD_VIEW: `${CARRIER_ENROLL_PATH}/add`,
  CARRIER_ENROLL_EDIT_VIEW: `${CARRIER_ENROLL_PATH}/edit/:id`,
  CARRIER_ENROLL_DETAILS_VIEW: `${CARRIER_ENROLL_PATH}/view/:id`,

  POLLING: POLLING_PATH,
  POLLING_LIST_VIEW: POLLING_PATH,
  POLLING_ADD_VIEW: `${POLLING_PATH}/add`,
  POLLING_EDIT_VIEW: `${POLLING_PATH}/edit/:id`,
  POLLING_DETAILS_VIEW: `${POLLING_PATH}/view/:id`,

  TCAL_VS_VESSEL: TCAL_VS_VESSEL_PATH,
  TCAL_VS_VESSEL_LIST_VIEW: TCAL_VS_VESSEL_PATH,
  TCAL_VS_VESSEL_ADD_VIEW: `${TCAL_VS_VESSEL_PATH}/add`,
  TCAL_VS_VESSEL_EDIT_VIEW: `${TCAL_VS_VESSEL_PATH}/edit/:id`,
  TCAL_VS_VESSEL_DETAILS_VIEW: `${TCAL_VS_VESSEL_PATH}/view/:id`,

  TCAL_VS_VOYAGE: TCAL_VS_VOYAGE_PATH,
  TCAL_VS_VOYAGE_LIST_VIEW: TCAL_VS_VOYAGE_PATH,
  TCAL_VS_VOYAGE_ADD_VIEW: `${TCAL_VS_VOYAGE_PATH}/add`,
  TCAL_VS_VOYAGE_EDIT_VIEW: `${TCAL_VS_VOYAGE_PATH}/edit/:id`,
  TCAL_VS_VOYAGE_DETAILS_VIEW: `${TCAL_VS_VOYAGE_PATH}/view/:id`,

  TCAL_VS_MANAGE_PREF_CAR_LIST_VIEW: TCAL_VS_MANAGE_PREF_CAR_PATH,
  TCAL_VS_MANAGE_BLA_CAR_LIST_VIEW: TCAL_VS_MANAGE_BLA_CAR_PATH,
  TCAL_VS_MANAGE_PREF_PP_LIST_VIEW: TCAL_VS_MANAGE_PREF_PP_PATH,

  TCAL_VS_MANAGE_VESSELS_VIEW: TCAL_VS_MANAGE_VESSELS_PATH,
  TCAL_VS_MANAGE_VESSELS_ADD: `${TCAL_VS_MANAGE_VESSELS_PATH}/add`,
  TCAL_VS_MANAGE_VESSELS_VAMEND: `${TCAL_VS_MANAGE_VESSELS_PATH}/viewAndAmend`,

  TCAL_VS_MANAGE_SCHEDULE_LIST: TCAL_VS_MANAGE_SCHEDULE_PATH,
  TCAL_VS_MANAGE_SCHEDULE_ADD: `${TCAL_VS_MANAGE_SCHEDULE_PATH}/add`,
  TCAL_VS_MANAGE_SCHEDULE_ADD_DETAILS: `${TCAL_VS_MANAGE_SCHEDULE_PATH}/add/details`,
  TCAL_VS_MANAGE_SCHEDULE_ADD_FINISH: `${TCAL_VS_MANAGE_SCHEDULE_PATH}/add/finish`,
  TCAL_VS_MANAGE_SCHEDULE_VIEW: `${TCAL_VS_MANAGE_SCHEDULE_PATH}/view`,
  TCAL_VS_MANAGE_SCHEDULE_AMEND: `${TCAL_VS_MANAGE_SCHEDULE_PATH}/amend`,
  TCAL_VS_MANAGE_SCHEDULE_AMEND_DETAILS: `${TCAL_VS_MANAGE_SCHEDULE_PATH}/amend/details`,
  TCAL_VS_MANAGE_SCHEDULE_AMEND_FINISH: `${TCAL_VS_MANAGE_SCHEDULE_PATH}/amend/finish`,
  TCAL_VS_MANAGE_SCHEDULE_VIEW_UPLOAD_SCHEDULE: `${TCAL_VS_MANAGE_SCHEDULE_PATH}/view-upload-schedule`,
  TCAL_VS_MANAGE_SCHEDULE_ADD_FILE_UPLOAD: `${TCAL_VS_MANAGE_SCHEDULE_PATH}/file-upload`,
  TCAL_VS_MANAGE_SCHEDULE_ADD_FILE_UPLOAD_FINISH: `${TCAL_VS_MANAGE_SCHEDULE_PATH}/file-upload-finish`,

  SEARCH_SCHEDULE: SEARCH_SCHEDULE_PATH,
  SEARCH_SCHEDULE_VIEW: SEARCH_SCHEDULE_PATH,
  SEARCH_SCHEDULE_RESULT: `${SEARCH_SCHEDULE_PATH}/result`,

  CARRIER_SEARCH_SCHEDULE: CARRIER_SEARCH_SCHEDULE_PATH,
  CARRIER_SEARCH_SCHEDULE_RESULT: `${CARRIER_SEARCH_SCHEDULE_PATH}/result`,

  VS_MIGRATION_SYNC_LOG: VS_MIGRATION_SYNC_LOG_PATH,
  VS_MIGRATION_SYNC_LOG_LIST_VIEW: VS_MIGRATION_SYNC_LOG_PATH,
  VS_MIGRATION_SYNC_LOG_ADD_VIEW: `${VS_MIGRATION_SYNC_LOG_PATH}/add`,
  VS_MIGRATION_SYNC_LOG_EDIT_VIEW: `${VS_MIGRATION_SYNC_LOG_PATH}/edit/:id`,
  VS_MIGRATION_SYNC_LOG_DETAILS_VIEW: `${VS_MIGRATION_SYNC_LOG_PATH}/view/:id`
}

export default pathMap
