import { useTranslation } from 'cng-web-lib'

import EvgmKeys from 'src/constants/locale/key/Evgm'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_Evgm'

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.EVGM
]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

        // ---------------- My EVGM ---------------
        {
            let title = translate(Namespace.EVGM, EvgmKeys.TITLE)
    
            // List View
            let listViewTitle = title
            breadcrumbNameMap.set(pathMap.MY_EVGM, listViewTitle)

            let createEVGMTitle = translate(
                Namespace.EVGM, EvgmKeys.CREATE_EVGM
            )
            breadcrumbNameMap.set(pathMap.CREATE_NEW_EVGM, createEVGMTitle)
        }
    
        return breadcrumbNameMap
}

export default useBreadcrumbNameMap