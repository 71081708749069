import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap, { MODULE_BASE } from 'src/paths/PathMap_CalistaAdvisory'
import CalistaAdvisoryKeys from 'src/constants/locale/key/CalistaAdvisory'


const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.CALISTA_ADVISORY
]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

    {
        let title = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKeys.SUB_MODULE_TITLE.WIZARD)
        breadcrumbNameMap.set(pathMap.CALISTA_ADVISORY_WIZARD, title)
    }

    breadcrumbNameMap.set(MODULE_BASE, 'CIA Account management')
    breadcrumbNameMap.set(pathMap.CALISTA_ADMIN_ACCOUNT_MANAGEMENT, 'CIA Admin Account management')

    return breadcrumbNameMap

}

function TranslateCngUiComponent(component, componentTitle) {

    const { translate } = useTranslation(translationNamespace)

    let title = translate(
        Namespace.UI_COMPONENT,
        component, { title: componentTitle }
    )

    return (title);
}

export default useBreadcrumbNameMap