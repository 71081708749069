
const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-forwardhub`
const PROD_MGT_PATH = `${MODULE_BASE}/prod-mgt`
const INBOUND_PATH = `${MODULE_BASE}/inbound`
const INBOUND_PATH_NORMAL = `${MODULE_BASE}/inbound-normal`
const INBOUND_PATH_LATE = `${MODULE_BASE}/inbound-late`
const INBOUND_PATH_REINSTATE = `${MODULE_BASE}/inbound-reinstate`
const OUTBOUND_PATH = `${MODULE_BASE}/outbound`
const OUTBOUND_UPLOAD_PATH = `${MODULE_BASE}/outbound-upload`
const OUTBOUND_CREATE_PATH = `${MODULE_BASE}/outbound-create`
const OUTBOUND_SEARCH_PATH = `${MODULE_BASE}/outbound-search`
const PATH_STOCK_HOME = `${MODULE_BASE}/stock`
const PATH_STOCK_SEARCH_HOME = `${MODULE_BASE}/stock-search`
const PATH_STOCK_UPLOAD_HOME = `${MODULE_BASE}/stock-upload`
const PATH_STOCK_TRANSFER_HOME= `${MODULE_BASE}/stock-transfer`
const PATH_FTM_SEARCH_HOME = `${MODULE_BASE}/ftm-inq`
const PATH_FTM_REQUEST_HOME = `${MODULE_BASE}/ftm-req`
const PATH_JIT_SEARCH_HOME = `${MODULE_BASE}/jit-inq`
const PATH_JIT_REQUEST_HOME = `${MODULE_BASE}/jit-req`
const PATH_WAREHOUSETRACKING_SEARCH_HOME = `${MODULE_BASE}/warehousetracking-inq`
const PATH_WAREHOUSETRACKING_REQUEST_HOME = `${MODULE_BASE}/warehousetracking-req`
const PATH_DASHBOARD_BASIC_HOME = `${MODULE_BASE}/dashboard-basic`
const PATH_DASHBOARD_ENHANCED_HOME = `${MODULE_BASE}/dashboard-enhanced`
const VOLUME_REPORT_PATH = `${MODULE_BASE}/volume-report`
const ADMINLOG_PATH = `${MODULE_BASE}/adminlog`
const ADMINLOG_OUTBOUND_PATH = `${MODULE_BASE}/adminlog/outbound`
const DASH_LOAD_STATUS_PATH = `${MODULE_BASE}/dash-load-status`
const DASH_USER_LOAD_STATUS_PATH = `${MODULE_BASE}/dash-user-load-status`
const DASH_EXPORT_TEMPLATE_PATH = `${MODULE_BASE}/dash-export-template`
const COMMON_CONFIG_PATH = `${MODULE_BASE}/common-config`


const pathMap = {
  PROD_MGT: PROD_MGT_PATH,
  PROD_MGT_LIST_VIEW: PROD_MGT_PATH,
  PROD_MGT_ADD_VIEW: `${PROD_MGT_PATH}/add`,
  PROD_MGT_EDIT_VIEW: `${PROD_MGT_PATH}/edit/:id`,
  PROD_MGT_DETAILS_VIEW: `${PROD_MGT_PATH}/view/:id`,

  INBOUND_HOME: `${INBOUND_PATH}`,
  INBOUND_NORMAL: `${INBOUND_PATH_NORMAL}`,
  INBOUND_LATE: `${INBOUND_PATH_LATE}`,
  INBOUND_REINSTATE: `${INBOUND_PATH_REINSTATE}`,

  OUTBOUND_HOME: `${OUTBOUND_PATH}`,
  OUTBOUND_UPLOAD: `${OUTBOUND_UPLOAD_PATH}`,
  OUTBOUND_CREATE: `${OUTBOUND_CREATE_PATH}`,
  OUTBOUND_SEARCH: `${OUTBOUND_SEARCH_PATH}`,

  STOCK_HOME: `${PATH_STOCK_HOME}`,
  STOCK_SEARCH_HOME: `${PATH_STOCK_SEARCH_HOME}`,
  STOCK_UPLOAD_HOME: `${PATH_STOCK_UPLOAD_HOME}`,
  STOCK_TRANSFER_HOME: `${PATH_STOCK_TRANSFER_HOME}`,

    FTM_SEARCH_HOME: `${PATH_FTM_SEARCH_HOME}`,
    FTM_REQUEST_HOME: `${PATH_FTM_REQUEST_HOME}`,

    JIT_SEARCH_HOME: `${PATH_JIT_SEARCH_HOME}`,
    JIT_REQUEST_HOME: `${PATH_JIT_REQUEST_HOME}`,
    
    WAREHOUSETRACKING_SEARCH_HOME: `${PATH_WAREHOUSETRACKING_SEARCH_HOME}`,
    WAREHOUSETRACKING_REQUEST_HOME: `${PATH_WAREHOUSETRACKING_REQUEST_HOME}`,

  DASHBOARD_BASIC_HOME: `${PATH_DASHBOARD_BASIC_HOME}`,
  DASHBOARD_ENHANCED_HOME: `${PATH_DASHBOARD_ENHANCED_HOME}`,

  VOLUME_REPORT: `${VOLUME_REPORT_PATH}`,

  ADMINLOG_PATH : ADMINLOG_PATH,
  ADMINLOG_OUTBOUND: ADMINLOG_OUTBOUND_PATH,
  ADMINLOG_OUTBOUND_LIST_VIEW: ADMINLOG_OUTBOUND_PATH,
  ADMINLOG_OUTBOUND_ADD_VIEW: `${ADMINLOG_OUTBOUND_PATH}/add`,
  ADMINLOG_OUTBOUND_EDIT_VIEW: `${ADMINLOG_OUTBOUND_PATH}/edit/:id`,
  ADMINLOG_OUTBOUND_DETAILS_VIEW: `${ADMINLOG_OUTBOUND_PATH}/view/:id`,



  DASH_LOAD_STATUS: DASH_LOAD_STATUS_PATH,
  DASH_LOAD_STATUS_LIST_VIEW: DASH_LOAD_STATUS_PATH,
  DASH_LOAD_STATUS_ADD_VIEW: `${DASH_LOAD_STATUS_PATH}/add`,
  DASH_LOAD_STATUS_EDIT_VIEW: `${DASH_LOAD_STATUS_PATH}/edit/:id`,
  DASH_LOAD_STATUS_DETAILS_VIEW: `${DASH_LOAD_STATUS_PATH}/view/:id`,

    DASH_USER_LOAD_STATUS: DASH_USER_LOAD_STATUS_PATH,
    DASH_USER_LOAD_STATUS_LIST_VIEW: DASH_USER_LOAD_STATUS_PATH,
    DASH_USER_LOAD_STATUS_ADD_VIEW: `${DASH_USER_LOAD_STATUS_PATH}/add`,
    DASH_USER_LOAD_STATUS_EDIT_VIEW: `${DASH_USER_LOAD_STATUS_PATH}/edit/:id`,
    DASH_USER_LOAD_STATUS_DETAILS_VIEW: `${DASH_USER_LOAD_STATUS_PATH}/view/:id`,


    DASH_EXPORT_TEMPLATE: DASH_EXPORT_TEMPLATE_PATH,
    DASH_EXPORT_TEMPLATE_LIST_VIEW: DASH_EXPORT_TEMPLATE_PATH,
    DASH_EXPORT_TEMPLATE_ADD_VIEW: `${DASH_EXPORT_TEMPLATE_PATH}/add`,
    DASH_EXPORT_TEMPLATE_EDIT_VIEW: `${DASH_EXPORT_TEMPLATE_PATH}/edit/:id`,
    DASH_EXPORT_TEMPLATE_DETAILS_VIEW: `${DASH_EXPORT_TEMPLATE_PATH}/view/:id`,
    DASH_EXPORT_TEMPLATE_DETAILS_ADD_VIEW: `${DASH_EXPORT_TEMPLATE_PATH}/addTemplate/:id`,
    DASH_EXPORT_TEMPLATE_DETAILS_EDIT_VIEW: `${DASH_EXPORT_TEMPLATE_PATH}/editTemplate/:id`,

  COMMON_CONFIG_PATH: COMMON_CONFIG_PATH,
  COMMON_CONFIG_LIST_VIEW: COMMON_CONFIG_PATH,
  COMMON_CONFIG_ADD_VIEW: `${COMMON_CONFIG_PATH}/add`,
  COMMON_CONFIG_EDIT_VIEW: `${COMMON_CONFIG_PATH}/edit/:id`,
  COMMON_CONFIG_DETAILS_VIEW: `${COMMON_CONFIG_PATH}/view/:id`,

}

export default pathMap
