const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-ping`
const PING_ADMIN_BASE = `${MODULE_BASE}/ping-admin`
const PING_BASE = `${MODULE_BASE}/ping`

//* Ping Admin
const PING_ADMIN_SYS_CONFIG_PATH = `${PING_ADMIN_BASE}/system-config`
const PING_ADMIN_PARTY_CONFIG_PATH = `${PING_ADMIN_BASE}/party-config`
const PING_ADMIN_EVENT_SOURCE_PATH = `${PING_ADMIN_BASE}/event-source`
const PING_ADMIN_EVENT_CODE_PATH = `${PING_ADMIN_BASE}/event-code`
const PING_ADMIN_EVENT_CODE_MAPPING_PATH = `${PING_ADMIN_BASE}/event-code-mapping`
const PING_ADMIN_EVENT_CODE_LINK_PATH = `${PING_ADMIN_BASE}/event-code-link`
const PING_ADMIN_SHIPMENT_EVENT_PATH = `${PING_ADMIN_BASE}/shipment-event`
const PING_ADMIN_SEARCH_LOG_PATH = `${PING_ADMIN_BASE}/search-log`
const PING_ADMIN_VESSEL_VOYAGE_INFO_PATH = `${PING_ADMIN_BASE}/vessel-voyage-info`
const PING_ADMIN_CG_MIGRATION_LOG_PATH = `${PING_ADMIN_BASE}/cg-migration-log`

//* Event Hub Logs
const PING_ADMIN_EVENTHUB_LOG_PATH = `${PING_ADMIN_BASE}/eventhub-log`

//* Event Hub Logs
const PING_ADMIN_PORTNET_LOG_PATH = `${PING_ADMIN_BASE}/portnet-log`

//* Ping Search
const PING_SEARCH_PATH = `${PING_BASE}/search`

//* Ping - My Milestones
const PING_MY_MILESTONES_PATH = `${PING_BASE}/my-milestones`

const pathMap = {
    //* ------------------------------------------------------------------------
    //* Ping Admin 
    //* ------------------------------------------------------------------------
    PING_ADMIN_SYS_CONFIG: PING_ADMIN_SYS_CONFIG_PATH,
    PING_ADMIN_SYS_CONFIG_LIST_VIEW: PING_ADMIN_SYS_CONFIG_PATH,
    PING_ADMIN_SYS_CONFIG_DETAILS_VIEW: `${PING_ADMIN_SYS_CONFIG_PATH}/view/:id`,
    PING_ADMIN_SYS_CONFIG_EDIT_VIEW: `${PING_ADMIN_SYS_CONFIG_PATH}/edit/:id`,

    PING_ADMIN_PARTY_CONFIG: PING_ADMIN_PARTY_CONFIG_PATH,
    PING_ADMIN_PARTY_CONFIG_LIST_VIEW: PING_ADMIN_PARTY_CONFIG_PATH,
    PING_ADMIN_PARTY_CONFIG_DETAILS_VIEW: `${PING_ADMIN_PARTY_CONFIG_PATH}/view/:id`,
    PING_ADMIN_PARTY_CONFIG_EDIT_VIEW: `${PING_ADMIN_PARTY_CONFIG_PATH}/edit/:id`,

    PING_ADMIN_EVENT_SOURCE: PING_ADMIN_EVENT_SOURCE_PATH,
    PING_ADMIN_EVENT_SOURCE_LIST_VIEW: PING_ADMIN_EVENT_SOURCE_PATH,
    PING_ADMIN_EVENT_SOURCE_DETAILS_VIEW: `${PING_ADMIN_EVENT_SOURCE_PATH}/view/:id`,
    PING_ADMIN_EVENT_SOURCE_ADD_VIEW: `${PING_ADMIN_EVENT_SOURCE_PATH}/add`,
    PING_ADMIN_EVENT_SOURCE_EDIT_VIEW: `${PING_ADMIN_EVENT_SOURCE_PATH}/edit/:id`,

    PING_ADMIN_EVENT_CODE: PING_ADMIN_EVENT_CODE_PATH,
    PING_ADMIN_EVENT_CODE_LIST_VIEW: PING_ADMIN_EVENT_CODE_PATH,
    PING_ADMIN_EVENT_CODE_DETAILS_VIEW: `${PING_ADMIN_EVENT_CODE_PATH}/view/:id`,
    PING_ADMIN_EVENT_CODE_ADD_VIEW: `${PING_ADMIN_EVENT_CODE_PATH}/add`,
    PING_ADMIN_EVENT_CODE_EDIT_VIEW: `${PING_ADMIN_EVENT_CODE_PATH}/edit/:id`,

    PING_ADMIN_EVENT_CODE_MAPPING: PING_ADMIN_EVENT_CODE_MAPPING_PATH,
    PING_ADMIN_EVENT_CODE_MAPPING_LIST_VIEW: PING_ADMIN_EVENT_CODE_MAPPING_PATH,
    PING_ADMIN_EVENT_CODE_MAPPING_DETAILS_VIEW: `${PING_ADMIN_EVENT_CODE_MAPPING_PATH}/view/:id`,
    PING_ADMIN_EVENT_CODE_MAPPING_ADD_VIEW: `${PING_ADMIN_EVENT_CODE_MAPPING_PATH}/add`,
    PING_ADMIN_EVENT_CODE_MAPPING_EDIT_VIEW: `${PING_ADMIN_EVENT_CODE_MAPPING_PATH}/edit/:id`,

    PING_ADMIN_EVENT_CODE_LINK: PING_ADMIN_EVENT_CODE_LINK_PATH,
    PING_ADMIN_EVENT_CODE_LINK_LIST_VIEW: PING_ADMIN_EVENT_CODE_LINK_PATH,
    PING_ADMIN_EVENT_CODE_LINK_DETAILS_VIEW: `${PING_ADMIN_EVENT_CODE_LINK_PATH}/view/:id`,
    PING_ADMIN_EVENT_CODE_LINK_ADD_VIEW: `${PING_ADMIN_EVENT_CODE_LINK_PATH}/add`,
    PING_ADMIN_EVENT_CODE_LINK_EDIT_VIEW: `${PING_ADMIN_EVENT_CODE_LINK_PATH}/edit/:id`,

    PING_ADMIN_SHIPMENT_EVENT: PING_ADMIN_SHIPMENT_EVENT_PATH,
    PING_ADMIN_SHIPMENT_EVENT_LIST_VIEW: PING_ADMIN_SHIPMENT_EVENT_PATH,
    PING_ADMIN_SHIPMENT_EVENT_DETAILS_VIEW: `${PING_ADMIN_SHIPMENT_EVENT_PATH}/view/:id`,
    PING_ADMIN_SHIPMENT_EVENT_ADD_VIEW: `${PING_ADMIN_SHIPMENT_EVENT_PATH}/add`,
    PING_ADMIN_SHIPMENT_EVENT_EDIT_VIEW: `${PING_ADMIN_SHIPMENT_EVENT_PATH}/edit/:id`,

    PING_ADMIN_SEARCH_LOG: PING_ADMIN_SEARCH_LOG_PATH,
    PING_ADMIN_SEARCH_LOG_LIST_VIEW: PING_ADMIN_SEARCH_LOG_PATH,
    PING_ADMIN_SEARCH_LOG_DETAILS_VIEW: `${PING_ADMIN_SEARCH_LOG_PATH}/view/:id`,

    PING_ADMIN_VESSEL_VOYAGE_INFO: PING_ADMIN_VESSEL_VOYAGE_INFO_PATH,
    PING_ADMIN_VESSEL_VOYAGE_INFO_LIST_VIEW: PING_ADMIN_VESSEL_VOYAGE_INFO_PATH,
    PING_ADMIN_VESSEL_VOYAGE_INFO_DETAILS_VIEW: `${PING_ADMIN_VESSEL_VOYAGE_INFO_PATH}/view/:id`,

    // Event Hub API Logs
    PING_ADMIN_EVENTHUB_LOG: PING_ADMIN_EVENTHUB_LOG_PATH,

    // PortNet API Logs
    PING_ADMIN_PORTNET_LOG: PING_ADMIN_PORTNET_LOG_PATH,

    // CG Migration Logs
    PING_ADMIN_CG_MIGRATION_LOG: PING_ADMIN_CG_MIGRATION_LOG_PATH,

    //* ------------------------------------------------------------------------
    //* Ping - Search
    //* ------------------------------------------------------------------------
    PING_SEARCH: PING_SEARCH_PATH,
    PING_SEARCH_BOOKING_LIST: `${PING_SEARCH_PATH}/bookinglist`,
    PING_SEARCH_FAVOURITE_LIST: `${PING_SEARCH_PATH}/favourite`,
    PING_SEARCH_HISTORY_LIST: `${PING_SEARCH_PATH}/history`,
    PING_SEARCH_BOOKING_REF: `${PING_SEARCH_PATH}/booking-ref/:bookingRef`,
    PING_SEARCH_BL_NUM: `${PING_SEARCH_PATH}/bl-num/:blNum`,
    PING_SEARCH_CONTAINER_NUM: `${PING_SEARCH_PATH}/container-num/:containerNum`,
    PING_SEARCH_VESSEL_NAME: `${PING_SEARCH_PATH}/vessel-name/:vesselName`,

    //* ------------------------------------------------------------------------
    //* Ping - My Milestones
    //* ------------------------------------------------------------------------
    PING_MY_MILESTONES: PING_MY_MILESTONES_PATH,

    // CRUD pages
    PING_MY_MILESTONES_LIST_VIEW: PING_MY_MILESTONES_PATH,
    PING_MY_MILESTONES_DETAILS_VIEW: `${PING_MY_MILESTONES_PATH}/view/:id`,
    PING_MY_MILESTONES_ADD_VIEW: `${PING_MY_MILESTONES_PATH}/add`,
    PING_MY_MILESTONES_ADD_SUCCESS_VIEW: `${PING_MY_MILESTONES_PATH}/add-success/:id`,
    PING_MY_MILESTONES_EDIT_VIEW: `${PING_MY_MILESTONES_PATH}/edit/:id`,
    PING_MY_MILESTONES_EDIT_SUCCESS_VIEW: `${PING_MY_MILESTONES_PATH}/edit-success/:id`,

    // Upload pages
    PING_MY_MILESTONES_UPLOAD_LIST_VIEW: `${PING_MY_MILESTONES_PATH}/upload`,
    PING_SEARCH_EVENT_SOURCE: `${PING_BASE}/event-source`

}

export default pathMap
