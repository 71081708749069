import { constants, useTranslation } from 'cng-web-lib'

import NmotKeys from 'src/constants/locale/key/Nmot'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_Nmot'

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const translationNamespace = [Namespace.UI_COMPONENT, Namespace.NMOT]

function useBreadcrumbNameMap() {
  const { translate } = useTranslation(translationNamespace)

  const breadcrumbNameMap = new Map()

  //* Hinterland destination master List
  {
    let title = translate(Namespace.NMOT, NmotKeys.HINTERLAND_DEST_ML)

    // Hinterland Destination
    let hDestTitle = title
    breadcrumbNameMap.set(pathMap.NMOT_H_DEST, hDestTitle)
  }

  //* NMoT Admin
  {
    let title = translate(Namespace.NMOT, NmotKeys.NMOT_ADMIN)

    let adminTitle = title
    breadcrumbNameMap.set(pathMap.NMOT_ADMIN, adminTitle)
  }

  //* NMoT Api Logs
  {
    let title = translate(Namespace.NMOT, NmotKeys.NMOT_LOGS)

    let apiLogsTitle = title
    breadcrumbNameMap.set(pathMap.NMOT_LOGS, apiLogsTitle)
  }

  //* NMoT Add
  {
    let title = translate(Namespace.NMOT, NmotKeys.NMOT_ADD)

    let nmotAddTitle = title
    breadcrumbNameMap.set(pathMap.NMOT_ADD, nmotAddTitle)
  }

  //* NMoT Edit
  {
    let title = translate(Namespace.NMOT, NmotKeys.NMOT_EDIT)

    let nmotEditTitle = title
    breadcrumbNameMap.set(pathMap.NMOT_EDIT, nmotEditTitle)
  }

    //* NMoT View
    {
      let title = translate(Namespace.NMOT, NmotKeys.NMOT_VIEW)
  
      let nmotViewTitle = title
      breadcrumbNameMap.set(pathMap.NMOT_VIEW, nmotViewTitle)
    }

  //* NMoT Upload
  {
    let title = translate(Namespace.NMOT, NmotKeys.NMOT_UPLOAD)

    let nmotUploadTitle = title
    breadcrumbNameMap.set(pathMap.NMOT_UPLOAD, nmotUploadTitle)
  }

  //* NMoT Dashboard
  {
    let title = translate(Namespace.NMOT, NmotKeys.NMOT_DASHBOARD)

    let nmotDashboardTitle = title
    breadcrumbNameMap.set(pathMap.NMOT_DASHBOARD, nmotDashboardTitle)
  }

  return breadcrumbNameMap
}

export default useBreadcrumbNameMap
