const LAYOUT_PATH = '' // This value cannot be changed.
const HOME_PATH = `${LAYOUT_PATH}/home`
const CUSTOM_DASHBOARD = `${LAYOUT_PATH}/dashboard`
const CARRIER_ENROLL_PATH = `${LAYOUT_PATH}/cal-vesselschedule/carrier-enroll`
const POLLING_PATH = `${LAYOUT_PATH}/cal-vesselschedule/polling`
const NEW_BOOKING_PATH = `${LAYOUT_PATH}/cal-freightbooking/new-booking`
const TCAL_VS_VESSEL_PATH = `${LAYOUT_PATH}/cal-vesselschedule/tcal-vs-vessel`
const TCAL_VS_VOYAGE_PATH = `${LAYOUT_PATH}/cal-vesselschedule/tcal-vs-voyage`
const SEARCH_SCHEDULE_PATH = `${LAYOUT_PATH}/cal-vesselschedule/search-schedule`
const CODE_MAPPING_PATH = `${LAYOUT_PATH}/cal-vesselschedule/tcal-vs-code-mapping`
const TCAL_VS_MANAGE_PREF_CAR_PATH = `${LAYOUT_PATH}/cal-vesselschedule/preferred-carrier`
const TCAL_VS_MANAGE_BLA_CAR_PATH = `${LAYOUT_PATH}/cal-vesselschedule/blacklisted-carriers`
const TCAL_VS_MANAGE_PREF_PP_PATH = `${LAYOUT_PATH}/cal-vesselschedule/preferred-port-pairs`
const SUBSCRIPTION_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/subscription`
const TCAL_PARTY_CARRIER_CODE_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/tcal-party-carrier-code`
const TCAL_AS_USER_GROUP_MASTER_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/tcal-as-user-group-master`
const TTFB_TPR_PARTY_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/ttfb-tpr-party`
const TTFB_TPR_USER_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/ttfb-tpr-user`
const ACCESS_LEVEL_MASTER_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/access-level-master`
const ACCESS_LEVEL_RULE_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/access-level-rule`
const ACCESS_LEVEL_COMPANY_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/access-level-company`
const ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/access-level-company-custom-rule`
const ACCESS_LEVEL_USER_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/access-level-user`
const SHIPPER_REQUEST_TYPE_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/shipper-request-type`
const COMPANY_FOR_SHIPPER_REQUEST_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/company-for-shipper-request`
const COMPANY_FOR_USER_GROUP_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/company-for-user-group`
const USER_FOR_SHIPPER_REQUEST_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/user-for-shipper-request`
const NBS_BILLING_CONFIG_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/nbs-charge-code-mapping`
const NBS_CHARGE_CODE_MAPPING_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/nbs-charge-code-mapping`
const NBS_FILE_PATH = `${LAYOUT_PATH}/calista-platform-ng-app-main/account-setup/nbs-file`
const SCO_JOB_CONIFG_PATH = `${LAYOUT_PATH}/cal-sco/jobconfig`
const PARTY_MICROSITE = `${LAYOUT_PATH}/member`
const ECO_CALCULATOR_PATH = `${LAYOUT_PATH}/eco-calculator`
const METHODOLOGY_PATH = `${LAYOUT_PATH}/methodology`
const SCO_ADMIN_CODE_TYPE_PATH = `${LAYOUT_PATH}/sco-admin/code-master`
const SCO_ADMIN_COUNTRY_CODE_PATH =`${LAYOUT_PATH}/sco-admin/country-code`
const SCO_ADMIN_COUNTRY_STATE_CODE_PATH = `${LAYOUT_PATH}/sco-admin/state-code`
const SCO_ADMIN_TIMEZONE_PATH = `${LAYOUT_PATH}/sco-admin/timezone`
const CALISTA_CEC_ACCOUNT = `${LAYOUT_PATH}/company/account`
const SCO_ADMIN_PRINCIPAL_MAPPING_PATH = `${LAYOUT_PATH}/sco-admin/principalmapping`



const pathMap = {
  ARTICLE_VIEW: `${HOME_PATH}/articles/:id`,
  EVENT_VIEW: `${HOME_PATH}/events/:id`,
  HOME_VIEW: HOME_PATH,
  HOME_CN_VIEW: `${HOME_PATH}-cn`,
  HOME: HOME_PATH,
  CUSTOM_DASHBOARD: CUSTOM_DASHBOARD,
  HOME_CN: `${HOME_PATH}-cn`,
  LIST_VIEW: `${HOME_PATH}/:category`,
  NEWS_VIEW: `${HOME_PATH}/news/:id`,
  SEARCH_VIEW: `${HOME_PATH}/search`,
  SITE_CONTENT_VIEW: `${HOME_PATH}/site-content/:code`,
  TEST_VIEW: `/test`,
  VIDEO_VIEW: `${HOME_PATH}/videos/:id`,

  CARRIER_ENROLL: CARRIER_ENROLL_PATH,
  CARRIER_ENROLL_LIST_VIEW: CARRIER_ENROLL_PATH,
  CARRIER_ENROLL_ADD_VIEW: `${CARRIER_ENROLL_PATH}/add`,
  CARRIER_ENROLL_EDIT_VIEW: `${CARRIER_ENROLL_PATH}/edit/:id`,
  CARRIER_ENROLL_DETAILS_VIEW: `${CARRIER_ENROLL_PATH}/view/:id`,

  POLLING: POLLING_PATH,
  POLLING_LIST_VIEW: POLLING_PATH,
  POLLING_ADD_VIEW: `${POLLING_PATH}/add`,
  POLLING_EDIT_VIEW: `${POLLING_PATH}/edit/:id`,
  POLLING_DETAILS_VIEW: `${POLLING_PATH}/view/:id`,

  NEW_BOOKING: NEW_BOOKING_PATH,
  NEW_BOOKING_REVIEW: `${NEW_BOOKING_PATH}/review`,

  TCAL_VS_VESSEL: TCAL_VS_VESSEL_PATH,
  TCAL_VS_VESSEL_LIST_VIEW: TCAL_VS_VESSEL_PATH,
  TCAL_VS_VESSEL_ADD_VIEW: `${TCAL_VS_VESSEL_PATH}/add`,
  TCAL_VS_VESSEL_EDIT_VIEW: `${TCAL_VS_VESSEL_PATH}/edit/:id`,
  TCAL_VS_VESSEL_DETAILS_VIEW: `${TCAL_VS_VESSEL_PATH}/view/:id`,

  TCAL_VS_VOYAGE: TCAL_VS_VOYAGE_PATH,
  TCAL_VS_VOYAGE_LIST_VIEW: TCAL_VS_VOYAGE_PATH,
  TCAL_VS_VOYAGE_ADD_VIEW: `${TCAL_VS_VOYAGE_PATH}/add`,
  TCAL_VS_VOYAGE_EDIT_VIEW: `${TCAL_VS_VOYAGE_PATH}/edit/:id`,
  TCAL_VS_VOYAGE_DETAILS_VIEW: `${TCAL_VS_VOYAGE_PATH}/view/:id`,

  TCAL_VS_MANAGE_PREF_CAR_LIST_VIEW: TCAL_VS_MANAGE_PREF_CAR_PATH,
  TCAL_VS_MANAGE_BLA_CAR_LIST_VIEW: TCAL_VS_MANAGE_BLA_CAR_PATH,
  TCAL_VS_MANAGE_PREF_PP_LIST_VIEW: TCAL_VS_MANAGE_PREF_PP_PATH,

  CODE_MAPPING: CODE_MAPPING_PATH,
  CODE_MAPPING_LIST_VIEW: CODE_MAPPING_PATH,
  CODE_MAPPING_ADD_VIEW: `${CODE_MAPPING_PATH}/add`,
  CODE_MAPPING_EDIT_VIEW: `${CODE_MAPPING_PATH}/edit/:id`,
  CODE_MAPPING_DETAILS_VIEW: `${CODE_MAPPING_PATH}/view/:id`,

  SEARCH_SCHEDULE: SEARCH_SCHEDULE_PATH,
  SEARCH_SCHEDULE_VIEW: SEARCH_SCHEDULE_PATH,
  SEARCH_SCHEDULE_RESULT: `${SEARCH_SCHEDULE_PATH}/result`,

  SUBSCRIPTION: SUBSCRIPTION_PATH,
  SUBSCRIPTION_LIST_VIEW: SUBSCRIPTION_PATH,
  SUBSCRIPTION_ADD_VIEW: `${SUBSCRIPTION_PATH}/add`,
  SUBSCRIPTION_EDIT_VIEW: `${SUBSCRIPTION_PATH}/edit/:id`,
  SUBSCRIPTION_DETAILS_VIEW: `${SUBSCRIPTION_PATH}/view/:id`,

  TCAL_PARTY_CARRIER_CODE: TCAL_PARTY_CARRIER_CODE_PATH,
  TCAL_PARTY_CARRIER_CODE_LIST_VIEW: TCAL_PARTY_CARRIER_CODE_PATH,
  TCAL_PARTY_CARRIER_CODE_ADD_VIEW: `${TCAL_PARTY_CARRIER_CODE_PATH}/add`,
  TCAL_PARTY_CARRIER_CODE_EDIT_VIEW: `${TCAL_PARTY_CARRIER_CODE_PATH}/edit/:id`,
  TCAL_PARTY_CARRIER_CODE_DETAILS_VIEW: `${TCAL_PARTY_CARRIER_CODE_PATH}/view/:id`,

  TCAL_AS_USER_GROUP_MASTER: TCAL_AS_USER_GROUP_MASTER_PATH,
  TCAL_AS_USER_GROUP_MASTER_LIST_VIEW: TCAL_AS_USER_GROUP_MASTER_PATH,
  TCAL_AS_USER_GROUP_MASTER_ADD_VIEW: `${TCAL_AS_USER_GROUP_MASTER_PATH}/add`,
  TCAL_AS_USER_GROUP_MASTER_EDIT_VIEW: `${TCAL_AS_USER_GROUP_MASTER_PATH}/edit/:id`,
  TCAL_AS_USER_GROUP_MASTER_DETAILS_VIEW: `${TCAL_AS_USER_GROUP_MASTER_PATH}/view/:id`,

  TTFB_TPR_PARTY: TTFB_TPR_PARTY_PATH,
  TTFB_TPR_PARTY_LIST_VIEW: TTFB_TPR_PARTY_PATH,
  TTFB_TPR_PARTY_ADD_VIEW: `${TTFB_TPR_PARTY_PATH}/add`,
  TTFB_TPR_PARTY_EDIT_VIEW: `${TTFB_TPR_PARTY_PATH}/edit/:id`,
  TTFB_TPR_PARTY_DETAILS_VIEW: `${TTFB_TPR_PARTY_PATH}/view/:id`,

  TTFB_TPR_USER: TTFB_TPR_USER_PATH,
  TTFB_TPR_USER_LIST_VIEW: TTFB_TPR_USER_PATH,
  TTFB_TPR_USER_ADD_VIEW: `${TTFB_TPR_USER_PATH}/add`,
  TTFB_TPR_USER_EDIT_VIEW: `${TTFB_TPR_USER_PATH}/edit/:id`,
  TTFB_TPR_USER_DETAILS_VIEW: `${TTFB_TPR_USER_PATH}/view/:id`,

  ACCESS_LEVEL_MASTER: ACCESS_LEVEL_MASTER_PATH,
  ACCESS_LEVEL_MASTER_LIST_VIEW: ACCESS_LEVEL_MASTER_PATH,
  ACCESS_LEVEL_MASTER_ADD_VIEW: `${ACCESS_LEVEL_MASTER_PATH}/add`,
  ACCESS_LEVEL_MASTER_EDIT_VIEW: `${ACCESS_LEVEL_MASTER_PATH}/edit/:id`,
  ACCESS_LEVEL_MASTER_DETAILS_VIEW: `${ACCESS_LEVEL_MASTER_PATH}/view/:id`,

  ACCESS_LEVEL_RULE: ACCESS_LEVEL_RULE_PATH,
  ACCESS_LEVEL_RULE_LIST_VIEW: ACCESS_LEVEL_RULE_PATH,
  ACCESS_LEVEL_RULE_ADD_VIEW: `${ACCESS_LEVEL_RULE_PATH}/add`,
  ACCESS_LEVEL_RULE_EDIT_VIEW: `${ACCESS_LEVEL_RULE_PATH}/edit/:id`,
  ACCESS_LEVEL_RULE_DETAILS_VIEW: `${ACCESS_LEVEL_RULE_PATH}/view/:id`,

  ACCESS_LEVEL_COMPANY: ACCESS_LEVEL_COMPANY_PATH,
  ACCESS_LEVEL_COMPANY_LIST_VIEW: ACCESS_LEVEL_COMPANY_PATH,
  ACCESS_LEVEL_COMPANY_ADD_VIEW: `${ACCESS_LEVEL_COMPANY_PATH}/add`,
  ACCESS_LEVEL_COMPANY_EDIT_VIEW: `${ACCESS_LEVEL_COMPANY_PATH}/edit/:id`,
  ACCESS_LEVEL_COMPANY_DETAILS_VIEW: `${ACCESS_LEVEL_COMPANY_PATH}/view/:id`,

  ACCESS_LEVEL_COMPANY_CUSTOM_RULE: ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH,
  ACCESS_LEVEL_COMPANY_CUSTOM_RULE_LIST_VIEW:
    ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH,
  ACCESS_LEVEL_COMPANY_CUSTOM_RULE_ADD_VIEW: `${ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH}/add`,
  ACCESS_LEVEL_COMPANY_CUSTOM_RULE_EDIT_VIEW: `${ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH}/edit/:id`,
  ACCESS_LEVEL_COMPANY_CUSTOM_RULE_DETAILS_VIEW: `${ACCESS_LEVEL_COMPANY_CUSTOM_RULE_PATH}/view/:id`,

  ACCESS_LEVEL_USER: ACCESS_LEVEL_USER_PATH,
  ACCESS_LEVEL_USER_LIST_VIEW: ACCESS_LEVEL_USER_PATH,
  ACCESS_LEVEL_USER_ADD_VIEW: `${ACCESS_LEVEL_USER_PATH}/add`,
  ACCESS_LEVEL_USER_EDIT_VIEW: `${ACCESS_LEVEL_USER_PATH}/edit/:id`,
  ACCESS_LEVEL_USER_DETAILS_VIEW: `${ACCESS_LEVEL_USER_PATH}/view/:id`,

  SHIPPER_REQUEST_TYPE: SHIPPER_REQUEST_TYPE_PATH,
  SHIPPER_REQUEST_TYPE_LIST_VIEW: SHIPPER_REQUEST_TYPE_PATH,
  SHIPPER_REQUEST_TYPE_ADD_VIEW: `${SHIPPER_REQUEST_TYPE_PATH}/add`,
  SHIPPER_REQUEST_TYPE_EDIT_VIEW: `${SHIPPER_REQUEST_TYPE_PATH}/edit/:id`,
  SHIPPER_REQUEST_TYPE_DETAILS_VIEW: `${SHIPPER_REQUEST_TYPE_PATH}/view/:id`,

  COMPANY_FOR_SHIPPER_REQUEST: COMPANY_FOR_SHIPPER_REQUEST_PATH,
  COMPANY_FOR_SHIPPER_REQUEST_LIST_VIEW: COMPANY_FOR_SHIPPER_REQUEST_PATH,
  COMPANY_FOR_SHIPPER_REQUEST_ADD_VIEW: `${COMPANY_FOR_SHIPPER_REQUEST_PATH}/add`,
  COMPANY_FOR_SHIPPER_REQUEST_EDIT_VIEW: `${COMPANY_FOR_SHIPPER_REQUEST_PATH}/edit/:id`,
  COMPANY_FOR_SHIPPER_REQUEST_DETAILS_VIEW: `${COMPANY_FOR_SHIPPER_REQUEST_PATH}/view/:id`,

  COMPANY_FOR_USER_GROUP: COMPANY_FOR_USER_GROUP_PATH,
  COMPANY_FOR_USER_GROUP_LIST_VIEW: COMPANY_FOR_USER_GROUP_PATH,
  COMPANY_FOR_USER_GROUP_ADD_VIEW: `${COMPANY_FOR_USER_GROUP_PATH}/add`,
  COMPANY_FOR_USER_GROUP_EDIT_VIEW: `${COMPANY_FOR_USER_GROUP_PATH}/edit/:id`,
  COMPANY_FOR_USER_GROUP_DETAILS_VIEW: `${COMPANY_FOR_USER_GROUP_PATH}/view/:id`,

  USER_FOR_SHIPPER_REQUEST: USER_FOR_SHIPPER_REQUEST_PATH,
  USER_FOR_SHIPPER_REQUEST_LIST_VIEW: USER_FOR_SHIPPER_REQUEST_PATH,
  USER_FOR_SHIPPER_REQUEST_ADD_VIEW: `${USER_FOR_SHIPPER_REQUEST_PATH}/add`,
  USER_FOR_SHIPPER_REQUEST_EDIT_VIEW: `${USER_FOR_SHIPPER_REQUEST_PATH}/edit/:id`,
  USER_FOR_SHIPPER_REQUEST_DETAILS_VIEW: `${USER_FOR_SHIPPER_REQUEST_PATH}/view/:id`,

  NBS_BILLING_CONFIG: NBS_BILLING_CONFIG_PATH,
  NBS_BILLING_CONFIG_LIST_VIEW: NBS_BILLING_CONFIG_PATH,
  NBS_BILLING_CONFIG_PATH_ADD_VIEW: `${NBS_BILLING_CONFIG_PATH}/add`,
  NBS_BILLING_CONFIG_PATH_EDIT_VIEW: `${NBS_BILLING_CONFIG_PATH}/edit/:id`,
  NBS_BILLING_CONFIG_PATH_DETAILS_VIEW: `${NBS_BILLING_CONFIG_PATH}/view/:id`,

  NBS_CHARGE_CODE_MAPPING: NBS_CHARGE_CODE_MAPPING_PATH,
  NBS_CHARGE_CODE_MAPPING_LIST_VIEW: NBS_CHARGE_CODE_MAPPING_PATH,
  NBS_CHARGE_CODE_MAPPING_ADD_VIEW: `${NBS_CHARGE_CODE_MAPPING_PATH}/add`,
  NBS_CHARGE_CODE_MAPPING_EDIT_VIEW: `${NBS_CHARGE_CODE_MAPPING_PATH}/edit/:id`,
  NBS_CHARGE_CODE_MAPPING_DETAILS_VIEW: `${NBS_CHARGE_CODE_MAPPING_PATH}/view/:id`,

  NBS_FILE: NBS_FILE_PATH,
  NBS_FILE_LIST_VIEW: NBS_FILE_PATH,
  NBS_FILE_ADD_VIEW: `${NBS_FILE_PATH}/add`,
  NBS_FILE_EDIT_VIEW: `${NBS_FILE_PATH}/edit/:id`,
  NBS_FILE_DETAILS_VIEW: `${NBS_FILE_PATH}/view/:id`,

  SCO_JOB_CONFIG: SCO_JOB_CONIFG_PATH,
  SCO_JOB_CONFIG_LIST_VIEW: SCO_JOB_CONIFG_PATH,
  SCO_JOB_CONFIG_ADD_VIEW: `${SCO_JOB_CONIFG_PATH}/create`,
  SCO_JOB_CONFIG_EDIT_VIEW: `${SCO_JOB_CONIFG_PATH}/edit/:id`,
  SCO_JOB_CONFIG_DETAILS_VIEW: `${SCO_JOB_CONIFG_PATH}/view/:id`,

  PARTY_MICROSITE_VIEW: `${PARTY_MICROSITE}/page/:website`,
  PARTY_MICROSITE_DEMO: `${PARTY_MICROSITE}/demo`,
  PARTY_MICROSITE_VIEW_BASE: `${PARTY_MICROSITE}/page`,

  ECO_CALCULATOR: ECO_CALCULATOR_PATH,
  ECO_CALCULATOR_LIST_VIEW: ECO_CALCULATOR_PATH,
  ECO_CALCULATOR_ADD_VIEW: `${ECO_CALCULATOR_PATH}/add`,
  ECO_CALCULATOR_EDIT_VIEW: `${ECO_CALCULATOR_PATH}/edit/:id`,
  ECO_CALCULATOR_DETAILS_VIEW: `${ECO_CALCULATOR_PATH}/view/:id`,

  METHODOLOGY_PATH: METHODOLOGY_PATH,
  METHODOLOGY_PATH_LIST_VIEW: METHODOLOGY_PATH,
  METHODOLOGY_PATH_ADD_VIEW: `${METHODOLOGY_PATH}/add`,
  METHODOLOGY_PATH_EDIT_VIEW: `${METHODOLOGY_PATH}/edit/:id`,
  METHODOLOGY_PATH_DETAILS_VIEW: `${METHODOLOGY_PATH}/view/:id`,
  SCO_ADMIN_CODE_TYPE: SCO_ADMIN_CODE_TYPE_PATH,
  SCO_ADMIN_CODE_TYPE_LIST_VIEW: SCO_ADMIN_CODE_TYPE_PATH,
  SCO_ADMIN_CODE_TYPE_ADD_VIEW: `${SCO_ADMIN_CODE_TYPE_PATH}/add`,
  SCO_ADMIN_CODE_TYPE_EDIT_VIEW: `${SCO_ADMIN_CODE_TYPE_PATH}/edit/:id`,
  SCO_ADMIN_CODE_TYPE_DETAILS_VIEW: `${SCO_ADMIN_CODE_TYPE_PATH}/view/:id`,

  SCO_ADMIN_COUNTRY_CODE: SCO_ADMIN_COUNTRY_CODE_PATH,
  SCO_ADMIN_COUNTRY_CODE_LIST_VIEW: SCO_ADMIN_COUNTRY_CODE_PATH,
  SCO_ADMIN_COUNTRY_CODE_DETAILS_VIEW: `${SCO_ADMIN_COUNTRY_CODE_PATH}/view/:countryCode`,

  SCO_ADMIN_COUNTRY_STATE_CODE: SCO_ADMIN_COUNTRY_STATE_CODE_PATH,
  SCO_ADMIN_STATE_CODE_COUNTRY_LIST_VIEW: SCO_ADMIN_COUNTRY_STATE_CODE_PATH,
  SCO_ADMIN_STATE_CODE_STATE_LIST_VIEW: `${SCO_ADMIN_COUNTRY_STATE_CODE_PATH}/view/:countryCode`,

  SCO_ADMIN_TIMEZONE: SCO_ADMIN_TIMEZONE_PATH,
  SCO_ADMIN_TIMEZONE_LIST_VIEW: SCO_ADMIN_TIMEZONE_PATH,
  SCO_ADMIN_TIMEZONE_DETAILS_VIEW: `${SCO_ADMIN_TIMEZONE_PATH}/view/:id`,
  CEC_ACCOUNT: CALISTA_CEC_ACCOUNT,
  CEC_ACCOUNT_LIST_VIEW: CALISTA_CEC_ACCOUNT,
  CEC_ACCOUNT_ADD_VIEW: `${CALISTA_CEC_ACCOUNT}/add`,
  CEC_ACCOUNT_DETAILS_VIEW: `${CALISTA_CEC_ACCOUNT}/view/:id`,
  CEC_ACCOUNT_EDIT_VIEW: `${CALISTA_CEC_ACCOUNT}/edit/:id`,

  SCO_ADMIN_PRINCIPAL_MAPPING: SCO_ADMIN_PRINCIPAL_MAPPING_PATH,
  SCO_ADMIN_PRINCIPAL_MAPPING_PARTIES_EDIT_VIEW: `${SCO_ADMIN_PRINCIPAL_MAPPING_PATH}/edit/:id`

}

export default pathMap
