import { useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import ComplianceKeys from 'src/constants/locale/key/Compliance'
import pathMap from 'src/paths/PathMap_Compliance'

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.COMPLIANCE,
    Namespace.COMPANY_PREFERENCE
]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

    //* New Permit ----------------------------------------------
    {
        let title = translate(
            Namespace.COMPLIANCE,
            ComplianceKeys.CREATE_NEW_PERMIT_TITLE
        )

        let newPermitTitle = title

        breadcrumbNameMap.set(pathMap.CREATE_NEW_PERMIT, newPermitTitle)

        //Review view
        let reviewViewTitle = translate(
            Namespace.COMPLIANCE,
            ComplianceKeys.CREATE_NEW_PERMIT_TITLE,
            title
        )
        breadcrumbNameMap.set(pathMap.CREATE_NEW_PERMIT_REVIEW, reviewViewTitle)

        let manageTitle = translate(
            Namespace.COMPLIANCE,
            ComplianceKeys.MANAGE_PERMIT_TITLE
        )
        breadcrumbNameMap.set(pathMap.MANAGE_PERMIT, manageTitle)

        let viewPermitTitle = translate(
            Namespace.COMPLIANCE,
            ComplianceKeys.VIEW_PERMIT_TITLE
        )
        breadcrumbNameMap.set(pathMap.MANAGE_VIEW_PERMIT, viewPermitTitle)

        let updatePermitTitle = translate(
            Namespace.COMPLIANCE,
            ComplianceKeys.UPDATE_PERMIT_TITLE
        )
        breadcrumbNameMap.set(pathMap.CREATE_PERMIT_UPDATE_DRAFT, updatePermitTitle)
    }

    {
        // list view
        let declarantMappingTitle = translate(Namespace.COMPLIANCE, ComplianceKeys.DECLARANT_MAPPING)
        breadcrumbNameMap.set(pathMap.DECLARANT_MAINTENANCE_PARTIES_LIST, declarantMappingTitle)
    
        //details view
        let editDeclarantMappingTitle = translate(Namespace.COMPLIANCE, ComplianceKeys.DECLARANT_MAPPING_DETAILS)
        breadcrumbNameMap.set(pathMap.DECLARANT_MAINTENANCE_EDIT_VIEW, editDeclarantMappingTitle)
    
        let declarantMaintenanceTitle = translate(Namespace.COMPLIANCE, ComplianceKeys.DECLARANT_MAINTENANCE_TITLE)
        breadcrumbNameMap.set(pathMap.DECLARANT_MAINTENANCE_LIST, declarantMaintenanceTitle)
    
        let declarantMaintenanceViewPageTitle = translate(Namespace.COMPLIANCE, ComplianceKeys.DECLARANT_MAINTENANCE_DETAILS)
        breadcrumbNameMap.set(pathMap.DECLARANT_MAINTENANCE_DETAILS_VIEW, declarantMaintenanceViewPageTitle)
    }

    return breadcrumbNameMap
}

export default useBreadcrumbNameMap