const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-nmot`

const NMOT_ADMIN_PATH = `${MODULE_BASE}/nmot-admin`
const NMOT_LOGS_PATH = `${MODULE_BASE}/nmot-logs`
const NMOT_H_DEST_PATH = `${MODULE_BASE}/hinterland-destination`
const NMOT_ADD_PATH = `${MODULE_BASE}/nmot-add-record`
const NMOT_UPLOAD_PATH = `${MODULE_BASE}/nmot-upload-record`
const NMOT_EDIT_PATH = `${MODULE_BASE}/nmot-edit-record`
const NMOT_VIEW_PATH = `${MODULE_BASE}/nmot-view-record`
const NMOT_DASHBOARD_PATH = `${MODULE_BASE}/nmot-dashboard-record`

const pathMap = {
  NMOT_ADMIN: NMOT_ADMIN_PATH,
  NMOT_LOGS: NMOT_LOGS_PATH,
  NMOT_H_DEST: NMOT_H_DEST_PATH,
  NMOT_ADD: NMOT_ADD_PATH,
  NMOT_EDIT: NMOT_EDIT_PATH,
  NMOT_VIEW: NMOT_VIEW_PATH,
  NMOT_UPLOAD: NMOT_UPLOAD_PATH,
  NMOT_DASHBOARD: NMOT_DASHBOARD_PATH
}

export default pathMap