import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_Notification'
import NotificationSubscriptionKeys from "../../constants/locale/key/NotificationSubscription";
import NotificationTriggerKeys from "../../constants/locale/key/NotificationTrigger";

const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.NOTIFICATION_SUBSCRIPTION,
    Namespace.NOTIFICATION_TRIGGER
]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation( translationNamespace )

    const breadcrumbNameMap = new Map()

    // --------------- Notification Subscription ---------------
    {
        let title = translate(Namespace.NOTIFICATION_SUBSCRIPTION, NotificationSubscriptionKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NOTIFICATION_SUBSCRIPTION_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NOTIFICATION_SUBSCRIPTION_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NOTIFICATION_SUBSCRIPTION_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NOTIFICATION_SUBSCRIPTION_DETAILS_VIEW, detailsViewTitle)
    }
    // --------------- End Notification Subscription --------------


    // --------------- Notification Trigger --------------
    {
        let title = translate(Namespace.NOTIFICATION_TRIGGER, NotificationTriggerKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NOTIFICATION_TRIGGER_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NOTIFICATION_TRIGGER_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NOTIFICATION_TRIGGER_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NOTIFICATION_TRIGGER_DETAILS_VIEW, detailsViewTitle)
    }
    // --------------- End Notification Trigger --------------

    return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {

    const { translate } = useTranslation(translationNamespace)

    let title = translate(
        Namespace.UI_COMPONENT,
        component, { title: componentTitle }
    )

    return (title);
}

export default useBreadcrumbNameMap
