import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_AccountSetup'
import AccessLevelCompanyKeys from 'src/constants/locale/key/AccessLevelCompany'
import AccessLevelCompanyCustomRuleKeys from 'src/constants/locale/key/AccessLevelCompanyCustomRule'
import AccessLevelMasterKeys from 'src/constants/locale/key/AccessLevelMaster'
import AccessLevelRuleKeys from 'src/constants/locale/key/AccessLevelRule'
import AccessLevelUserKeys from 'src/constants/locale/key/AccessLevelUser'
import CompanyForUserGroupKeys from 'src/constants/locale/key/CompanyForUserGroup'
import SubscriptionKeys from 'src/constants/locale/key/Subscription'
import TcalAsUserGroupMasterKeys from 'src/constants/locale/key/TcalAsUserGroupMaster'
import TcalPartyCarrierCodeKeys from 'src/constants/locale/key/TcalPartyCarrierCode'
import ShipperRequestTypeKeys from 'src/constants/locale/key/ShipperRequestType'
import CompanyForShipperRequestKeys from 'src/constants/locale/key/CompanyForShipperRequest'
import UserForShipperRequestKeys from 'src/constants/locale/key/UserForShipperRequest'
import TtfbTprPartyKeys from 'src/constants/locale/key/TtfbTprParty'
import UserForUserGroupKeys from 'src/constants/locale/key/UserForUserGroup'
import NBSBillingConfigKeys from "../../constants/locale/key/NBSBillingConfig";
import NBSChargeCodeMappingKeys from "../../constants/locale/key/NBSChargeCodeMapping";
import NBSFileKeys from "../../constants/locale/key/NBSFile";

const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.ACCESS_LEVEL_COMPANY,
    Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
    Namespace.ACCESS_LEVEL_MASTER,
    Namespace.ACCESS_LEVEL_RULE,
    Namespace.ACCESS_LEVEL_USER,
    Namespace.COMPANY_FOR_USER_GROUP,
    Namespace.SUBSCRIPTION,
    Namespace.TCAL_AS_USER_GROUP_MASTER,
    Namespace.TCAL_PARTY_CARRIER_CODE,
    Namespace.SHIPPER_REQUEST_TYPE,
    Namespace.COMPANY_FOR_SHIPPER_REQUEST,
    Namespace.USER_FOR_SHIPPER_REQUEST,
    Namespace.TTFB_TPR_PARTY,
    Namespace.USER_FOR_USER_GROUP,
    Namespace.NBS_CHARGE_CODE_MAPPING,
    Namespace.NBS_BILLING_CONFIG,
    Namespace.NBS_FILE
]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

    //* Access Level Company ---------------------------------------------------
    {
        let title = translate(Namespace.ACCESS_LEVEL_COMPANY, AccessLevelCompanyKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_COMPANY_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_COMPANY_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_COMPANY_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_COMPANY_DETAILS_VIEW, detailsViewTitle)
    }

    //* Access Level Company Custom Rule ---------------------------------------
    {
        let title = translate(Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE, AccessLevelCompanyCustomRuleKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_DETAILS_VIEW, detailsViewTitle)
    }

    //* Access Level Master ----------------------------------------------------
    {
        let title = translate(Namespace.ACCESS_LEVEL_MASTER, AccessLevelMasterKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_MASTER_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_MASTER_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_MASTER_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_MASTER_DETAILS_VIEW, detailsViewTitle)
    }

    //* Access Level Rule ------------------------------------------------------
    {
        let title = translate(Namespace.ACCESS_LEVEL_RULE, AccessLevelRuleKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_RULE_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_RULE_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_RULE_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_RULE_DETAILS_VIEW, detailsViewTitle)
    }

    //* Access Level User ------------------------------------------------------
    {
        let title = translate(Namespace.ACCESS_LEVEL_USER, AccessLevelUserKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_USER_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_USER_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_USER_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.ACCESS_LEVEL_USER_DETAILS_VIEW, detailsViewTitle)
    }

    //* Company For User Group -------------------------------------------------
    {
        let title = translate(Namespace.COMPANY_FOR_USER_GROUP, CompanyForUserGroupKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.COMPANY_FOR_USER_GROUP_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.COMPANY_FOR_USER_GROUP_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.COMPANY_FOR_USER_GROUP_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.COMPANY_FOR_USER_GROUP_DETAILS_VIEW, detailsViewTitle)
    }

    //* Subscription -----------------------------------------------------------
    {
        let title = translate(Namespace.SUBSCRIPTION, SubscriptionKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SUBSCRIPTION_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SUBSCRIPTION_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SUBSCRIPTION_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SUBSCRIPTION_DETAILS_VIEW, detailsViewTitle)
    }

    //* Tcal As User Group Master ----------------------------------------------
    {
        let title = translate(Namespace.TCAL_AS_USER_GROUP_MASTER, TcalAsUserGroupMasterKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TCAL_AS_USER_GROUP_MASTER_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TCAL_AS_USER_GROUP_MASTER_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TCAL_AS_USER_GROUP_MASTER_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TCAL_AS_USER_GROUP_MASTER_DETAILS_VIEW, detailsViewTitle)
    }

    //* Tcal Party Carrier Code ------------------------------------------------
    {
        let title = translate(Namespace.TCAL_PARTY_CARRIER_CODE, TcalPartyCarrierCodeKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TCAL_PARTY_CARRIER_CODE_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TCAL_PARTY_CARRIER_CODE_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TCAL_PARTY_CARRIER_CODE_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TCAL_PARTY_CARRIER_CODE_DETAILS_VIEW, detailsViewTitle)
    }

    //* Shipper Request Type ---------------------------------------------------
    {
        let title = translate(Namespace.SHIPPER_REQUEST_TYPE, ShipperRequestTypeKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SHIPPER_REQUEST_TYPE_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SHIPPER_REQUEST_TYPE_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SHIPPER_REQUEST_TYPE_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SHIPPER_REQUEST_TYPE_DETAILS_VIEW, detailsViewTitle)
    }

    //* Company For Shipper Request --------------------------------------------
    {
        let title = translate(Namespace.COMPANY_FOR_SHIPPER_REQUEST, CompanyForShipperRequestKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.COMPANY_FOR_SHIPPER_REQUEST_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.COMPANY_FOR_SHIPPER_REQUEST_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.COMPANY_FOR_SHIPPER_REQUEST_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.COMPANY_FOR_SHIPPER_REQUEST_DETAILS_VIEW, detailsViewTitle)
    }

    //* User For Shipper Request -----------------------------------------------
    {
        let title = translate(Namespace.USER_FOR_SHIPPER_REQUEST, UserForShipperRequestKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.USER_FOR_SHIPPER_REQUEST_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.USER_FOR_SHIPPER_REQUEST_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.USER_FOR_SHIPPER_REQUEST_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.USER_FOR_SHIPPER_REQUEST_DETAILS_VIEW, detailsViewTitle)
    }

    //* TTFB TPR Party ---------------------------------------------------------
    {
        let title = translate(Namespace.TTFB_TPR_PARTY, TtfbTprPartyKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TTFB_TPR_PARTY_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TTFB_TPR_PARTY_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TTFB_TPR_PARTY_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TTFB_TPR_PARTY_DETAILS_VIEW, detailsViewTitle)
    }

    //* User For User Group ----------------------------------------------------
    {
        let title = translate(Namespace.USER_FOR_USER_GROUP, UserForUserGroupKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.USER_FOR_USER_GROUP_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.USER_FOR_USER_GROUP_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.USER_FOR_USER_GROUP_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.USER_FOR_USER_GROUP_DETAILS_VIEW, detailsViewTitle)
    }

    //* NBS Charge Code Mapping ----------------------------------------------------
    {
        let title = translate(Namespace.NBS_CHARGE_CODE_MAPPING, NBSChargeCodeMappingKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_CHARGE_CODE_MAPPING_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_CHARGE_CODE_MAPPING_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_CHARGE_CODE_MAPPING_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_CHARGE_CODE_MAPPING_DETAILS_VIEW, detailsViewTitle)
    }

    //* NBS BILLING CONFIG ----------------------------------------------------
    {
        let title = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_BILLING_CONFIG_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_BILLING_CONFIG_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_BILLING_CONFIG_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_BILLING_CONFIG_DETAILS_VIEW, detailsViewTitle)
    }

    //* NBS FILE ----------------------------------------------------
    {
        let title = translate(Namespace.NBS_FILE, NBSFileKeys.TITLE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_FILE_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_FILE_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_FILE_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_FILE_DETAILS_VIEW, detailsViewTitle)
    }

    //* End --------------------------------------------------------------------
    return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {

    const { translate } = useTranslation(translationNamespace)

    let title = translate(
        Namespace.UI_COMPONENT,
        component, { title: componentTitle }
    )

    return (title);
}

export default useBreadcrumbNameMap
