const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-clogs` // should use cal-clogs

const CLOGS_CONFIG_PATH = `${MODULE_BASE}/clogs-config`
const SEQUENCE_PATH = `${MODULE_BASE}/sequence`
const JSON_SCHEMA_PATH = `${MODULE_BASE}/json-schema`
const CONTAINER_TYPE_PATH = `${MODULE_BASE}/container-type`
const MODULE_PATH = `${MODULE_BASE}/module`
const BILLING_AUDIT_PATH = `${MODULE_BASE}/billing-audit`
const CUSTOM_USER_ACCOUNT_PATH = `${MODULE_BASE}/custom-user-account`

const pathMap = {
  CLOGS_CONFIG: CLOGS_CONFIG_PATH,
  CLOGS_CONFIG_LIST_VIEW: CLOGS_CONFIG_PATH,
  CLOGS_CONFIG_ADD_VIEW: `${CLOGS_CONFIG_PATH}/add`,
  CLOGS_CONFIG_EDIT_VIEW: `${CLOGS_CONFIG_PATH}/edit/:id`,
  CLOGS_CONFIG_DETAILS_VIEW: `${CLOGS_CONFIG_PATH}/view/:id`,
  SEQUENCE: SEQUENCE_PATH,
  SEQUENCE_LIST_VIEW: SEQUENCE_PATH,
  SEQUENCE_ADD_VIEW: `${SEQUENCE_PATH}/add`,
  SEQUENCE_EDIT_VIEW: `${SEQUENCE_PATH}/edit/:id`,
  SEQUENCE_DETAILS_VIEW: `${SEQUENCE_PATH}/view/:id`,
  JSON_SCHEMA: JSON_SCHEMA_PATH,
  JSON_SCHEMA_LIST_VIEW: JSON_SCHEMA_PATH,
  JSON_SCHEMA_ADD_VIEW: `${JSON_SCHEMA_PATH}/add`,
  JSON_SCHEMA_EDIT_VIEW: `${JSON_SCHEMA_PATH}/edit/:id`,
  JSON_SCHEMA_DETAILS_VIEW: `${JSON_SCHEMA_PATH}/view/:id`,
  CONTAINER_TYPE: CONTAINER_TYPE_PATH,
  CONTAINER_TYPE_LIST_VIEW: CONTAINER_TYPE_PATH,
  CONTAINER_TYPE_ADD_VIEW: `${CONTAINER_TYPE_PATH}/add`,
  CONTAINER_TYPE_EDIT_VIEW: `${CONTAINER_TYPE_PATH}/edit/:id`,
  CONTAINER_TYPE_DETAILS_VIEW: `${CONTAINER_TYPE_PATH}/view/:id`,
  CUSTOM_USER_ACCOUNT: CUSTOM_USER_ACCOUNT_PATH,
  CUSTOM_USER_ACCOUNT_LIST_VIEW: CUSTOM_USER_ACCOUNT_PATH,
  CUSTOM_USER_ACCOUNT_ADD_VIEW: `${CUSTOM_USER_ACCOUNT_PATH}/add`,
  CUSTOM_USER_ACCOUNT_EDIT_VIEW: `${CUSTOM_USER_ACCOUNT_PATH}/edit/:id`,
  CUSTOM_USER_ACCOUNT_DETAILS_VIEW: `${CUSTOM_USER_ACCOUNT_PATH}/view/:id`,
  MODULE: MODULE_PATH,
  MODULE_LIST_VIEW: MODULE_PATH,
  MODULE_ADD_VIEW: `${MODULE_PATH}/add`,
  MODULE_EDIT_VIEW: `${MODULE_PATH}/edit/:id`,
  MODULE_DETAILS_VIEW: `${MODULE_PATH}/view/:id`,
  BILLING_AUDIT: BILLING_AUDIT_PATH,
  BILLING_AUDIT_LIST_VIEW: BILLING_AUDIT_PATH
}

export default pathMap
