import { constants, useTranslation } from 'cng-web-lib'

import PartyMappingKeys from 'src/constants/locale/key/PartyMapping'
import BatchBookingRequestKeys from 'src/constants/locale/key/BatchBookingRequest'
import BatchMilestoneKeys from 'src/constants/locale/key/BatchMilestone'
import BatchSftpProcessKeys from 'src/constants/locale/key/BatchSftpProcess'
import CodeMappingKeys from 'src/constants/locale/key/CodeMapping'
import PortMappingKeys from 'src/constants/locale/key/PortMapping'
import PlatformPortMappingKeys from 'src/constants/locale/key/PlatformPortMapping'
import BatchDataConfigKeys from 'src/constants/locale/key/BatchDataConfig'
import TransactionLogKeys from 'src/constants/locale/key/TransactionLog'
import SinotransRegistrationKeys from 'src/constants/locale/key/SinotransRegistration'
import ShippingInstructionRequestKeys from 'src/constants/locale/key/ShippingInstructionRequest'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_EDI'
import NbsMappingKeys from 'src/constants/locale/key/NbsMapping'

const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.PARTY_MAPPING,
    Namespace.BATCH_BOOKING_REQUEST,
    Namespace.BATCH_MILESTONE,
    Namespace.BATCH_SFTP_PROCESS,
    Namespace.CODE_MAPPING,
    Namespace.PORT_MAPPING,
    Namespace.PLATFORM_PORT_MAPPING,
    Namespace.BATCH_DATA_CONFIG,
    Namespace.TRANSACTION_LOG,
    Namespace.SINOTRANS_REGISTRATION,
    Namespace.SHIPPING_INSTRUCTION_REQUEST,
    Namespace.NBS_MAPPING
]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

    //* Party Codes Mapping  --------------------------------------------------------
    {
        let title = translate(Namespace.PARTY_MAPPING, PartyMappingKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.PARTY_MAPPING_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PARTY_MAPPING_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PARTY_MAPPING_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PARTY_MAPPING_DETAILS_VIEW, detailsViewTitle)
    }

    // ---------------- EDI Batch Booking Request ---------------
    {
        let title = translate(Namespace.BATCH_BOOKING_REQUEST, BatchBookingRequestKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.BATCH_BOOKING_REQUEST_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_BOOKING_REQUEST_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_BOOKING_REQUEST_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_BOOKING_REQUEST_DETAILS_VIEW, detailsViewTitle)
    }

    // ---------------- EDI Batch Milestone ---------------
    {
        let title = translate(Namespace.BATCH_MILESTONE, BatchMilestoneKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.BATCH_MILESTONE_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_MILESTONE_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_MILESTONE_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_MILESTONE_DETAILS_VIEW, detailsViewTitle)
    }

    // ---------------- EDI Batch SFTP Process ---------------
    {
        let title = translate(Namespace.BATCH_SFTP_PROCESS, BatchSftpProcessKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.BATCH_SFTP_PROCESS_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_SFTP_PROCESS_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_SFTP_PROCESS_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_SFTP_PROCESS_DETAILS_VIEW, detailsViewTitle)
    }


    // ---------------- EDI Code Mapping ---------------
    {
        let title = translate(Namespace.CODE_MAPPING, CodeMappingKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.CODE_MAPPING_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.CODE_MAPPING_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.CODE_MAPPING_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.CODE_MAPPING_DETAILS_VIEW, detailsViewTitle)
    }

    // ---------------- EDI Port Mapping ---------------
    {
        let title = translate(Namespace.PORT_MAPPING, PortMappingKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.PORT_MAPPING_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PORT_MAPPING_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PORT_MAPPING_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PORT_MAPPING_DETAILS_VIEW, detailsViewTitle)

    }

    // ---------------- EDI Platform Port Mapping ---------------
    {
        let title = translate(Namespace.PLATFORM_PORT_MAPPING, PlatformPortMappingKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.PLATFORM_PORT_MAPPING_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PLATFORM_PORT_MAPPING_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PLATFORM_PORT_MAPPING_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PLATFORM_PORT_MAPPING_DETAILS_VIEW, detailsViewTitle)

    }

    // ---------------- EDI Batch Data Config ---------------
    {
        let title = translate(Namespace.BATCH_DATA_CONFIG, BatchDataConfigKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.BATCH_DATA_CONFIG_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_DATA_CONFIG_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_DATA_CONFIG_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.BATCH_DATA_CONFIG_DETAILS_VIEW, detailsViewTitle)

    }

    // ---------------- EDI Transaction Log ---------------
    {
        let title = translate(Namespace.TRANSACTION_LOG, TransactionLogKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.TRANSACTION_LOG_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TRANSACTION_LOG_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TRANSACTION_LOG_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.TRANSACTION_LOG_DETAILS_VIEW, detailsViewTitle)

    }

    // ---------------- Sinotrans Registration ---------------
    {
        let title = translate(Namespace.SINOTRANS_REGISTRATION, SinotransRegistrationKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.SINOTRANS_REGISTRATION_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SINOTRANS_REGISTRATION_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SINOTRANS_REGISTRATION_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SINOTRANS_REGISTRATION_DETAILS_VIEW, detailsViewTitle)

    }

    // ---------------- EDI Shipping Instruction ---------------
    {
        let title = translate(Namespace.SHIPPING_INSTRUCTION_REQUEST, ShippingInstructionRequestKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.SHIPPING_INSTRUCTION_REQUEST_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SHIPPING_INSTRUCTION_REQUEST_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SHIPPING_INSTRUCTION_REQUEST_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.SHIPPING_INSTRUCTION_REQUEST_DETAILS_VIEW, detailsViewTitle)

    
    }

    // ---------------- EDI NBS Mapping ---------------
    {
        let title = translate(Namespace.NBS_MAPPING, NbsMappingKeys.TITLE)

        // List View
        let listViewTitle = title
        breadcrumbNameMap.set(pathMap.NBS_MAPPING_LIST_VIEW, listViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_MAPPING_ADD_VIEW, addViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_MAPPING_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.NBS_MAPPING_DETAILS_VIEW, detailsViewTitle)

    
    }

    return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {

    const { translate } = useTranslation(translationNamespace)

    let title = translate(
        Namespace.UI_COMPONENT,
        component, { title: componentTitle }
    )

    return (title);
}

export default useBreadcrumbNameMap
