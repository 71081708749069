const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-tmp`

const TMP_ADMIN_CODE_MASTER_PATH = `${MODULE_BASE}/code-master`;
const TMP_ADMIN_COMPANY_PROFILE_PATH = `${MODULE_BASE}/principal`
const TMP_ADMIN_SUPPLIER_PATH = `${MODULE_BASE}/supplier`
const TMP_ADMIN_COMPONENT_PATH = `${MODULE_BASE}/component`
const TMP_MASS_UPLOAD_PATH = `${MODULE_BASE}/mass-upload`
const TMP_SYS_CONFIG_PATH = `${MODULE_BASE}/admin/system-config`
const TMP_COUNTRY_MAP_PATH = `${MODULE_BASE}/admin/country-map`
const TMP_MASTER_UPLOAD_PATH = `${MODULE_BASE}/master-upload`

const pathMap = {

    //Code Master
    CODE_MASTER: TMP_ADMIN_CODE_MASTER_PATH,

    CODE_MASTER_LIST_VIEW: TMP_ADMIN_CODE_MASTER_PATH,
    CODE_MASTER_ADD_VIEW: `${TMP_ADMIN_CODE_MASTER_PATH}/add`,
    CODE_MASTER_EDIT_VIEW: `${TMP_ADMIN_CODE_MASTER_PATH}/edit/:id`,
    CODE_MASTER_DETAILS_VIEW: `${TMP_ADMIN_CODE_MASTER_PATH}/view/:id`,
    CODE_MASTER_DELETE_VIEW: `${TMP_ADMIN_CODE_MASTER_PATH}/delete/:id`,


    //Company Profile
    COMPANY_PROFILE: TMP_ADMIN_COMPANY_PROFILE_PATH,

    COMPANY_PROFILE_LIST_VIEW: TMP_ADMIN_COMPANY_PROFILE_PATH,
    COMPANY_PROFILE_ADD_VIEW: `${TMP_ADMIN_COMPANY_PROFILE_PATH}/add`,
    COMPANY_PROFILE_EDIT_VIEW: `${TMP_ADMIN_COMPANY_PROFILE_PATH}/edit/:id`,
    COMPANY_PROFILE_DETAILS_VIEW: `${TMP_ADMIN_COMPANY_PROFILE_PATH}/view/:id`,
    COMPANY_PROFILE_DELETE_VIEW: `${TMP_ADMIN_COMPANY_PROFILE_PATH}/delete/:id`,


    //Supplier
    SUPPLIER: TMP_ADMIN_SUPPLIER_PATH,

    SUPPLIER_LIST_VIEW: TMP_ADMIN_SUPPLIER_PATH,
    SUPPLIER_ADD_VIEW: `${TMP_ADMIN_SUPPLIER_PATH}/add`,
    SUPPLIER_EDIT_VIEW: `${TMP_ADMIN_SUPPLIER_PATH}/edit/:id`,
    SUPPLIER_DETAILS_VIEW: `${TMP_ADMIN_SUPPLIER_PATH}/view/:id`,
    SUPPLIER_DELETE_VIEW: `${TMP_ADMIN_SUPPLIER_PATH}/delete/:id`,


    //Component
    COMPONENT: TMP_ADMIN_COMPONENT_PATH,

    COMPONENT_LIST_VIEW: TMP_ADMIN_COMPONENT_PATH,
    COMPONENT_ADD_VIEW: `${TMP_ADMIN_COMPONENT_PATH}/add`,
    COMPONENT_EDIT_VIEW: `${TMP_ADMIN_COMPONENT_PATH}/edit/:id`,
    COMPONENT_DETAILS_VIEW: `${TMP_ADMIN_COMPONENT_PATH}/view/:id`,
    COMPONENT_DELETE_VIEW: `${TMP_ADMIN_COMPONENT_PATH}/delete/:id`,


    //Mass Upload
    MASS_UPLOAD: TMP_MASS_UPLOAD_PATH,

    //System Configuration
    SYS_CONFIG: TMP_SYS_CONFIG_PATH,

    SYS_CONFIG_LIST_VIEW: TMP_SYS_CONFIG_PATH,
    SYS_CONFIG_EDIT_VIEW: `${TMP_SYS_CONFIG_PATH}/edit/:id`,
    SYS_CONFIG_DETAILS_VIEW: `${TMP_SYS_CONFIG_PATH}/view/:id`,

    //Country Mapping
    COUNTRY_MAPPING_LIST_VIEW: TMP_COUNTRY_MAP_PATH,

    //Master Upload
    MASTER_UPLOAD: TMP_MASTER_UPLOAD_PATH






}

export default pathMap