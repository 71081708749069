import {
  AppBar,
  Box,
  Hidden,
  Link,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core'

import Logo from './Logo'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.othersTopbar
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}))

function TopBar({ className, ...rest }) {
  const classes = useStyles()

  return (
    <AppBar
      className={classNames(classes.root, className)}
      color='default'
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to='/'>
          <Logo className={classes.logo} />
        </RouterLink>
        <Hidden mdDown>
          <Typography variant='caption' color='textSecondary'>
            Version 0.0.1
          </Typography>
        </Hidden>
        <Box flexGrow={1} />
        <Link
          className={classes.link}
          color='textSecondary'
          component={RouterLink}
          to='/login'
          underline='none'
          classes={{
            root: classNames('ng-button-filled-primary', 'link-button')
          }}
        >
          Login
        </Link>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string
}

export default TopBar
