const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-evgm`

const MY_EVGM_PATH = `${MODULE_BASE}/my-evgm`

const pathMap = {
    MY_EVGM: MY_EVGM_PATH,
    CREATE_NEW_EVGM: `${MODULE_BASE}/new-evgm`,
    REVIEW_EVGM: `${MODULE_BASE}/new-evgm/review-evgm`
}

export default pathMap