import { useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_TmpAdmin'
import TmpCodeMasterKeys from "src/constants/locale/key/TmpCodeMaster";
import TmpCompanyProfileKeys from "src/constants/locale/key/TmpCompanyProfile"
import TmpSupplierKeys from 'src/constants/locale/key/TmpSupplier'
import TmpComponentKeys from 'src/constants/locale/key/TmpComponent'
import TmpMassUploadKeys from 'src/constants/locale/key/TmpMassUpload'
import TmpSysConfigKeys from '../../constants/locale/key/TmpSysConfig';
import TmpCountryMapKeys from '../../constants/locale/key/TmpCountryMap'
import TmpMasterUploadKeys from '../../constants/locale/key/TmpMasterUpload'


const translationNamespace = [Namespace.UI_COMPONENT, Namespace.TMP_CODE_MASTER, Namespace.TMP_COMPANY_PROFILE, Namespace.TMP_SUPPLIER, Namespace.TMP_COMPONENT, Namespace.TMP_MASS_UPLOAD, Namespace.TMP_SYS_CONFIG, Namespace.TMP_COUNTRY_MAP, Namespace.TMP_MASTER_UPLOAD]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

    {
        //------------TMP CODE MASTER---------

        let CodeMasterTableTitle = translate(Namespace.TMP_CODE_MASTER, TmpCodeMasterKeys.TMP_CODE_MASTER)
        breadcrumbNameMap.set(pathMap.CODE_MASTER_LIST_VIEW, CodeMasterTableTitle)

        let CodeMasterAddPageTitle = translate(Namespace.TMP_CODE_MASTER, TmpCodeMasterKeys.TMP_CODE_MASTER_CREATE)
        breadcrumbNameMap.set(pathMap.CODE_MASTER_ADD_VIEW, CodeMasterAddPageTitle)

        let CodeMasterEditPageTitle = translate(Namespace.TMP_CODE_MASTER, TmpCodeMasterKeys.TMP_CODE_MASTER_EDIT)
        breadcrumbNameMap.set(pathMap.CODE_MASTER_EDIT_VIEW, CodeMasterEditPageTitle)

        let CodeMasterViewPageTitle = translate(Namespace.TMP_CODE_MASTER, TmpCodeMasterKeys.TMP_CODE_MASTER_VIEW)
        breadcrumbNameMap.set(pathMap.CODE_MASTER_DETAILS_VIEW, CodeMasterViewPageTitle)

        //------------TMP COMPANY PROFILE---------

        let CompanyProfileTableTitle = translate(Namespace.TMP_COMPANY_PROFILE, TmpCompanyProfileKeys.TMP_COMPANY_PROFILE)
        breadcrumbNameMap.set(pathMap.COMPANY_PROFILE_LIST_VIEW, CompanyProfileTableTitle)

        let CompanyProfileAddPageTitle = translate(Namespace.TMP_COMPANY_PROFILE, TmpCompanyProfileKeys.TMP_COMPANY_PROFILE_CREATE)
        breadcrumbNameMap.set(pathMap.COMPANY_PROFILE_ADD_VIEW, CompanyProfileAddPageTitle)

        let CompanyProfileEditPageTitle = translate(Namespace.TMP_COMPANY_PROFILE, TmpCompanyProfileKeys.TMP_COMPANY_PROFILE_EDIT)
        breadcrumbNameMap.set(pathMap.COMPANY_PROFILE_EDIT_VIEW, CompanyProfileEditPageTitle)

        let CompanyProfileViewPageTitle = translate(Namespace.TMP_COMPANY_PROFILE, TmpCompanyProfileKeys.TMP_COMPANY_PROFILE_VIEW)
        breadcrumbNameMap.set(pathMap.COMPANY_PROFILE_DETAILS_VIEW, CompanyProfileViewPageTitle)

        //------------TMP SUPPLIER---------

        let SupplierTableTitle = translate(Namespace.TMP_SUPPLIER, TmpSupplierKeys.TMP_SUPPLIER)
        breadcrumbNameMap.set(pathMap.SUPPLIER_LIST_VIEW, SupplierTableTitle)

        let SupplierAddPageTitle = translate(Namespace.TMP_SUPPLIER, TmpSupplierKeys.TMP_SUPPLIER_CREATE)
        breadcrumbNameMap.set(pathMap.SUPPLIER_ADD_VIEW, SupplierAddPageTitle)

        let SupplierEditPageTitle = translate(Namespace.TMP_SUPPLIER, TmpSupplierKeys.TMP_SUPPLIER_EDIT)
        breadcrumbNameMap.set(pathMap.SUPPLIER_EDIT_VIEW, SupplierEditPageTitle)

        let SupplierViewPageTitle = translate(Namespace.TMP_SUPPLIER, TmpSupplierKeys.TMP_SUPPLIER_VIEW)
        breadcrumbNameMap.set(pathMap.SUPPLIER_DETAILS_VIEW, SupplierViewPageTitle)

        //------------TMP COMPONENT---------

        let ComponentTableTitle = translate(Namespace.TMP_COMPONENT, TmpComponentKeys.TMP_COMPONENT)
        breadcrumbNameMap.set(pathMap.COMPONENT_LIST_VIEW, ComponentTableTitle)

        let ComponentAddPageTitle = translate(Namespace.TMP_COMPONENT, TmpComponentKeys.TMP_COMPONENT_CREATE)
        breadcrumbNameMap.set(pathMap.COMPONENT_ADD_VIEW, ComponentAddPageTitle)

        let ComponentEditPageTitle = translate(Namespace.TMP_COMPONENT, TmpComponentKeys.TMP_COMPONENT_EDIT)
        breadcrumbNameMap.set(pathMap.COMPONENT_EDIT_VIEW, ComponentEditPageTitle)

        let ComponentViewPageTitle = translate(Namespace.TMP_COMPONENT, TmpComponentKeys.TMP_COMPONENT_VIEW)
        breadcrumbNameMap.set(pathMap.COMPONENT_DETAILS_VIEW, ComponentViewPageTitle)

        //------------TMP COMPONENT---------

        let MassUploadTitle = translate(Namespace.TMP_MASS_UPLOAD, TmpMassUploadKeys.TMP_MASS_UPLOAD)
        breadcrumbNameMap.set(pathMap.MASS_UPLOAD, MassUploadTitle)

        //------------TMP SYS CONFIG------------
        let SysConfigTableTitle = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_TITLE)
        breadcrumbNameMap.set(pathMap.SYS_CONFIG_LIST_VIEW, SysConfigTableTitle)

        let SysConfigTableViewTitle = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_VIEW)
        breadcrumbNameMap.set(pathMap.SYS_CONFIG_DETAILS_VIEW, SysConfigTableViewTitle)

        let SysConfigTableEditTitle = translate(Namespace.TMP_SYS_CONFIG, TmpSysConfigKeys.TMP_SYS_CONFIG_EDIT)
        breadcrumbNameMap.set(pathMap.SYS_CONFIG_EDIT_VIEW, SysConfigTableEditTitle)


        //------------TMP Country Mapping------------
        let CountryMapTableTitle = translate(Namespace.TMP_COUNTRY_MAP, TmpCountryMapKeys.TMP_COUNTRY_MAP)
        breadcrumbNameMap.set(pathMap.COUNTRY_MAPPING_LIST_VIEW, CountryMapTableTitle)


         //------------TMP COMPONENT---------
         let MasterUploadTitle = translate(Namespace.TMP_MASTER_UPLOAD, TmpMasterUploadKeys.TMP_MASTER_UPLOAD)
         breadcrumbNameMap.set(pathMap.MASTER_UPLOAD, MasterUploadTitle)


    }

    return breadcrumbNameMap
}

export default useBreadcrumbNameMap
