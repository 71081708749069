import { constants, useTranslation } from 'cng-web-lib'

import CarrierEnrollKeys from 'src/constants/locale/key/CarrierEnroll'
import Namespace from 'src/constants/locale/Namespace'
import PollingKeys from 'src/constants/locale/key/Polling'
import SearchScheduleKeys from 'src/constants/locale/key/SearchSchedule'
import TcalVsManageCarriersKeys from 'src/constants/locale/key/TcalVsManageCarriers'
import TcalVsManageScheduleKeys from 'src/constants/locale/key/TcalVsManageSchedule'
import TcalVsVesselKeys from 'src/constants/locale/key/TcalVsVessel'
import TcalVsVoyageKeys from 'src/constants/locale/key/TcalVsVoyage'
import VsMigrationSyncLogKeys from 'src/constants/locale/key/VsMigrationSyncLog'
import pathMap from 'src/paths/PathMap_VesselSchedule'

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const translationNamespace = [
  Namespace.UI_COMPONENT,
  Namespace.SEARCH_SCHEDULE,
  Namespace.TCAL_VS_MANAGE_CARRIERS,
  Namespace.CARRIER_ENROLL,
  Namespace.POLLING,
  Namespace.TCAL_VS_VESSEL,
  Namespace.TCAL_VS_VOYAGE,
  Namespace.TCAL_VS_MANAGE_SCHEDULE
]

function useBreadcrumbNameMap() {
  const { translate } = useTranslation(translationNamespace)

  const breadcrumbNameMap = new Map()

  //* Search Schedule --------------------------------------------------------
  {
    let title = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.TITLE)

    // List View
    let listViewTitle = title
    breadcrumbNameMap.set(pathMap.SEARCH_SCHEDULE_VIEW, listViewTitle)
  }

  //* Carrier Search Schedule --------------------------------------------------------
  {
    let title = translate(Namespace.SEARCH_SCHEDULE, SearchScheduleKeys.TITLE)
    breadcrumbNameMap.set(pathMap.CARRIER_SEARCH_SCHEDULE, title)
  }

  //* Manage Preferred Carriers ----------------------------------------------
  {
    let title = translate(
      Namespace.TCAL_VS_MANAGE_CARRIERS,
      TcalVsManageCarriersKeys.PREFERRED_CARRIERS_TITLE
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_MANAGE_PREF_CAR_LIST_VIEW, title)
  }

  //* Manage Preferred Port Pairs --------------------------------------------
  {
    let title = translate(
      Namespace.TCAL_VS_MANAGE_CARRIERS,
      TcalVsManageCarriersKeys.PREFERRED_PORT_PAIRS_TITLE
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_MANAGE_PREF_PP_LIST_VIEW, title)
  }

  //* Manage Blacklisted Carriers --------------------------------------------
  {
    let title = translate(
      Namespace.TCAL_VS_MANAGE_CARRIERS,
      TcalVsManageCarriersKeys.BLACKLISTED_CARRIERS_TITLE
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_MANAGE_BLA_CAR_LIST_VIEW, title)
  }

  //* Manage Vessels              --------------------------------------------
  {
    let title = translate(
      Namespace.TCAL_VS_VESSEL,
      TcalVsVesselKeys.MANAGE_VESSELS_TITLE
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_MANAGE_VESSELS_VIEW, title)
  }

  {
    let title = translate(
      Namespace.TCAL_VS_VESSEL,
      TcalVsVesselKeys.ADD_VESSELS_TITLE
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_MANAGE_VESSELS_ADD, title)
  }

  {
    let title = translate(
      Namespace.TCAL_VS_VESSEL,
      TcalVsVesselKeys.VIEW_AMEND_VESSELS_TITLE
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_MANAGE_VESSELS_VAMEND, title)
  }

  //* Manage Schedule --------------------------------------------
  {
    let title = translate(
      Namespace.TCAL_VS_MANAGE_SCHEDULE,
      TcalVsManageScheduleKeys.MANAGE_SCHEDULE_TITLE
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_MANAGE_SCHEDULE_LIST, title)
  }

  {
    let title = translate(
      Namespace.TCAL_VS_MANAGE_SCHEDULE,
      TcalVsManageScheduleKeys.ADD_SCHEDULE_TITLE
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD, title)
  }

  {
    let title = translate(
      Namespace.TCAL_VS_MANAGE_SCHEDULE,
      TcalVsManageScheduleKeys.VIEW_SCHEDULE_TITLE
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_MANAGE_SCHEDULE_VIEW, title)
  }

  {
    let title = translate(
      Namespace.TCAL_VS_MANAGE_SCHEDULE,
      TcalVsManageScheduleKeys.AMEND_SCHEDULE_TITLE
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_MANAGE_SCHEDULE_AMEND, title)
  }

  {
    let title = translate(
      Namespace.TCAL_VS_MANAGE_SCHEDULE,
      TcalVsManageScheduleKeys.VIEW_UPLOAD_SCHEDULE_TITLE
    )
    breadcrumbNameMap.set(
      pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_FILE_UPLOAD,
      title
    )
  }

  {
    let title = translate(
      Namespace.TCAL_VS_MANAGE_SCHEDULE,
      TcalVsManageScheduleKeys.ADD_SCHEDULE_TITLE
    )
    breadcrumbNameMap.set(
      pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_FILE_UPLOAD,
      title
    )
  }

  {
    let title = translate(
      Namespace.TCAL_VS_MANAGE_SCHEDULE,
      TcalVsManageScheduleKeys.ADD_SCHEDULE_TITLE
    )
    breadcrumbNameMap.set(
      pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_FILE_UPLOAD_FINISH,
      title
    )
  }

  //* Carrier Enrolment ------------------------------------------------------
  {
    let title = translate(Namespace.CARRIER_ENROLL, CarrierEnrollKeys.TITLE)

    // List View
    let listViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.CARRIER_ENROLL_LIST_VIEW, listViewTitle)

    // Add View
    let addViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.CARRIER_ENROLL_ADD_VIEW, addViewTitle)

    // Edit View
    let editViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.CARRIER_ENROLL_EDIT_VIEW, editViewTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.CARRIER_ENROLL_DETAILS_VIEW, detailsViewTitle)
  }

  //* Polling ----------------------------------------------------------------
  {
    let title = translate(Namespace.POLLING, PollingKeys.TITLE)

    // List View
    let listViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.POLLING_LIST_VIEW, listViewTitle)

    // Add View
    let addViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.POLLING_ADD_VIEW, addViewTitle)

    // Edit View
    let editViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.POLLING_EDIT_VIEW, editViewTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.POLLING_DETAILS_VIEW, detailsViewTitle)
  }

  //* Tcal VS Vessel ---------------------------------------------------------
  {
    let title = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.TITLE)

    // List View
    let listViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_VESSEL_LIST_VIEW, listViewTitle)

    // Add View
    let addViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_VESSEL_ADD_VIEW, addViewTitle)

    // Edit View
    let editViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_VESSEL_EDIT_VIEW, editViewTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_VESSEL_DETAILS_VIEW, detailsViewTitle)
  }

  //* Tcal VS Voyage ---------------------------------------------------------
  {
    let title = translate(Namespace.TCAL_VS_VOYAGE, TcalVsVoyageKeys.TITLE)

    // List View
    let listViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_VOYAGE_LIST_VIEW, listViewTitle)

    // Add View
    let addViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_VOYAGE_ADD_VIEW, addViewTitle)

    // Edit View
    let editViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_VOYAGE_EDIT_VIEW, editViewTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.TCAL_VS_VOYAGE_DETAILS_VIEW, detailsViewTitle)
  }

  //* VS Sync Log ---------------------------------------------------------
  {
    let title = translate(Namespace.VS_MIGRATION_SYNC_LOG, VsMigrationSyncLogKeys.TITLE)

    // List View
    let listViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.VS_MIGRATION_SYNC_LOG_LIST_VIEW, listViewTitle)

    // Add View
    let addViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.VS_MIGRATION_SYNC_LOG_ADD_VIEW, addViewTitle)

    // Edit View
    let editViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.VS_MIGRATION_SYNC_LOG_EDIT_VIEW, editViewTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(
      UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.VS_MIGRATION_SYNC_LOG_DETAILS_VIEW, detailsViewTitle)
  }

  //* End --------------------------------------------------------------------
  return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {
  const { translate } = useTranslation(translationNamespace)

  let title = translate(Namespace.UI_COMPONENT, component, {
    title: componentTitle
  })

  return title
}

export default useBreadcrumbNameMap
