const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-cfc`

const ECO_CALCULATOR_PATH = `${MODULE_BASE}/eco-calculator`
const METHODOLOGY_PATH = `${MODULE_BASE}/methodology`

const pathMap = {
  ECO_CALCULATOR: ECO_CALCULATOR_PATH,
  ECO_CALCULATOR_LIST_VIEW: ECO_CALCULATOR_PATH,
  ECO_CALCULATOR_RESULT_VIEW: `${ECO_CALCULATOR_PATH}/result`,
  ECO_CALCULATOR_ADVAN_VIEW: `${ECO_CALCULATOR_PATH}/advan`,
  ECO_CALCULATOR_DETAILS_VIEW: `${ECO_CALCULATOR_PATH}/view/:id`,
  AUDIT_LOG: `${MODULE_BASE}/log-system`,
  ECO_CALCULATOR_EDIT_VIEW: `${ECO_CALCULATOR_PATH}/edit/:id`,

  METHODOLOGY_PATH: METHODOLOGY_PATH,
  METHODOLOGY_PATH_LIST_VIEW: METHODOLOGY_PATH,
  METHODOLOGY_PATH_ADD_VIEW: `${METHODOLOGY_PATH}/add`,
  METHODOLOGY_PATH_EDIT_VIEW: `${METHODOLOGY_PATH}/edit/:id`,
  METHODOLOGY_PATH_DETAILS_VIEW: `${METHODOLOGY_PATH}/view/:id`
}

export default pathMap
