    import { useTranslation } from 'cng-web-lib'
    import Namespace from 'src/constants/locale/Namespace'
    import pathMap from 'src/paths/PathMap_EcommerceUpload'
    import EcommerceUploadKeys from 'src/constants/locale/key/EcommerceUpload'

    const translationNamespace = [
        Namespace.ECOMMERCE_UPLOAD
    ]

    function useBreadcrumbNameMap() {
        const { translate } = useTranslation(translationNamespace)
        const breadcrumbNameMap = new Map()

        let uploadTitle = translate(Namespace.ECOMMERCE_UPLOAD, EcommerceUploadKeys.UPLOAD_FILE_TITLE)
        breadcrumbNameMap.set(pathMap.UPLOAD_PAGE, uploadTitle)
        
        return breadcrumbNameMap
    }


    export default useBreadcrumbNameMap
