import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'

function ListLinkItem({ primaryText, onClick }) {
  return (
    <ListItem button onClick={onClick}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faChevronCircleRight} />
      </ListItemIcon>
      <ListItemText primary={primaryText} />
    </ListItem>
  )
}

export default ListLinkItem
