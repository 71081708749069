const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-common/notification`

const NOTIFICATION_TRIGGER_PATH = `${MODULE_BASE}/notification-trigger`

const pathMap = {
    NOTIFICATION_TRIGGER: NOTIFICATION_TRIGGER_PATH,
    NOTIFICATION_TRIGGER_LIST_VIEW: NOTIFICATION_TRIGGER_PATH,
    NOTIFICATION_TRIGGER_ADD_VIEW: `${NOTIFICATION_TRIGGER_PATH}/add`,
    NOTIFICATION_TRIGGER_EDIT_VIEW: `${NOTIFICATION_TRIGGER_PATH}/edit/:id`,
    NOTIFICATION_TRIGGER_DETAILS_VIEW: `${NOTIFICATION_TRIGGER_PATH}/view/:id`
}

export default pathMap