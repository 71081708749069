import { constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_SCO_Job'
import SCOJob from 'src/constants/locale/key/SCOJob'

const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.SCO_JOB
]

function useBreadcrumbNameMap() {
    const { translate } = useTranslation(translationNamespace)
    const breadcrumbNameMap = new Map()

    let title = translate(Namespace.SCO_JOB, SCOJob.ADD_JOB_CONFIGURATION_MODULE_TITLE)
    breadcrumbNameMap.set(pathMap.SCO_JOB_CONFIG_ADD_VIEW, title)


    /** 
     *  Job Configuration Table List Title
     * 
     **/

    let tableTitle = translate(Namespace.SCO_JOB, SCOJob.JOB_CONFIGURATION_TABLE_NAME)
    breadcrumbNameMap.set(pathMap.SCO_JOB_CONFIG_LIST_VIEW, tableTitle)


    /** 
     *  Job Configuration Details View Title
     * 
     **/
    let viewTitle = translate(Namespace.SCO_JOB, SCOJob.VIEW_JOB_CONFIGURATION)
    breadcrumbNameMap.set(pathMap.SCO_JOB_CONFIG_DETAILS_VIEW, viewTitle)


    /** 
     *  Job Configuration Details Edit Title
     * 
     **/
    let editTitle = translate(Namespace.SCO_JOB, SCOJob.EDIT_JOB_CONFIGURATION)
    breadcrumbNameMap.set(pathMap.SCO_JOB_CONFIG_EDIT_VIEW, editTitle)


    /** 
     *  My Job Table
     * 
     **/
    let myJobTableTitle = translate(Namespace.SCO_JOB, SCOJob.MY_JOB)
    breadcrumbNameMap.set(pathMap.SCO_JOB_LIST_VIEW, myJobTableTitle)

    /** 
     *  Create New Job
     * 
     **/
    let createNewJob = translate(Namespace.SCO_JOB, SCOJob.CREATE_NEW_JOB)
    breadcrumbNameMap.set(pathMap.SCO_JOB_ADD_VIEW, createNewJob)


    let editJobDetails = translate(Namespace.SCO_JOB, SCOJob.EDIT_JOB_DETAILS)
    breadcrumbNameMap.set(pathMap.SCO_JOB_DETAILS_EDIT_VIEW, editJobDetails)


    /**
     * Document Conversion
     */

    let docConversion = translate(Namespace.SCO_JOB, SCOJob.DOCUMENT_CONVERSION)
    breadcrumbNameMap.set(pathMap.SCO_JOB_DOC_CONVERSION, docConversion)



    let addDocConversion = translate(Namespace.SCO_JOB, SCOJob.CREATE_DOCUMENT_CONVERSION)
    breadcrumbNameMap.set(pathMap.SCO_JOB_DOC_CONVERSION_ADD, addDocConversion)


    let viewDocConversion = translate(Namespace.SCO_JOB, SCOJob.DOCUMENT_CONVERSION_DETAILS)
    breadcrumbNameMap.set(pathMap.SCO_JOB_DOC_CONVERSION_VIEW, viewDocConversion)

    let editDocConversion = translate(Namespace.SCO_JOB, SCOJob.DOCUMENT_CONVERSION_DETAILS)
    breadcrumbNameMap.set(pathMap.SCO_JOB_DOC_CONVERSION_EDIT, editDocConversion)


    /**
     * Job Request
     */

    let jobRequest = translate(Namespace.SCO_JOB, SCOJob.JOB_REQUEST)
    breadcrumbNameMap.set(pathMap.SCO_JOB_REQUEST, jobRequest)


    return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {
    const { translate } = useTranslation(translationNamespace)

    let title = translate(Namespace.UI_COMPONENT, component, {
        title: componentTitle
    })

    return title
}

export default useBreadcrumbNameMap
