import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import RiceStockKeys from 'src/constants/locale/key/RiceStock'
import pathMap from 'src/paths/PathMap_RiceStock'

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const translationNamespace = [Namespace.UI_COMPONENT, Namespace.RICE_STOCK]

function useBreadcrumbNameMap() {
  const { translate } = useTranslation(translationNamespace)

  const breadcrumbNameMap = new Map()
  {
    let uploadImporterTitle = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.UPLOAD_IMPORTER.TITLE
    )
    let uploadPOTitle = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.UPLOAD_PO.TITLE
    )
    let stockDetailTitle = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.UPLOAD_STOCK_DETAIL.TITLE
    )
    let uploadConfig = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.UPLOAD_RICESTOCKPILE_CONFIG.TITLE
    )

    breadcrumbNameMap.set(
      pathMap.UPLOAD_IMPORTER_PROFILE_VIEW,
      uploadImporterTitle
    )
    breadcrumbNameMap.set(pathMap.UPLOAD_PO_DETAIL_VIEW, uploadPOTitle)
    breadcrumbNameMap.set(pathMap.UPLOAD_STOCK_DETAIL_VIEW, stockDetailTitle)
    breadcrumbNameMap.set(pathMap.UPLOAD_RICE_STOCKPILE_CONFIG_VIEW, uploadConfig)

    // PowerBI Dasboard Breadcrumb
    let dashboardImporterProfileTitle = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.POWER_BI_DASHBOARD.IMPORTER_PROFILE.TITLE
    )
    let dashboardImportStatusByImporterTitle = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.POWER_BI_DASHBOARD.IMPORT_STATUS_BY_IMPORTER.TITLE
    )
    let dashboardImportStatusTitle = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.POWER_BI_DASHBOARD.IMPORT_STATUS.TITLE
    )
    let dashboardStockpileTitle = translate(
      Namespace.RICE_STOCK,
      RiceStockKeys.POWER_BI_DASHBOARD.STOCKPILE.TITLE
    )
    breadcrumbNameMap.set(
      pathMap.POWERBI_DASHBOARD_IMPORTER_PROFILE_VIEW,
      dashboardImporterProfileTitle
    )
    breadcrumbNameMap.set(
      pathMap.POWERBI_DASHBOARD_IMPORT_STATUS_BY_IMPORTER_VIEW,
      dashboardImportStatusByImporterTitle
    )
    breadcrumbNameMap.set(
      pathMap.POWERBI_DASHBOARD_IMPORT_STATUS_VIEW,
      dashboardImportStatusTitle
    )
    breadcrumbNameMap.set(
      pathMap.POWERBI_DASHBOARD_STOCKPILE_VIEW,
      dashboardStockpileTitle
    )

    breadcrumbNameMap.set(pathMap.IMPORTERS_LIST_VIEW, 'Dashboard Importer Table')
    breadcrumbNameMap.set(pathMap.IMPORTERS_ADD_VIEW, 'Add Importer')
    breadcrumbNameMap.set(pathMap.IMPORTERS_EDIT_VIEW, 'Edit Importer')
    breadcrumbNameMap.set(pathMap.IMPORTERS_DETAILS_VIEW, 'Importer Details')
  }

  return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {
  const { translate } = useTranslation(translationNamespace)

  let title = translate(Namespace.UI_COMPONENT, component, {
    title: componentTitle
  })

  return title
}

export default useBreadcrumbNameMap
