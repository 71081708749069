import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_Ping'
import PingKeys from 'src/constants/locale/key/Ping';
import { useParams } from 'react-router';

const {
    locale: {
        key: { UiComponentKeys }
    }
} = constants

const translationNamespace = [
    Namespace.UI_COMPONENT,
    Namespace.PING
]

function useBreadcrumbNameMap() {

    const { translate } = useTranslation(translationNamespace)

    const breadcrumbNameMap = new Map()

    //* ------------------------------------------------------------------------
    //* Ping Admin
    //* ------------------------------------------------------------------------
    //* Ping System Config -----------------------------------------------------
    {
        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SYS_CONFIG)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_SYS_CONFIG_LIST_VIEW, listViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_SYS_CONFIG_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_SYS_CONFIG_DETAILS_VIEW, detailsViewTitle)
    }

    //* Ping Party Config ------------------------------------------------------
    {
        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_PARTY_CONFIG)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_PARTY_CONFIG_LIST_VIEW, listViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_PARTY_CONFIG_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_PARTY_CONFIG_DETAILS_VIEW, detailsViewTitle)
    }

    //* Ping Event Source ------------------------------------------------------
    {
        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_EVENT_SOURCE)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_SOURCE_LIST_VIEW, listViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_SOURCE_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_SOURCE_DETAILS_VIEW, detailsViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_SOURCE_ADD_VIEW, addViewTitle)
    }

    //* Ping Event Code --------------------------------------------------------
    {
        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_EVENT_CODE_MASTER)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_LIST_VIEW, listViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_DETAILS_VIEW, detailsViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_ADD_VIEW, addViewTitle)
    }

    //* Ping Event Code Mapping ------------------------------------------------
    {
        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_EVENT_CODE_MAPPING)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_MAPPING_LIST_VIEW, listViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_MAPPING_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_MAPPING_DETAILS_VIEW, detailsViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_MAPPING_ADD_VIEW, addViewTitle)
    }

    //* Ping Event Code Link ---------------------------------------------------
    {
        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_EVENT_CODE_LINK)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_LINK_LIST_VIEW, listViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_LINK_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_LINK_DETAILS_VIEW, detailsViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENT_CODE_LINK_ADD_VIEW, addViewTitle)
    }

    //* Ping Shipment Event ----------------------------------------------------
    {
        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SHIPMENT_EVENT)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_SHIPMENT_EVENT_LIST_VIEW, listViewTitle)

        // Edit View
        let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_SHIPMENT_EVENT_EDIT_VIEW, editViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_SHIPMENT_EVENT_DETAILS_VIEW, detailsViewTitle)

        // Add View
        let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_SHIPMENT_EVENT_ADD_VIEW, addViewTitle)
    }

    //* Ping Search Log --------------------------------------------------------
    {
        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH_LOG)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_SEARCH_LOG_LIST_VIEW, listViewTitle)

        // Details View
        let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_SEARCH_LOG_DETAILS_VIEW, detailsViewTitle)
    }

    //* Ping Vessel Voyage Info ------------------------------------------------
    {
        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_VESSEL_VOYAGE_INFO)

        // List View
        let listViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE, title)
        breadcrumbNameMap.set(pathMap.PING_ADMIN_VESSEL_VOYAGE_INFO_LIST_VIEW, listViewTitle)
    }

    //* Ping Event Hub API Logs ------------------------------------------------
    {
        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_EVENTHUB_API_LOGS)

        // Main Page
        breadcrumbNameMap.set(pathMap.PING_ADMIN_EVENTHUB_LOG, title)
    }

    //* Ping PortNet API Logs ------------------------------------------------
    {
        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_PORTNET_API_LOGS)

        // Main Page
        breadcrumbNameMap.set(pathMap.PING_ADMIN_PORTNET_LOG, title)
    }

    //* Ping Search ------------------------------------------------------------
    {
        const { bookingRef, blNum, containerNum, vesselName } = useParams()

        let pageTitle = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH)

        // Search Page
        breadcrumbNameMap.set(pathMap.PING_SEARCH, pageTitle)

        // Favourite list
        let favouriteListTitle = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH_FAVOURITE_LIST)
        breadcrumbNameMap.set(pathMap.PING_SEARCH_FAVOURITE_LIST, favouriteListTitle)

        // History list
        let historyListTitle = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH_HISTORY_LIST)
        breadcrumbNameMap.set(pathMap.PING_SEARCH_HISTORY_LIST, historyListTitle)

        // Booking list
        let bookingListTitle = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH_BOOKING_LIST)
        breadcrumbNameMap.set(pathMap.PING_SEARCH_BOOKING_LIST, bookingListTitle)

        // Booking Search
        let bookingSearchTitle = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH)
        bookingSearchTitle = bookingSearchTitle + " - " + bookingRef
        breadcrumbNameMap.set(pathMap.PING_SEARCH_BOOKING_REF, bookingSearchTitle)

        // BL Search
        let blNumSearchTitle = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH)
        blNumSearchTitle = blNumSearchTitle + " - " + blNum
        breadcrumbNameMap.set(pathMap.PING_SEARCH_BL_NUM, blNumSearchTitle)

        // Container Search
        let containerSearchTitle = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH)
        containerSearchTitle = containerSearchTitle + " - " + containerNum
        breadcrumbNameMap.set(pathMap.PING_SEARCH_CONTAINER_NUM, containerSearchTitle)

        // Vessel Search
        let vesselSearchTitle = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH)
        vesselSearchTitle = vesselSearchTitle + " - " + vesselName
        breadcrumbNameMap.set(pathMap.PING_SEARCH_VESSEL_NAME, vesselSearchTitle)
    }

    //* ------------------------------------------------------------------------
    //* Ping - My Milestones
    //* ------------------------------------------------------------------------
    {
        let PingMyMilestoneKeys = PingKeys.PING_MY_MILESTONES
        let PageTitleKeys = PingMyMilestoneKeys.PAGE_TITLE

        let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_MY_MILESTONES)

        // List Page
        breadcrumbNameMap.set(pathMap.PING_MY_MILESTONES, title)

        // Add Page & Add Success Page
        let addPageTitle = translate(Namespace.PING, PageTitleKeys.CREATE_NEW_MILESTONE)

        breadcrumbNameMap.set(pathMap.PING_MY_MILESTONES_ADD_VIEW, addPageTitle)
        breadcrumbNameMap.set(pathMap.PING_MY_MILESTONES_ADD_SUCCESS_VIEW, addPageTitle)

        // Edit Page & Edit Success Page
        let editPageTitle = translate(Namespace.PING, PageTitleKeys.EDIT_MILESTONE)

        breadcrumbNameMap.set(pathMap.PING_MY_MILESTONES_EDIT_VIEW, editPageTitle)
        breadcrumbNameMap.set(pathMap.PING_MY_MILESTONES_EDIT_SUCCESS_VIEW, editPageTitle)

        // View Page
        let viewPageTitle = translate(Namespace.PING, PageTitleKeys.VIEW_MILESTONE)
        breadcrumbNameMap.set(pathMap.PING_MY_MILESTONES_DETAILS_VIEW, viewPageTitle)

        // Upload List Page
        let uploadListPageTitle = translate(Namespace.PING, PageTitleKeys.UPLOAD_MILESTONES)
        breadcrumbNameMap.set(pathMap.PING_MY_MILESTONES_UPLOAD_LIST_VIEW, uploadListPageTitle)
    }

    //* End --------------------------------------------------------------------
    return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {

    const { translate } = useTranslation(translationNamespace)

    let title = translate(
        Namespace.UI_COMPONENT,
        component, { title: componentTitle }
    )

    return (title);
}

export default useBreadcrumbNameMap
