const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-sco`

const CALISTA_SCO_INVENTORY_COMPANY = `${MODULE_BASE}/company/inventory`
const CALISTA_SCO_INVENTORY_ADMIN = `${MODULE_BASE}/admin/inventory`


const CALISTA_SCO_INVENTORY_COMPANY_SUPPLIER = `${CALISTA_SCO_INVENTORY_COMPANY}/supplier`
const CALISTA_SCO_INVENTORY_ADMIN_SUPPLIER = `${CALISTA_SCO_INVENTORY_ADMIN}/supplier`

const CALISTA_SCO_INVENTORY_COMPANY_BUYER = `${CALISTA_SCO_INVENTORY_COMPANY}/buyer`
const CALISTA_SCO_INVENTORY_ADMIN_BUYER = `${CALISTA_SCO_INVENTORY_ADMIN}/buyer`

const CALISTA_SCO_INVENTORY_COMPANY_CATEGORY = `${CALISTA_SCO_INVENTORY_COMPANY}/category`
const CALISTA_SCO_INVENTORY_ADMIN_CATEGORY = `${CALISTA_SCO_INVENTORY_ADMIN}/category`

const CALISTA_SCO_INVENTORY_COMPANY_WAREHOUSE = `${CALISTA_SCO_INVENTORY_COMPANY}/warehouse`
const CALISTA_SCO_INVENTORY_ADMIN_WAREHOUSE = `${CALISTA_SCO_INVENTORY_ADMIN}/warehouse`
const CALISTA_SCO_INVENTORY_COMPANY_NONSTORAGE = `${CALISTA_SCO_INVENTORY_COMPANY}/location`
const CALISTA_SCO_INVENTORY_ADMIN_NONSTORAGE = `${CALISTA_SCO_INVENTORY_ADMIN}/location`

const CALISTA_SCO_INVENTORY_COMPANY_SKU = `${CALISTA_SCO_INVENTORY_COMPANY}/sku`
const CALISTA_SCO_INVENTORY_ADMIN_SKU = `${CALISTA_SCO_INVENTORY_ADMIN}/sku`

const CALISTA_SCO_INVENTORY_COMPANY_DEF_LOC_CONFIG = `${CALISTA_SCO_INVENTORY_COMPANY}/defaultLocConfig`

const CALISTA_SCO_INVENTORY_COMPANY_STOCK = `${CALISTA_SCO_INVENTORY_COMPANY}/stock`

const CALISTA_SCO_INVENTORY_PRINCIPAL_MAPPING = `${CALISTA_SCO_INVENTORY_ADMIN}/principalmapping`
const CALISTA_SCO_MY_INVENTORY_PRINCIPAL = `${CALISTA_SCO_INVENTORY_COMPANY}/stockprincipal`

const pathMap = {
    /*Start of SUPPLIER PATHS*/
    SCO_INV_SUPPLIER_LIST: `${CALISTA_SCO_INVENTORY_COMPANY_SUPPLIER}`,
    SCO_INV_SUPPLIER_CREATE_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_SUPPLIER}/create`,
    SCO_INV_SUPPLIER_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_SUPPLIER}/view/:id`,
    SCO_INV_SUPPLIER_EDIT_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_SUPPLIER}/edit/:id`,
    SCO_INV_ADMIN_SUPPLIER_LIST: `${CALISTA_SCO_INVENTORY_ADMIN_SUPPLIER}`,
    SCO_INV_ADMIN_SUPPLIER_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_ADMIN_SUPPLIER}/view/:id`,
    /*End of SUPPLIER PATHS*/

    /*Start of BUYER PATHS*/
    SCO_INV_BUYER_LIST: `${CALISTA_SCO_INVENTORY_COMPANY_BUYER}`,
    SCO_INV_BUYER_CREATE_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_BUYER}/create`,
    SCO_INV_BUYER_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_BUYER}/view/:id`,
    SCO_INV_BUYER_EDIT_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_BUYER}/edit/:id`,
    SCO_INV_ADMIN_BUYER_LIST: `${CALISTA_SCO_INVENTORY_ADMIN_BUYER}`,
    SCO_INV_ADMIN_BUYER_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_ADMIN_BUYER}/view/:id`,
    /*End of BUYER PATHS*/

    /*Start of CATEGORY PATHS*/
    SCO_INV_CATEGORY_LIST: `${CALISTA_SCO_INVENTORY_COMPANY_CATEGORY}`,
    SCO_INV_CATEGORY_CREATE_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_CATEGORY}/create`,
    SCO_INV_CATEGORY_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_CATEGORY}/view/:id`,
    SCO_INV_CATEGORY_EDIT_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_CATEGORY}/edit/:id`,
    SCO_INV_ADMIN_CATEGORY_LIST: `${CALISTA_SCO_INVENTORY_ADMIN_CATEGORY}`,
    SCO_INV_ADMIN_CATEGORY_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_ADMIN_CATEGORY}/view/:id`,
    /*End of CATEGORY PATHS*/

    /* Start of WAREHOUSE PATHS */
    SCO_INV_WAREHOUSE_LIST: `${CALISTA_SCO_INVENTORY_COMPANY_WAREHOUSE}`,
    SCO_INV_WAREHOUSE_CREATE_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_WAREHOUSE}/create`,
    SCO_INV_WAREHOUSE_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_WAREHOUSE}/view/:id`,
    SCO_INV_WAREHOUSE_EDIT_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_WAREHOUSE}/edit/:id`,
    /* End of WAREHOUSE PATHS */

    /* Start of Warehouse Admin Paths */
    SCO_INV_ADMIN_WAREHOUSE_LIST: `${CALISTA_SCO_INVENTORY_ADMIN_WAREHOUSE}`,
    SCO_INV_ADMIN_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_ADMIN_WAREHOUSE}/view/:id`,
    /* End of Warehouse Admin Paths */

    /* Start of NonStorage Paths */
    SCO_INV_NONSTORAGE_LIST: `${CALISTA_SCO_INVENTORY_COMPANY_NONSTORAGE}`,
    SCO_INV_NONSTORAGE_CREATE_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_NONSTORAGE}/create`,
    SCO_INV_NONSTORAGE_EDIT_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_NONSTORAGE}/edit/:id`,
    SCO_INV_NONSTORAGE_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_NONSTORAGE}/view/:id`,
    /* End of NonStorage Paths */

    /* Start of NonStorage Admin Paths */
    SCO_INV_ADMIN_NONSTORAGE_LIST: `${CALISTA_SCO_INVENTORY_ADMIN_NONSTORAGE}`,
    SCO_INV_ADMIN_NONSTORAGE_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_ADMIN_NONSTORAGE}/view/:id`,
    /* End of NonStorage Admin Paths */

    /*Start of SKU PATHS*/
    SCO_INV_SKU_LIST: `${CALISTA_SCO_INVENTORY_COMPANY_SKU}`,
    SCO_INV_SKU_CREATE_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_SKU}/create`,
    SCO_INV_SKU_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_SKU}/view/:id`,
    SCO_INV_SKU_EDIT_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_SKU}/edit/:id`,
    SCO_INV_ADMIN_SKU_LIST: `${CALISTA_SCO_INVENTORY_ADMIN_SKU}`,
    SCO_INV_ADMIN_SKU_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_ADMIN_SKU}/view/:id`,
    SCO_INV_SKU_UPLOAD: `${CALISTA_SCO_INVENTORY_COMPANY_SKU}/uploadSku`,
    SCO_INV_SKU_CONFIRM_UPLOAD_DETAILS: `${CALISTA_SCO_INVENTORY_COMPANY_SKU}/uploadSku/confirmUploadDetails`,
    /*End of SKU PATHS*/

    /*Start of Default Loc Config PATHS*/
    SCO_INV_DEF_LOC_CONFIG_EDIT: `${CALISTA_SCO_INVENTORY_COMPANY_DEF_LOC_CONFIG}`,
    /*End of Default Loc Config PATHS*/

    /*Start of Stock Inventory PATHS*/
    SCO_INV_STOCK_INVENTORY_LIST: `${CALISTA_SCO_INVENTORY_COMPANY_STOCK}`,
    SCO_INV_STOCK_INVENTORY_DETAILS_VIEW: `${CALISTA_SCO_INVENTORY_COMPANY_STOCK}/view/:id`,
    SCO_INV_STOCK_INVENTORY_DETAILS_EDIT: `${CALISTA_SCO_INVENTORY_COMPANY_STOCK}/edit/:id`,
    /*End of Stock Inventory PATHS*/

    /* Start of Principal Mapping PATHS */
    SCO_INV_PRINCIPAL_MAPPING_PARTIES_LIST: `${CALISTA_SCO_INVENTORY_PRINCIPAL_MAPPING}`,
    SCO_INV_PRINCIPAL_MAPPING_PARTIES_EDIT_VIEW: `${CALISTA_SCO_INVENTORY_PRINCIPAL_MAPPING}/edit/:id`,
    SCO_INV_MY_PRINCIPAL_LIST: `${CALISTA_SCO_MY_INVENTORY_PRINCIPAL}`,
    SCO_INV_MY_PRINCIPAL_DETAILS_VIEW: `${CALISTA_SCO_MY_INVENTORY_PRINCIPAL}/view/:id`,

    /* Start of Inv Report PATHS */
    SCO_INV_REPORT: `${CALISTA_SCO_INVENTORY_ADMIN}/report`,
    SCO_INV_REPORT_EXPORT: `${CALISTA_SCO_INVENTORY_ADMIN}/generate-report`,

}

export default pathMap