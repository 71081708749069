
export const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-cia`
const CIA_ADMIN_BASE = `${MODULE_BASE}/cia-admin`


const CALISTA_ADVISORY_SEARCH_PATH = `${MODULE_BASE}/tas`
const CALISTA_ADVISORY_WIZARD_PATH = `${MODULE_BASE}/wizard`
const CALISTA_ADVISORY_LCC_PATH = `${MODULE_BASE}/calculator`
const CALISTA_ADVISORY_MASSRECOMMEND_PATH = `${MODULE_BASE}/massrecommend`
const CALISTA_ADVISORY_ADMIN_DATA_CONFIG = `${CIA_ADMIN_BASE}/data-config`
const CALISTA_ADVISORY_ADMIN_PARTY_CONFIG = `${CIA_ADMIN_BASE}/party-config`
const CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG = `${CIA_ADMIN_BASE}/system-config`
const CALISTA_ADVISORY_ADMIN_COUNTRY = `${CIA_ADMIN_BASE}/country`
const CALISTA_ADVISORY_ADMIN_FTA_MAPPING = `${CIA_ADMIN_BASE}/fta-mapping`
const CALISTA_ADVISORY_ADMIN_MASS_RECOMMEND = `${CIA_ADMIN_BASE}/mass-recommend`
const CALISTA_ACCOUNT_MANAGEMENT_PATH = `${MODULE_BASE}/acct-mgmt`
const CALISTA_ADMIN_ACCOUNT_MANAGEMENT_PATH = `${MODULE_BASE}/admin-acct-mgmt`

const pathMap = {
    CALISTA_ADVISORY_SEARCH: CALISTA_ADVISORY_SEARCH_PATH,
    CALISTA_ADVISORY_WIZARD: CALISTA_ADVISORY_WIZARD_PATH,
    CALISTA_ADVISORY_LCC: CALISTA_ADVISORY_LCC_PATH,
    CALISTA_ADVISORY_MASSRECOMMEND: CALISTA_ADVISORY_MASSRECOMMEND_PATH,

    CALISTA_ADVISORY_LCC_RESULT: CALISTA_ADVISORY_LCC_PATH + '/result',
    CALISTA_ADVISORY_MASSRECOMMEND_UPLOAD: CALISTA_ADVISORY_MASSRECOMMEND_PATH + '/upload',
    CALISTA_ADVISORY_MASSRECOMMEND_RESULT: CALISTA_ADVISORY_MASSRECOMMEND_PATH + '/result',
    CALISTA_ADVISORY_MASSRECOMMEND_UPLOAD_TYPE: CALISTA_ADVISORY_MASSRECOMMEND_PATH + '/upload/:requestType',
    CALISTA_ADVISORY_MASSRECOMMEND_RESULT_TYPE: CALISTA_ADVISORY_MASSRECOMMEND_PATH + '/result/:id',

    CALISTA_ADVISORY_ADMIN_PARTY_CONFIG: CALISTA_ADVISORY_ADMIN_PARTY_CONFIG,
    CALISTA_ADVISORY_ADMIN_PARTY_CONFIG_LIST_VIEW: CALISTA_ADVISORY_ADMIN_PARTY_CONFIG,
    CALISTA_ADVISORY_ADMIN_PARTY_CONFIG_DETAILS_VIEW: `${CALISTA_ADVISORY_ADMIN_PARTY_CONFIG}/view/:id`,
    CALISTA_ADVISORY_ADMIN_PARTY_CONFIG_EDIT_VIEW: `${CALISTA_ADVISORY_ADMIN_PARTY_CONFIG}/edit/:id`,

    CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG: CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG,
    CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG_LIST_VIEW: CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG,
    CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG_DETAILS_VIEW: `${CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG}/view/:id`,
    CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG_ADD_VIEW: `${CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG}/add`,
    CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG_EDIT_VIEW: `${CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG}/edit/:id`,

    CALISTA_ADVISORY_ADMIN_COUNTRY: CALISTA_ADVISORY_ADMIN_COUNTRY,
    CALISTA_ADVISORY_ADMIN_COUNTRY_LIST_VIEW: CALISTA_ADVISORY_ADMIN_COUNTRY,
    CALISTA_ADVISORY_ADMIN_COUNTRY_DETAILS_VIEW: `${CALISTA_ADVISORY_ADMIN_COUNTRY}/view/:id`,
    CALISTA_ADVISORY_ADMIN_COUNTRY_ADD_VIEW: `${CALISTA_ADVISORY_ADMIN_COUNTRY}/add`,
    CALISTA_ADVISORY_ADMIN_COUNTRY_EDIT_VIEW: `${CALISTA_ADVISORY_ADMIN_COUNTRY}/edit/:id`,

    CALISTA_ADVISORY_ADMIN_FTA_MAPPING: CALISTA_ADVISORY_ADMIN_FTA_MAPPING,
    CALISTA_ADVISORY_ADMIN_FTA_MAPPING_LIST_VIEW: CALISTA_ADVISORY_ADMIN_FTA_MAPPING,
    CALISTA_ADVISORY_ADMIN_FTA_MAPPING_DETAILS_VIEW: `${CALISTA_ADVISORY_ADMIN_FTA_MAPPING}/view/:id`,
    CALISTA_ADVISORY_ADMIN_FTA_MAPPING_ADD_VIEW: `${CALISTA_ADVISORY_ADMIN_FTA_MAPPING}/add`,
    CALISTA_ADVISORY_ADMIN_FTA_MAPPING_EDIT_VIEW: `${CALISTA_ADVISORY_ADMIN_FTA_MAPPING}/edit/:id`,

    CALISTA_ADVISORY_ADMIN_MASS_RECOMMEND_LIST_VIEW: CALISTA_ADVISORY_ADMIN_MASS_RECOMMEND,
    CALISTA_ADVISORY_ADMIN_MASS_RECOMMEND_DETAILS_VIEW: `${CALISTA_ADVISORY_ADMIN_MASS_RECOMMEND}/view/:id`,

    CALISTA_ACCOUNT_MANAGEMENT: CALISTA_ACCOUNT_MANAGEMENT_PATH,
    CALISTA_ADMIN_ACCOUNT_MANAGEMENT: CALISTA_ADMIN_ACCOUNT_MANAGEMENT_PATH,
    CALISTA_ADMIN_ACCOUNT_MANAGEMENT_COMPANY_VIEW: CALISTA_ADMIN_ACCOUNT_MANAGEMENT_PATH + '/company',

}

export default pathMap
