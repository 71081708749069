import { constants, useTranslation } from 'cng-web-lib'

import ClogsConfigKeys from 'src/constants/locale/key/ClogsConfig'
import ContainerTypeKeys from 'src/constants/locale/key/ContainerType'
import JsonSchemaKeys from 'src/constants/locale/key/JsonSchema'
import ModuleKeys from 'src/constants/locale/key/Module'
import CustomUserAccountKeys from 'src/constants/locale/key/CustomUserAccount'
import Namespace from 'src/constants/locale/Namespace'
import SequenceKeys from 'src/constants/locale/key/Sequence'
import pathMap from 'src/paths/PathMap_LogisticsAdmin'

const {
	locale: {
		key: { UiComponentKeys }
	}
} = constants

const translationNamespace = [
	Namespace.UI_COMPONENT,
  Namespace.CLOGS_CONFIG,
	Namespace.SEQUENCE,
  Namespace.JSON_SCHEMA,
  Namespace.MODULE,
  Namespace.BILLING_AUDIT,
  Namespace.CUSTOM_USER_ACCOUNT

]

function useBreadcrumbNameMap() {
	
	const { translate } = useTranslation(translationNamespace)

  const breadcrumbNameMap = new Map()

  {
	let title = translate(Namespace.CLOGS_CONFIG, ClogsConfigKeys.TITLE)
  // ---------------- Logistics Admin Clogs Config ---------------
  
	// List View
	let listViewTitle = title
	breadcrumbNameMap.set(pathMap.CLOGS_CONFIG_LIST_VIEW, listViewTitle)

	// Add View
	let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
	breadcrumbNameMap.set(pathMap.CLOGS_CONFIG_ADD_VIEW, addViewTitle)

	// Edit View
	let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
	breadcrumbNameMap.set(pathMap.CLOGS_CONFIG_EDIT_VIEW, editViewTitle)

	// Details View
	let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
	breadcrumbNameMap.set(pathMap.CLOGS_CONFIG_DETAILS_VIEW, detailsViewTitle)
  }
	// ---------------- Logistics Admin Clogs Config ---------------

	// ---------------- Logistics Admin Sequence ---------------
  {
      let title = translate(Namespace.SEQUENCE, SequenceKeys.TITLE)

      // List View
      let listViewTitle = title
      breadcrumbNameMap.set(pathMap.SEQUENCE_LIST_VIEW, listViewTitle)

      // Add View
      let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
      breadcrumbNameMap.set(pathMap.SEQUENCE_ADD_VIEW, addViewTitle)

      // Edit View
      let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
      breadcrumbNameMap.set(pathMap.SEQUENCE_EDIT_VIEW, editViewTitle)

      // Details View
      let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
      breadcrumbNameMap.set(pathMap.SEQUENCE_DETAILS_VIEW, detailsViewTitle)

  }
  // ---------------- Logistics Admin Sequence ---------------

  // ---------------- Logistics Admin Json Schema ---------------
  {
    let title = translate(Namespace.JSON_SCHEMA, JsonSchemaKeys.TITLE)

    // List View
    let listViewTitle = title
    breadcrumbNameMap.set(pathMap.JSON_SCHEMA_LIST_VIEW, listViewTitle)

    // Add View
    let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
    breadcrumbNameMap.set(pathMap.JSON_SCHEMA_ADD_VIEW, addViewTitle)

    // Edit View
    let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
    breadcrumbNameMap.set(pathMap.JSON_SCHEMA_EDIT_VIEW, editViewTitle)

    // Details View
    let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
    breadcrumbNameMap.set(pathMap.JSON_SCHEMA_DETAILS_VIEW, detailsViewTitle)

}
// ---------------- Logistics Admin Json Schema ---------------

// ---------------- Logistics Admin Container Type ---------------
{
  let title = translate(Namespace.CONTAINER_TYPE, ContainerTypeKeys.TITLE)

  // List View
  let listViewTitle = title
  breadcrumbNameMap.set(pathMap.CONTAINER_TYPE_LIST_VIEW, listViewTitle)

  // Add View
  let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
  breadcrumbNameMap.set(pathMap.CONTAINER_TYPE_ADD_VIEW, addViewTitle)

  // Edit View
  let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
  breadcrumbNameMap.set(pathMap.CONTAINER_TYPE_EDIT_VIEW, editViewTitle)

  // Details View
  let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
  breadcrumbNameMap.set(pathMap.CONTAINER_TYPE_DETAILS_VIEW, detailsViewTitle)

}
// ---------------- Logistics Admin Container Type ---------------

// ---------------- Logistics Admin Billing Audit ---------------

{
  
  let title = translate(Namespace.BILLING_AUDIT, ContainerTypeKeys.TITLE)

  // List View
  let listViewTitle = title
  breadcrumbNameMap.set(pathMap.BILLING_AUDIT, listViewTitle)


}
// ---------------- Logistics Admin Billing Audit ---------------

// ---------------- Logistics Admin Module ---------------
{
  let title = translate(Namespace.MODULE, ContainerTypeKeys.TITLE)

  // List View
  let listViewTitle = title
  breadcrumbNameMap.set(pathMap.MODULE_LIST_VIEW, listViewTitle)

  // Add View
  let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
  breadcrumbNameMap.set(pathMap.MODULE_ADD_VIEW, addViewTitle)

  // Edit View
  let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
  breadcrumbNameMap.set(pathMap.MODULE_EDIT_VIEW, editViewTitle)

  // Details View
  let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
  breadcrumbNameMap.set(pathMap.MODULE_DETAILS_VIEW, detailsViewTitle)

}
// ---------------- Logistics Admin Module ---------------

// ---------------- Logistics Admin Custom User Account ---------------
{
  let title = translate(Namespace.CUSTOM_USER_ACCOUNT, CustomUserAccountKeys.TITLE)

  // List View
  let listViewTitle = title
  breadcrumbNameMap.set(pathMap.CUSTOM_USER_ACCOUNT_LIST_VIEW, listViewTitle)

  // Add View
  let addViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE, title)
  breadcrumbNameMap.set(pathMap.CUSTOM_USER_ACCOUNT_ADD_VIEW, addViewTitle)

  // Edit View
  let editViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE, title)
  breadcrumbNameMap.set(pathMap.CUSTOM_USER_ACCOUNT_EDIT_VIEW, editViewTitle)

  // Details View
  let detailsViewTitle = TranslateCngUiComponent(UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE, title)
  breadcrumbNameMap.set(pathMap.CUSTOM_USER_ACCOUNT_DETAILS_VIEW, detailsViewTitle)

}
// ---------------- Logistics Admin Custom User Account ---------------

  return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {

    const { translate } = useTranslation(translationNamespace)

    let title = translate(
        Namespace.UI_COMPONENT,
        component, { title: componentTitle }
    )

    return (title);
}

export default useBreadcrumbNameMap
