const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-common/calista-admin`

const CALISTA_PARTY_CONFIG_PATH = `${MODULE_BASE}/calista-party-config`

const pathMap = {
	CALISTA_PARTY_CONFIG: CALISTA_PARTY_CONFIG_PATH,
	CALISTA_PARTY_CONFIG_LIST_VIEW: CALISTA_PARTY_CONFIG_PATH,
	CALISTA_PARTY_CONFIG_DETAILS_VIEW: `${CALISTA_PARTY_CONFIG_PATH}/view/:id`,
	CALISTA_PARTY_CONFIG_EDIT_VIEW: `${CALISTA_PARTY_CONFIG_PATH}/edit/:id`,
	CALISTA_PARTY_CONFIG_MICROSITE_PREVIEW: `${CALISTA_PARTY_CONFIG_PATH}/microsite/preview`
}

export default pathMap
