const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-company-pref`

const ADDRESS_BOOK_SHIPPER_PATH = `${MODULE_BASE}/shipper/address-book`
const ADDRESS_BOOK_CARRIER_PATH = `${MODULE_BASE}/carrier/address-book`
const SI_SETTING_PATH = `${MODULE_BASE}/si-pref-setting`
const FRB_COMPANY_PREFERENCE_PATH = `${MODULE_BASE}/frb-company-preference`
const SI_COMPANY_PREFERENCE_PATH = `${MODULE_BASE}/si-company-preference`

const pathMap = {
  ADDRESS_BOOK_SHIPPER: ADDRESS_BOOK_SHIPPER_PATH,
  ADDRESS_BOOK_CARRIER: ADDRESS_BOOK_CARRIER_PATH,
  SI_PREF_SETTING: SI_SETTING_PATH,
  FRB_COMPANY_PREFERENCE: FRB_COMPANY_PREFERENCE_PATH,
  FRB_COMPANY_PREFERENCE_EDIT_VIEW: FRB_COMPANY_PREFERENCE_PATH,
  
  SI_COMPANY_PREFERENCE: SI_COMPANY_PREFERENCE_PATH,
  SI_COMPANY_PREFERENCE_EDIT_VIEW: SI_COMPANY_PREFERENCE_PATH
}

export default pathMap
