const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-sco/ecommerce`
const CEC_DELIVERY_ORDER = `${MODULE_BASE}/delivery-orders`;

const pathMap = {

    //Delivery Orders
    CEC_DELIVERY_ORDER_LIST_VIEW: CEC_DELIVERY_ORDER,
    CEC_DELIVERY_ORDER_ADD_VIEW: `${CEC_DELIVERY_ORDER}/add`,
    CEC_DELIVERY_ORDER_EDIT_VIEW: `${CEC_DELIVERY_ORDER}/edit/:deliveryOrderRefNo`,
    CEC_DELIVERY_ORDER_DETAILS_VIEW: `${CEC_DELIVERY_ORDER}/view/:deliveryOrderRefNo`

}

export default pathMap