import { useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_CarbonFootprint'
import EcoCalculatorKeys from 'src/constants/locale/key/EcoCalculator'

const translationNamespace = [Namespace.UI_COMPONENT, Namespace.ECO_CALCULATOR]

function useBreadcrumbNameMap() {
  const { translate } = useTranslation(translationNamespace)

  const usebreadcrumbNameMap = new Map()

  //* Eco Calculator ---------------------------------------------------
  {
    let title = translate(
      Namespace.ECO_CALCULATOR,
      EcoCalculatorKeys.SUB_MODULE_TITLE
    )
    // List View
    usebreadcrumbNameMap.set(pathMap.ECO_CALCULATOR_LIST_VIEW, title)
  }

  return usebreadcrumbNameMap
}

export default useBreadcrumbNameMap
