import { constants, useTranslation } from 'cng-web-lib'

import UserPreferenceKeys from 'src/constants/locale/key/UserPreference'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/PathMap_UserPreference'

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const translationNamespace = [
  Namespace.UI_COMPONENT,
  Namespace.USER_PREFERENCE
]

function useBreadcrumbNameMap() {
  const { translate } = useTranslation(translationNamespace)

  const breadcrumbNameMap = new Map()

  //* TimeZone settings --------------------------------------------------------
  {
    let title = translate(
      Namespace.USER_PREFERENCE,
      UserPreferenceKeys.TIMEZONE_TITLE
    )

    let userPrefTitle = title
    breadcrumbNameMap.set(pathMap.TZ_USER_PREF_SETTING, userPrefTitle)
  }

  return breadcrumbNameMap
}

export default useBreadcrumbNameMap
