const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-sco`


const CALISTA_SCO_JOB_ATTRIBUTE = `${MODULE_BASE}/jobattribute`


const pathMap = {
    SCO_JOB_ATTRIBUTE_LIST: `${CALISTA_SCO_JOB_ATTRIBUTE}`,
    SCO_JOB_ATTRIBUTE_CREATE_VIEW: `${CALISTA_SCO_JOB_ATTRIBUTE}/create`,
    SCO_JOB_ATTRIBUTE_VIEW_DETAILS: `${CALISTA_SCO_JOB_ATTRIBUTE}/view/:id`,
    SCO_JOB_ATTRIBUTE_EDIT_VIEW: `${CALISTA_SCO_JOB_ATTRIBUTE}/edit/:id`,
}

export default pathMap
