const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-common/notification`

const NOTIFICATION_SUBSCRIPTION_PATH = `${MODULE_BASE}/notification-subscription`

const pathMap = {
    NOTIFICATION_SUBSCRIPTION: NOTIFICATION_SUBSCRIPTION_PATH,
    NOTIFICATION_SUBSCRIPTION_LIST_VIEW: `${NOTIFICATION_SUBSCRIPTION_PATH}`,
    NOTIFICATION_SUBSCRIPTION_ADD_VIEW: `${NOTIFICATION_SUBSCRIPTION_PATH}/add`,
    NOTIFICATION_SUBSCRIPTION_EDIT_VIEW: `${NOTIFICATION_SUBSCRIPTION_PATH}/edit/:id`,
    NOTIFICATION_SUBSCRIPTION_DETAILS_VIEW: `${NOTIFICATION_SUBSCRIPTION_PATH}/view/:id`
}

export default pathMap
