const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-common/calista-admin/company-info`

const pathMap = {
	MODULE_BASE: MODULE_BASE,
	CALISTA_PARTY_ADMIN_LIST: `${MODULE_BASE}`,
	CALISTA_PARTY_ADMIN_VIEW: `${MODULE_BASE}/view/:id`,
	CALISTA_PARTY_ADMIN_EDIT: `${MODULE_BASE}/edit/:id`,
	CALISTA_PARTY_ADMIN_MICROSITE_PREVIEW: `${MODULE_BASE}/microsite/preview`
}

export default pathMap
