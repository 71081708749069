const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-edimap`

const PARTY_MAPPING_PATH = `${MODULE_BASE}/party-mapping`
const BATCH_BOOKING_REQUEST_PATH = `${MODULE_BASE}/batch-booking-request`
const CODE_MAPPING_PATH = `${MODULE_BASE}/code-mapping`
const PORT_MAPPING_PATH = `${MODULE_BASE}/port-mapping`
const PLATFORM_PORT_MAPPING_PATH = `${MODULE_BASE}/platform-port-mapping`
const BATCH_DATA_CONFIG_PATH = `${MODULE_BASE}/batch-data-config`
const TRANSACTION_LOG_PATH = `${MODULE_BASE}/transaction-log`
const SINOTRANS_REGISTRATION_PATH = `${MODULE_BASE}/sinotrans-registration`
const BATCH_MILESTONE_PATH = `${MODULE_BASE}/batch-milestone`
const BATCH_SFTP_PROCESS_PATH = `${MODULE_BASE}/batch-sftp-process`
const SHIPPING_INSTRUCTION_REQUEST_PATH = `${MODULE_BASE}/shipping-instruction-request`
const NBS_MAPPING_PATH = `${MODULE_BASE}/nbs-mapping`


const pathMap = {
  PARTY_MAPPING: PARTY_MAPPING_PATH,
  PARTY_MAPPING_LIST_VIEW: PARTY_MAPPING_PATH,
  PARTY_MAPPING_ADD_VIEW: `${PARTY_MAPPING_PATH}/add`,
  PARTY_MAPPING_EDIT_VIEW: `${PARTY_MAPPING_PATH}/edit/:id`,
  PARTY_MAPPING_DETAILS_VIEW: `${PARTY_MAPPING_PATH}/view/:id`,
  BATCH_BOOKING_REQUEST: BATCH_BOOKING_REQUEST_PATH,
  BATCH_BOOKING_REQUEST_LIST_VIEW: BATCH_BOOKING_REQUEST_PATH,
  BATCH_BOOKING_REQUEST_ADD_VIEW: `${BATCH_BOOKING_REQUEST_PATH}/add`,
  BATCH_BOOKING_REQUEST_EDIT_VIEW: `${BATCH_BOOKING_REQUEST_PATH}/edit/:id`,
  BATCH_BOOKING_REQUEST_DETAILS_VIEW: `${BATCH_BOOKING_REQUEST_PATH}/view/:id`,
  CODE_MAPPING: CODE_MAPPING_PATH,
  CODE_MAPPING_LIST_VIEW: CODE_MAPPING_PATH,
  CODE_MAPPING_ADD_VIEW: `${CODE_MAPPING_PATH}/add`,
  CODE_MAPPING_EDIT_VIEW: `${CODE_MAPPING_PATH}/edit/:id`,
  CODE_MAPPING_DETAILS_VIEW: `${CODE_MAPPING_PATH}/view/:id`,
  PORT_MAPPING: PORT_MAPPING_PATH,
  PORT_MAPPING_LIST_VIEW: PORT_MAPPING_PATH,
  PORT_MAPPING_ADD_VIEW: `${PORT_MAPPING_PATH}/add`,
  PORT_MAPPING_EDIT_VIEW: `${PORT_MAPPING_PATH}/edit/:id`,
  PORT_MAPPING_DETAILS_VIEW: `${PORT_MAPPING_PATH}/view/:id`,
  PLATFORM_PORT_MAPPING_PATH: PLATFORM_PORT_MAPPING_PATH,
  PLATFORM_PORT_MAPPING_LIST_VIEW: PLATFORM_PORT_MAPPING_PATH,
  PLATFORM_PORT_MAPPING_ADD_VIEW: `${PLATFORM_PORT_MAPPING_PATH}/add`,
  PLATFORM_PORT_MAPPING_EDIT_VIEW: `${PLATFORM_PORT_MAPPING_PATH}/edit/:id`,
  PLATFORM_PORT_MAPPING_DETAILS_VIEW: `${PLATFORM_PORT_MAPPING_PATH}/view/:id`,
  BATCH_DATA_CONFIG_PATH: BATCH_DATA_CONFIG_PATH,
  BATCH_DATA_CONFIG_LIST_VIEW: BATCH_DATA_CONFIG_PATH,
  BATCH_DATA_CONFIG_ADD_VIEW: `${BATCH_DATA_CONFIG_PATH}/add`,
  BATCH_DATA_CONFIG_EDIT_VIEW: `${BATCH_DATA_CONFIG_PATH}/edit/:id`,
  BATCH_DATA_CONFIG_DETAILS_VIEW: `${BATCH_DATA_CONFIG_PATH}/view/:id`,
  TRANSACTION_LOG_PATH: TRANSACTION_LOG_PATH,
  TRANSACTION_LOG_LIST_VIEW: TRANSACTION_LOG_PATH,
  TRANSACTION_LOG_ADD_VIEW: `${TRANSACTION_LOG_PATH}/add`,
  TRANSACTION_LOG_EDIT_VIEW: `${TRANSACTION_LOG_PATH}/edit/:id`,
  TRANSACTION_LOG_DETAILS_VIEW: `${TRANSACTION_LOG_PATH}/view/:id`,
  SINOTRANS_REGISTRATION: SINOTRANS_REGISTRATION_PATH,
  SINOTRANS_REGISTRATION_LIST_VIEW: SINOTRANS_REGISTRATION_PATH,
  SINOTRANS_REGISTRATION_ADD_VIEW: `${SINOTRANS_REGISTRATION_PATH}/add`,
  SINOTRANS_REGISTRATION_EDIT_VIEW: `${SINOTRANS_REGISTRATION_PATH}/edit/:id`,
  SINOTRANS_REGISTRATION_DETAILS_VIEW: `${SINOTRANS_REGISTRATION_PATH}/view/:id`,
  BATCH_MILESTONE: BATCH_MILESTONE_PATH,
  BATCH_MILESTONE_LIST_VIEW: BATCH_MILESTONE_PATH,
  BATCH_MILESTONE_ADD_VIEW: `${BATCH_MILESTONE_PATH}/add`,
  BATCH_MILESTONE_EDIT_VIEW: `${BATCH_MILESTONE_PATH}/edit/:id`,
  BATCH_MILESTONE_DETAILS_VIEW: `${BATCH_MILESTONE_PATH}/view/:id`,
  BATCH_SFTP_PROCESS: BATCH_SFTP_PROCESS_PATH,
  BATCH_SFTP_PROCESS_LIST_VIEW: BATCH_SFTP_PROCESS_PATH,
  BATCH_SFTP_PROCESS_ADD_VIEW: `${BATCH_SFTP_PROCESS_PATH}/add`,
  BATCH_SFTP_PROCESS_EDIT_VIEW: `${BATCH_SFTP_PROCESS_PATH}/edit/:id`,
  BATCH_SFTP_PROCESS_DETAILS_VIEW: `${BATCH_SFTP_PROCESS_PATH}/view/:id`,
  SHIPPING_INSTRUCTION_REQUEST: SHIPPING_INSTRUCTION_REQUEST_PATH,
  SHIPPING_INSTRUCTION_REQUEST_LIST_VIEW: SHIPPING_INSTRUCTION_REQUEST_PATH,
  SHIPPING_INSTRUCTION_REQUEST_ADD_VIEW: `${SHIPPING_INSTRUCTION_REQUEST_PATH}/add`,
  SHIPPING_INSTRUCTION_REQUEST_EDIT_VIEW: `${SHIPPING_INSTRUCTION_REQUEST_PATH}/edit/:id`,
  SHIPPING_INSTRUCTION_REQUEST_DETAILS_VIEW: `${SHIPPING_INSTRUCTION_REQUEST_PATH}/view/:id`,
  NBS_MAPPING: NBS_MAPPING_PATH,
  NBS_MAPPING_LIST_VIEW: NBS_MAPPING_PATH,
  NBS_MAPPING_ADD_VIEW: `${NBS_MAPPING_PATH}/add`,
  NBS_MAPPING_EDIT_VIEW: `${NBS_MAPPING_PATH}/edit/:id`,
  NBS_MAPPING_DETAILS_VIEW: `${NBS_MAPPING_PATH}/view/:id`
}

export default pathMap
